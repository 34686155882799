/* eslint-disable no-nested-ternary */

import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Accordion, AccordionDetails, Tooltip, Stack, TextField, FormControl, InputLabel, Select, Grid, Modal, IconButton, MenuItem, Button, Box, Typography, Tabs, Tab, TextareaAutosize, Card, Autocomplete, Chip } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


import { styled } from '@mui/material/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';




const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};


export default function PreferredExecutiveTabPanel({ formik, clientMaster, setpreferredExecutiveValue,isCheckedCallList, isCheckedActive, handleCheckboxChange, handleIsActiveChange, projectStatus, clientGrouping, value, handleChangeValue, hide, handleHide, handleShow, projectStatusId, handelSelectedProject, subContactId, handleSelectedSubContact, userId, projectDatatDropDown, projectId, handleSelectedProject, executives, selectedExecutives, handleSelectedExecutive, teams, numberOfTeams, maxExecutivesPerTeam, initialExecutiveValue, executiveValue, addTeam, handleTeamNameChange, handleProjectChange, initialExecutiveStates, executiveStates, handleExecutivesChange, executiveRoles, executiveCostings, handleRoleChange, handleCostingChange, handleActiveCheckboxChange, selectedRadioStates, handleRadioChange, handleErrorCheckboxChange, exeData, handleSelectedExecutiveDD, SelectExecutive, handleSave, removeTeam, deleteTeam, handleDelete, executive, department, selectedPreferredExecutives, preferredExecutiveValue, handlePreferredSelectedExecutive, DeptValue, handleSelectedDepartment, isHide, selectedDepartment, executiveDepartments, currentDepartment, handleExecutiveCheckBoxChange, exeCheckBoxState, SelectDepartment, teamPanelData }) {
    

    const TABLE_HEAD = [
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'department',
            headerName: 'Department',
            flex: 1,
            valueGetter: (params) => departmentMap[params.row.department] || '',

        },
        {
            field: 'checkbox',
            headerName: 'IsConnect',
            flex: 1,
            renderCell: (params) => (
                <Checkbox
                    checked={exeCheckBoxState[params.row.id] || false}   //   Set checked state based on exeCheckBoxState
                    onChange={() => handleExecutiveCheckBoxChange(params.row.id)}   //   Toggle checkbox state
                />
            ),
        },

        // {
        //    field: 'delete',
        //    headerName: 'Delete',
        //    flex: 1,
        //    renderCell: (params) => (
        //        <IconButton
        //            onClick={() => handleDelete(params.row.id)}
        //            color="secondary"
        //            aria-label="delete"
        //        >
        //            <Iconify icon="ic:baseline-delete" width='20px' height='20px' color='primary.main' />
        //        </IconButton>
        //    ),
        // },
    ];

    const TABLE_HEAD_SELECTED = [
     
        { field: 'name', headerName: 'Name', flex: 1 },
        {
            field: 'department',
            headerName: 'Department',
            flex: 1,
            valueGetter: (params) => departmentMap[params.row.department] || '', 

        },
        {
            field: 'checkbox',
            headerName: 'IsConnect',
            flex: 1,
            renderCell: (params) => (
                <Checkbox
                    checked={exeCheckBoxState[params.row.id] || false}   //   Set checked state based on exeCheckBoxState
                    onChange={() => handleExecutiveCheckBoxChange(params.row.id)}   //   Toggle checkbox state
                />
            ),
        },

        // {
        //    field: 'delete',
        //    headerName: 'Delete',
        //    flex: 1,
        //    renderCell: (params) => (
        //        <IconButton
        //            onClick={() => handleDelete(params.row.id)}
        //            color="secondary"
        //            aria-label="delete"
        //        >
        //            <Iconify icon="ic:baseline-delete" width='20px' height='20px' color='primary.main' />
        //        </IconButton>
        //    ),
        // },
    ];


    const [departmentMap, setDepartmentMap] = useState({});
    useEffect(() => {
        const deptMap = department.reduce((acc, dept) => {
            acc[dept.Id] = dept.TeamName;
            return acc;
        }, {});
        setDepartmentMap(deptMap);
    }, [department]);

    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", selectedPreferredExecutives, TABLE_HEAD, preferredExecutiveValue)
  

    return (
        <>
            <Stack spacing={3}>
                <Stack direction="row" spacing={4}>

                    <FormControl
                        fullWidth
                        size="small" >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Department</InputLabel>
                        <Select
                            label="Department"
                            value={DeptValue}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => SelectDepartment()}>Select</MenuItem>
                            {department.map((dept) => (
                                <MenuItem key={dept.Id} value={dept.Id} onClick={() => handleSelectedDepartment(dept.Id, dept.TeamName)}> {dept.TeamName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Autocomplete
                        multiple
                        fullWidth
                        id="executive-select"
                        options={executive}
                        value={preferredExecutiveValue}
                        onChange={handlePreferredSelectedExecutive}
                        getOptionLabel={(option) => option.Name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Executives"
                                fullWidth
                                size="small"
                                InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}
                            />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox checked={selected} />
                                {option.Name}
                            </li>
                        )}
                        renderTags={(value, getTagProps) => (
                            <div>
                                {value.length > 0 && <p>{value.length} selected</p>}
                            </div>
                        )}
                    // isOptionEqualToValue={(option, value) => option.Id === value.Id}
                    />

                </Stack>
                {clientMaster.PreferredExecutive && clientMaster.PreferredExecutive.length > 0 && !isHide ? (
                    <>
                        <div style={{ marginTop: '20px' }} />
                        <div style={{ height: '54vh', width: '100%' }}>
                            <DataGrid
                                rows={clientMaster.PreferredExecutive.map((executive, index) => ({
                                    id: index, // Use a unique ID for each row, e.g., the index
                                    name: executive.Name, // The executive's name
                                    department: executive.Department, // Pass the selected department
                                    selectedPreferredExecutives: executive,
                                    exeChecked: exeCheckBoxState // The selected executive
                                }))}
                                density="compact"
                                columns={TABLE_HEAD}
                                getRowId={(row) => row.id} // Use the ID property as the row ID
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            />
                        </div>
                    </>
                ) : (
                    isHide ? (
                        <>
                            <div style={{ marginTop: '20px' }} />
                            <div style={{ height: '54vh', width: '100%' }}>
                                <DataGrid
                                    rows={selectedPreferredExecutives.map((executive, index) => ({
                                        id: index, // Use a unique ID for each row, e.g., the index
                                        name: executive.Name, // The executive's name
                                        department: executive.DepartmentId, // Pass the selected department
                                        selectedPreferredExecutives: executive,
                                        exeChecked: exeCheckBoxState // The selected executive
                                    }))}
                                    density="compact"
                                        columns={TABLE_HEAD_SELECTED}
                                    getRowId={(row) => row.id} // Use the ID property as the row ID
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                />
                            </div>
                        </>
                    ) : null
                )}

            </Stack>
        </>

    );
}