import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function FillInstallationReport() {
    const { enqueueSnackbar } = useSnackbar();

    const ClientLocationDropDown = [
        { Id: 1, value: 'Pending' },
        { Id: 2, value: 'Completed' },
        { Id: 3, value: 'Cancelled' }
    ];



    const TABLE_HEAD = [
        { field: 'Name', headerName: 'Client Name', width: 150 },
        { field: 'ClientName', headerName: 'Product', width: 300 },
        { field: 'CreatedDate', headerName: 'Installation Date', width: 300 },
        { field: 'IssueId', headerName: 'Issue Id', width: 300 },

        // {
        //    field: 'actions',
        //    headerName: '',
        //    type: 'actions',
        //    width: 20,
        //    renderCell: (cellValues) => {
        //        return (<div>

        //            <IconButton
        //                onClick={(event) => {
        //                    handleClick(event, cellValues);
        //                }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

        //        </div>
        //        );
        //    }
        // },

    ];

    // dropdown
    const [clients, setClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('');
    const location = useLocation();

    const handleClient = (id) => {
        setSelectedClientId(id);

    };

    const [statusId, setStatusId] = useState('');
    const handleStatus = (value) => {
        setStatusId(value);

    };

    // Dates 
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(endOfMonth(new Date()));
    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };

    useEffect(() => {
        URLS.getClientLandingPageGenerateReportUI()
            .then((response) => {

                setClients(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });


    }, []);


    // const navigate = useNavigate();
    // const handleClick = (event, cellValues) => {
    //    navigate('/dashboard/productmasterdetailspage', { state: { proMaster: cellValues.row } })
    // }


    const [reports, setReports] = useState([]);

    const [isLoading, setIsLoading] = useState();




    const onFilterClick = () => {
        setIsLoading(true);

        URLS.getGenerateReportFilter()
            .then((response) => {
                setReports(response.data);
                setIsLoading(false);

            })
            .catch(error => {
                // On error
                handleError(error, enqueueSnackbar);
            });
    };



    const handleReset = () => {
        setReports([]);
        setSelectedClientId('');

    }

    useEffect(() => {
        setReports();
        setSelectedClientId('');
        setReports([]);
        setStatusId('')
    }, [location.key])


    return (

        <Page title="Installation Report" >

            <Card >


                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <FormControl
                        size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}>
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Clients</InputLabel>
                        <Select
                            label="Clients"
                            size="small"
                            value={selectedClientId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {clients.map((product) => (
                                <MenuItem key={product.Id} value={product.Id} onClick={() => handleClient(product.Id)}> {product.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>


                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                            onChange={handleEffFrom}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                            onChange={handleEffTo}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <FormControl
                        fullWidth
                        size="small"
                    >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Status</InputLabel>
                        <Select
                            label="Status"
                            value={statusId}
                            MenuProps={{
                                style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                            }}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {ClientLocationDropDown.map((ofc) => (

                                <MenuItem key={ofc.Id} value={ofc.value} onClick={() => handleStatus(ofc.Id)}> {ofc.value}</MenuItem>
                            ))}


                        </Select>

                    </FormControl>


                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}

                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >

                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/fillinstallationreportdetailspage'
                                variant="contained"
                                size="small"
                                state={{ fillInstallation: { Id: '', ClientId: '', ProductId: '', SiteId: '', InstallationStatus: '', InstallationStatusDate: '', InstallationRemark: '', } }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleReset}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                        </Tooltip >

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={reports} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => reports.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}