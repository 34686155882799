import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, Modal, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
// components
import Button from '@mui/material/Button';

// icon
import { Icon } from '@iconify/react';
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import AppCache from '../../services/AppCache';
import URLS from '../../services/urls.service';
import DashBoardMenuService from '../../services/dashBoardMenu.service';


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 50;
// const APPBAR_MOBILE = 64;
// const APPBAR_DESKTOP = 92;
const APPBAR_DESKTOP = 50;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
   // background: alpha(theme.palette.primary.light, 0.20),
   // background: alpha(theme.palette.cyan.dark, 0.5),
    background: '#fbe9e7',

   // background: '#723D46',
 // backgroundColor: alpha(theme.palette.background.default, 0.72),
  
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('sm')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 2),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function DashboardNavbar({ onOpenSidebar, openNav, dwidth }) {
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [username, setDashboardusername] = useState([]);
    const [userId, setUserId] = useState([]);
    const [mark, setMark] = useState(false);


    // Modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handelCancel = () => {
        navigate('/login', { relpace: true });
    }

    useEffect(() => {
        // if (isOpenSidebar) {
        //  onCloseSidebar();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setDashboardusername(CurrentLoggedInUser.ExecutiveName);
        setUserId(CurrentLoggedInUser.ExecutiveId);
        const ContactId = CurrentLoggedInUser.ExecutiveId;
        URLS.getAttendance(ContactId)
            .then((response) => {
                console.log("response.status", response.data.status);
                if (response.data.status === true) {
                    setMark(true);
                } else {
                    setMark(false);
                }
               
                
            }).catch(error => {
                // On error
                console.log(" Error ");
            });
       


        // console.log(" SetProjects", CurrentLoggedInUser.Menus);
    }, []);
    const onChangeMark = () => {
        console.log("hii")
        const PayloadAttendance = {
            'userDetails': [
                {
                    ContactId: userId, Lat: '0', Long: '0'

                }
            ]
        };
        console.log("PayloadAttendance", PayloadAttendance);
        URLS.postaddAttendance(PayloadAttendance)
            .then((response) => {
                console.log("ssds");
                setMark(true);
            }).catch(error => {
                // On error
                console.log(" Error ");
            });

    }

  
    const navigate = useNavigate();
    const handleLogOut = () => {
       
        navigate('/login', { relpace: true });

    };
  return (
      <RootStyle sx={{ width: { md: `calc(100% - ${dwidth }px)` } }}>
      <ToolbarStyle>
             {/* {<IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}> */}
              {!openNav && (<IconButton onClick={onOpenSidebar} sx={{ color: '#4e342e' }}>
                  <Iconify icon="eva:menu-2-fill" />
              </IconButton>)}
      
            
              <Box component="img" src="/static/illustrations/TikeTicLOGO.png" sx={{
                  width: 100, height: 50
              }} />
              <Box sx={{ flexGrow: 1 }} />
              
              <AccountPopover />
              <Typography variant="subtitle1" sx={{ color: '#4e342e', ml: 1 }}>
                  {username}
              </Typography>
              <Tooltip title="Log out">
              <IconButton onClick={handleOpen}
                  sx={{
                      color: '#B50000',
                      ":hover": {
                          cursor: "pointer",
                          color: "#FB0000",

                      },
                  }}>
                  <Icon icon="mdi:shutdown" />
                  </IconButton>
                  </Tooltip>
              <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
              >
                  <Box sx={style}>
                      <Typography id="modal-modal-title" variant="h6" sx={{ mb: 2, textAlign: 'center' }} gutterBottom>
                          Confirm Logout?
                      </Typography>
                      <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>

                          <Button
                              onClick={handelCancel}
                              size="small"
                              variant="contained"
                          >
                              Yes
                          </Button>
                          <Button
                              onClick={handleClose}
                              size="small"
                              variant="contained"
                          >
                              No
                          </Button>
                      </Stack>
                  </Box>
              </Modal>

              {mark === true ?
                  <Tooltip title="I am present">
                      <IconButton onClick={onChangeMark} value={ mark}
                      sx={{ color: '#64dd17' }}>

                      <Icon icon="ic:twotone-fingerprint" />
                  </IconButton></Tooltip> :
                
                  <IconButton onClick={onChangeMark} value={ mark}
                      sx={{
                          color: '#B50000',
                          ":hover": {
                              cursor: "pointer",
                              color: "#FB0000",

                          },
                      }}>

                      <Icon icon="ic:twotone-fingerprint" />
                  </IconButton>}
      </ToolbarStyle>
    </RootStyle>
  );
}
