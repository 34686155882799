import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography, Divider } from '@mui/material';
// components
import Page from '../../../components/Page';
import Scrollbar from "../../../components/Scrollbar";
import ClientMaster from '../../../components/SidebarMenuComponents/MasterComponent/ClientMaster'
import ClientMasterDetailsComponent from "../../../components/SidebarMenuComponents/MasterComponent/ClientMasterDetailsComponent";
import ProductMasterDetailsComponent from "../../../components/SidebarMenuComponents/MasterComponent/ProductMasterDetailsComponent";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));
export default function ProductMasterDetailsPage() {
    const location = useLocation()
    const { proMaster } = location.state;

    return (
        <Page title="  Product Master Details |tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Product Master Details
            </Typography>
            <Divider />
            <Card sx={{ height: '470px', padding: 2 }}>
                <Scrollbar>
                    <ContentStyle>
                        <ProductMasterDetailsComponent proMaster={proMaster} />
                    </ContentStyle>
                </Scrollbar>
            </Card>
        </Page>
    );
}

