import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {





    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    // if (query) {
    //   
    //    return filter(array, (_project) => _project.ClientAlias.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    // }
    return stabilizedThis.map((el) => el[0]);
}

export default function ProductMaster() {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const ClientLocationDropDown = [
        { Id: -1, value: 'All' },
        { Id: 0, value: 'Non-Commercial' },
        { Id: 1, value: 'Commercial' },
    ];

    const TABLE_HEAD = [
        { field: 'ID', headerName: 'Product Id', width: 150 },
        { field: 'Name', headerName: 'Client Product Name', width: 300 },
        { field: 'GST', headerName: 'GST%', width: 300 ,flex:1},
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },

    ];

    // dropdown
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState();
    const [commercialStatusId, setCommercialStatusId] = useState(-1);
    const [editData, setEditData] = useState([])

    const handleCommercialStatusChange = (value) => {
        setCommercialStatusId(value);

    };
    console.log("commercialStatusId", commercialStatusId)
    useEffect(() => {
        URLS.getProductMasterAutocomplete()
            .then((response) => {

                setSelectedProduct(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });


    }, []);

    const handleProductChange = (event, value) => {
        if (value) {
            setSelectedProductId(value.ID);
        } else {
            setSelectedProductId(null);
        }
    };

    const productList = selectedProduct.map((product) => ({
        ID: product.ID,
        label: `${product.Name}`,
    }));

    const navigate = useNavigate();
    const handleClick = (event, cellValues) => {
        console.log("cellValues>>>>", cellValues.row.ID, cellValues)
        const payload = { EditID: cellValues.row.ID }
        URLS.getProductMasterEditData(payload)
            .then((response) => {
                const jsonDataString = JSON.stringify(response.data);

                const modifiedData = JSON.parse(jsonDataString);
                setEditData(modifiedData);
                console.log("modifiedData>>>>>", modifiedData)
                navigate('/dashboard/productmasterdetailspage', { state: { proMaster: modifiedData } })

            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    }

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('Name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);


    const [productData, setProductData] = useState([]);

    const [isLoading, setIsLoading] = useState();

    const [teamPanelData, setTeamPanelData] = useState([]);


     const [productId, setProductId] = useState('');

    const handelSelectedProduct = (id) => {

         setProductId(id);

     };
    const onFilterClick = () => {
        setIsLoading(true);

        const productIdFilter = productId === "" ? 0 : productId
        const payload = { ProductMasterID: productIdFilter, CommercialID: commercialStatusId };
            //   let payload = { clientId };

        URLS.getProductMasterListingData(payload)
                .then((response) => {
                    setProductData(response.data);
                    setIsLoading(false);

                })
                .catch(error => {
                    // On error
                    handleError(error, enqueueSnackbar);
                });
        };

    const filteredProjects = applySortFilter(productData, getComparator(order, orderBy), filterName);

    const isProjectNotFound = filteredProjects.length === 0;

    const handleReset = () => {
        setProductData([]);
        setSelectedProductId('');
        setProductId('');
        setCommercialStatusId(-1)
    }
    useEffect(() => {
        setProductData([]);
        setSelectedProductId('');
        setProductId('');
        setCommercialStatusId(-1)
    }, [location.key]); 

    return (

        <Page title="Product Master" >


            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                   

                    <FormControl
                        size="small"
                        fullWidth
                    >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Products</InputLabel>
                        <Select
                            label="Products"
                            size="small"
                            value={productId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handelSelectedProduct()} >Select</MenuItem>

                            {selectedProduct.map((product) => (
                                <MenuItem key={product.ID} value={product.ID} onClick={() => handelSelectedProduct(product.ID)}> {product.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>

                    <FormControl
                        size="small"
                        fullWidth
                    >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Commercial Status</InputLabel>
                        <Select
                            label="Commercial Status"
                            size="small"
                            value={commercialStatusId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                           {/* <MenuItem key={0} value={""} >Select</MenuItem> */}

                            {ClientLocationDropDown.map((product) => (
                                <MenuItem key={product.Id} value={product.Id} onClick={() => handleCommercialStatusChange(product.Id,product.value)}> {product.value}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>

                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}
                                
                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >







                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/productmasterdetailspage'
                                variant="contained"
                                size="small"
                                state={{ proMaster: {  Name: '', GST: '', Remark: '',IsActive:true,IsCommercialProduct:'',ProjectStatusId:'', ExecutiveID: '' } }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={ handleReset}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                        </Tooltip >

                    </Stack>

                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={productData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => productData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}