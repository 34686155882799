import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, FormControl, InputLabel, Select, Grid, Modal, IconButton, Typography, MenuItem, Button, Box, CardMedia, Tabs, Tab, FormHelperText } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Moment from 'moment';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
import AllocationExecutiveExpensesTabPanel from './AllocationExecutiveExpensesTabPanel';

// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #4e342e',
    position: 'sticky',
    '& .MuiTabs-indicator': {

    },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontsize: '20px',
    color: '#b71c1c',
    Transition: '2s',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#4e342e',

        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4e342e',

        fontWeight: theme.typography.fontWeightMedium,

    },
    '&.Mui-focusVisible': {
        backgroundColor: '#fff',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ExecutiveExpensesDetailsTabPanel({ formik, value, handleTabChangeValue, hide, handleHide, handleShow, fieldSets, handleAllocationRadioChange, selectedAllocationType }) {
    const navigate = useNavigate();

    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/master/executiveexpenses', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

  

    const validateInput = (input) => {
        return /^\d*$/.test(input); // Regular expression to allow only numeric input
    };

    const {  getFieldProps, errors, touched } = formik;

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <AntTabs value={value} onChange={handleTabChangeValue} variant="fullWidth" aria-label="basic tabs example">
                    <AntTab onClick={handleShow} label="Details" {...a11yProps(0)} />
                    {value > 0 && <AntTab onClick={handleShow} label=" Allocation " {...a11yProps(1)} />}
                </AntTabs >
            </Box>
            <TabPanel value={value} index={0}>
                <Stack spacing={3}>
                 

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <TextField
                            fullWidth 
                                size="small"
                                label="Expenses Category"
                        />
                        <TextField
                            fullWidth 
                            size="small"
                            label="Amount"
                        />
                        <TextField
                            fullWidth 
                            size="small"
                            label="Remark"
                        />
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Travelling Category"
                        />
                        <TextField
                            fullWidth
                            size="small"
                            label="From Location"
                        />
                        <TextField
                            fullWidth
                            size="small"
                            label="To Location"
                        />
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Count"
                        />
                        <TextField
                            fullWidth
                            size="small"
                            label="City"
                        />
                        <TextField
                            fullWidth
                            size="small"
                            label="Food Category"
                        />
                        </Stack>
                </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AllocationExecutiveExpensesTabPanel formik={formik} fieldSets={fieldSets} handleAllocationRadioChange={handleAllocationRadioChange} selectedAllocationType={selectedAllocationType} />
            </TabPanel>



            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}
        </>
    );
}