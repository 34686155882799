import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
    sx: PropTypes.object
};

export default function Logo({ sx }) {
    return <Box component="img" src="/static/illustrations/TikeTicLOGO.png" sx={{
        width: 250, height: 150, textAlign: 'centter', ...sx
    }} />;
}