
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Accordion, AccordionDetails, Tooltip, Stack, TextField, FormControl, InputLabel, Select, Grid, Modal, IconButton, MenuItem, Button, paymentId, Box, Typography, Tabs, Tab, TextareaAutosize, Card, Autocomplete, Chip } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


import { styled } from '@mui/material/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';




const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};

const remarkTextFieldStyle = {
    width : '48.5%'
}

export default function ProductTabPanel({ oaMaster, formik, oaProductsData, oaDetails, oaProductId, deleteTeam, handleProductsChange, fieldSets, handleAddButtonClick, handleFieldChange, handleProductIDChange, oaMultipleData, selectProductPayment, productPayemntId, handleProductPaymentIdChange }) {


    const { handleSubmit, getFieldProps, errors, touched } = formik;


    const validateInput = (input) => {
        return /^\d*$/.test(input); // Regular expression to allow only numeric input
    };
    console.log("fieldSets", fieldSets)
    return (
        <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={11} lg={11.5}  >
                <Card sx={{ minWidth: '300px', maxHeight: '370px', overflow: 'auto' }}>
                            <div>
                                { fieldSets.map((fieldSet, index) => (  // Selecting only the last element of fieldSets array
                                    <Accordion
                                        style={{
                                            borderBottom: '1px solid #ddd',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                            margin: '10px'
                                        }}
                                        key={index}
                                        defaultExpanded={index < fieldSets.length}
                                    >
                                        <AccordionSummary
                                            style={accordionStyle}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel${index}-content`}
                                            id={`panel${index }-header`}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <Typography style={{ lineHeight: 2.5 }}>
                                                    <span style={{ fontWeight: 'bold' }}>Products </span>
                                                </Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Stack spacing={4}>
                                                <Stack direction="row" spacing={4}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Product</InputLabel>
                                                        <Select
                                                            label="Product"
                                                            value={fieldSets[index].oaProductId }
                                                            onChange={(e) => handleFieldChange(index, 'oaProductId', e.target.value)} 
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        backgroundColor: '#fbe9e7',
                                                                        maxHeight: '200px',
                                                                        width: '200px',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem key={0} value={""}>Select</MenuItem>
                                                            {oaProductsData.map((component) => (
                                                                <MenuItem key={component.Id} value={component.Id}>
                                                                    {component.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        fullWidth
                                                        label="Quantity"
                                                        size="small"
                                                        value={fieldSets[index].Quantity}
                                                        onChange={(e) => {
                                                            const input = e.target.value;
                                                            if (validateInput(input)) {
                                                                handleFieldChange(index, 'Quantity', input);
                                                            } else {
                                                                e.preventDefault(); // Prevents non-numeric characters from being entered
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            const input = fieldSets[index].Quantity;
                                                            if (!validateInput(input)) {
                                                                // Handle invalid input here, such as showing an error message
                                                                console.log('Quantity input is invalid for index:', index);
                                                            }
                                                        }}

                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Rate"
                                                        size="small"
                                                        value={fieldSets[index].Rate}
                                                        onChange={(e) => {
                                                            const input = e.target.value;
                                                            if (validateInput(input)) {
                                                                handleFieldChange(index, 'Rate', input);
                                                            } else {
                                                                e.preventDefault(); // Prevents non-numeric characters from being entered
                                                            }
                                                        }}
                                                    />

                                                    <TextField
                                                        fullWidth
                                                        label="Month"
                                                        size="small"
                                                        value={fieldSets[index].Month}
                                                        onChange={(e) => {
                                                            const input = e.target.value;
                                                            if (validateInput(input)) {
                                                                handleFieldChange(index, 'Month', input);
                                                            } else {
                                                                e.preventDefault(); // Prevents non-numeric characters from being entered
                                                            }
                                                        }}
                                                    />

                                                  
                                                </Stack>
                                                <Stack spacing={4}>
                                                <Stack direction="row" spacing={4}>
                                                        <FormControl fullWidth size="small" style={{width:'350px'}}>
                                                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Payment Term</InputLabel>
                                                        <Select
                                                            label="Payment Term"
                                                              
                                                              
                                                                value={fieldSet.OAPayementTerm}
                                                                onChange={(e) => handleFieldChange(index, 'OAPayementTerm',e.target.value)}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    sx: {
                                                                        backgroundColor: '#fbe9e7',
                                                                        maxHeight: '200px',
                                                                        width: '150px'
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem key={0} value={""}>Select</MenuItem>
                                                            {selectProductPayment.map((component) => (
                                                                <MenuItem key={component.Id} value={component.Id}>
                                                                    {component.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        </FormControl>
                                                    <TextField

                                                        label="Remark"
                                                        size="small"
                                                        value={fieldSets[index].Remark}
                                                        onChange={(e) => handleFieldChange(index, 'Remark', e.target.value)}
                                                        multiline
                                                        maxRows={4}
                                                        fullWidth
                                                    />
                                                </Stack>
                                                    </Stack>
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}

                            </div>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={0.5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Tooltip title="Add New Team">
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleAddButtonClick}
                        style={{
                            width: '50px',
                            height: '36px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            marginTop: '22px',
                            marginBottom: '10px',
                        }}
                    >
                        <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                    </Button>
                </Tooltip>

            </Grid>
        </Grid>
    );
}