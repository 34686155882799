import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {





    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    // if (query) {
    //   
    //    return filter(array, (_project) => _project.ClientAlias.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    // }
    return stabilizedThis.map((el) => el[0]);
}

export default function OAMasterListing() {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const StatusDropdown = [
        { Id: 1, value: 'All' },
        { Id: 2, value: 'Open' },
        { Id: 3, value: 'Close' }
    ];

    const TABLE_HEAD = [
        { field: 'OANumber', headerName: 'OA Number', width: 150 },
        { field: 'OADate', headerName: 'OA Date', width: 300 },
        { field: 'Client', headerName: 'Client', width: 300, flex: 1 },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues, oaDetailsData) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues, oaDetailsData);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },

    ];

    // dropdown
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('');
    const handleClient = (id) => {
        setSelectedClientId(id);

    };
    const [statusId, setStatusId] = useState(1);
    const handleStatusChange = (value) => {
        setStatusId(value);

    };

    // Calender
    const [effectiveFromValue, setEffectiveFromValue] = useState('' || startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState('' || endOfMonth(new Date()));
    const handleEffFrom = (newValue) => {
        const selectedDate = new Date(newValue);
        const day = selectedDate.getDate().toString().padStart(2, '0');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthIndex = selectedDate.getMonth();
        const month = monthNames[monthIndex];
        const year = selectedDate.getFullYear();
        const formattedDate = `${day} ${month} ${year}`;
        setEffectiveFromValue(formattedDate);
       
    };
    const handleEffTo = (newValue) => {
        const selectedDate = new Date(newValue);
        const day = selectedDate.getDate().toString().padStart(2, '0');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthIndex = selectedDate.getMonth();
        const month = monthNames[monthIndex];
        const year = selectedDate.getFullYear();
        const formattedDate = `${day} ${month} ${year}`;
        setEffectiveToValue(formattedDate);
      
    };

    // Number
    const [oaNumber, setoaNumber] = useState();
    const [data, setData] = useState([]);
    const [oaMasterData, setOaMasterData] = useState([]);


    const handleOaNumberchange = (event) => {
        setoaNumber(event.target.value);
    }


    useEffect(() => {
        // URLS.getProductMasterAutocomplete()
        //    .then((response) => {

        //        setSelectedClient(response.data);
        //    })
        //    .catch(error => {
        //        // On error
        //        console.log(" Error ");
        //    });

        URLS.getClientMasterData()
            .then((response) => {

                setSelectedClient(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        
    }, []);



    const navigate = useNavigate();
   // const [oaMultipleData, setOAMultipleData] = useState([]);
    const handleClick = (event, cellValues) => {
        const payload = { OAMasterId: cellValues.row.OAMasterId };

        // Call both APIs concurrently using Promise.all
        Promise.all([
            URLS.getOADetailsEditData(payload),
            URLS.getOAMasterEditData(payload)
        ])
            .then(([oaDetailsResponse, oaMasterResponse]) => {
                const oaDetailsData = JSON.parse(JSON.stringify(oaDetailsResponse.data));
                const oaMasterData = JSON.parse(JSON.stringify(oaMasterResponse.data));

                setData({ oaDetails: oaDetailsData, oaMaster: oaMasterData });
                navigate('/dashboard/oamasterdetailspage', { state: { oaDetails: oaDetailsData, oaMaster: oaMasterData } });
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    };

 
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('Name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [costSheet, setCostSheet] = useState(false)

    const [isLoading, setIsLoading] = useState();


    const onFilterClick = () => {
        setIsLoading(true);
        const payload = { ClientID: selectedClientId, OANumber: oaNumber, OAFromDate: effectiveFromValue, OAToDate: effectiveToValue, OAStatus: statusId };
        //   let payload = { clientId };

        URLS.getOAMasterListingData(payload)
            .then((response) => {
                setOaMasterData(response.data);
              
                setIsLoading(false);
                enqueueSnackbar("Fetch data successfully", { variant: 'success' });
            })
            .catch(error => {
                // On error
                enqueueSnackbar("Data not found", { variant: 'error' });

                // handleError(error, enqueueSnackbar);
            });
    };


    const filteredProjects = applySortFilter(oaMasterData, getComparator(order, orderBy), filterName);

    const isProjectNotFound = filteredProjects.length === 0;

    const handleReset = () => {
        setoaNumber("");
        setSelectedClientId("");
        setOaMasterData([]);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setStatusId(1)
    }

    const handleCostSheetClick = () => {
        setCostSheet(true)
    }

    useEffect(() => {
        setoaNumber("");
        setSelectedClientId("");
        setOaMasterData([]);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setStatusId(1);
    }, [location.key]); 

    console.log("location>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", location.pathname)
    return (

        <Page title="OA Master Listing" >


            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <TextField
                        fullWidth
                        label="OA Number"
                        size="small"
                        value={oaNumber}
                        onChange={handleOaNumberchange}
                    />

                    <FormControl
                        size="small"
                        fullWidth
                    >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Clients</InputLabel>
                        <Select
                            label="Clients"
                            size="small"
                            value={selectedClientId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handleClient()} >Select</MenuItem>

                            {selectedClient.map((product) => (
                                <MenuItem key={product.ID} value={product.ContactId} onClick={() => handleClient(product.ContactId)}> {product.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>

                    <FormControl
                        size="small"
                        fullWidth
                    >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Status</InputLabel>
                        <Select
                            label="Status"
                            size="small"
                            value={statusId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handleStatusChange()} >Select</MenuItem>

                            {StatusDropdown.map((product) => (
                                <MenuItem key={product.Id} value={product.Id} onClick={() => handleStatusChange( product.Id)}> {product.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>


                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                            onChange={handleEffFrom}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                            onChange={handleEffTo}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}

                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >



                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/oamasterdetailspage'
                                variant="contained"
                                size="small"
                                state={{ oaMaster: { OAMasterId: '', OANumber: '', OADate: '', ClientId: '', BuyersRefOrderNo: '', RefQuotationNo: '', RefQuotationDate: '', Responsible: '', PaymentTerm: '', Status: '', Amount: '', TaxAmount: '', TotalAmount: '', Remark: '', CreatedBy: '', OAMasterListingDetailsModel: { OAMasterDetailsId:'',ProductId: '', OADetailsRemark: '', Rate: '', Quantity: '', Month: '', SeqNo:'', ExecutiveId: '' } }}}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >
                        <Tooltip title="Cost Sheet">

                            <Button
                                component={RouterLink}
                                to='/dashboard/oamasterdetailspage'
                                variant="contained"
                                size="small"
                                state={{
                                    oaMaster: {
                                        OAMasterId: '', OANumber: '', OADate: '', ClientId: '', BuyersRefOrderNo: '', RefQuotationNo: '',
                                        RefQuotationDate: '', Responsible: '', PaymentTerm: '', Status: '', Amount: '', TaxAmount: '', TotalAmount: '',
                                        Remark: '', CreatedBy: '', OAMasterListingDetailsModel: {
                                            OAMasterDetailsId: '', ProductId: '', OADetailsRemark: '',
                                            Rate: '', Quantity: '', Month: '', SeqNo: '', ExecutiveId: ''
                                        }
                                    },
                                    isClicked:true
                                }}
                                onClick={handleCostSheetClick }
                            ><Icon icon="lucide:sheet" width="25px" height="25px" /></Button>

                        </Tooltip >
                        <Tooltip title="Reset">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleReset}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                        </Tooltip >

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={oaMasterData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => oaMasterData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}