import { useLocation, Navigate, Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography, Divider, IconButton } from '@mui/material';
// components
import { Icon } from '@iconify/react';
import Page from '../../../components/Page';
import Scrollbar from "../../../components/Scrollbar";
import ContactMasterDetailsComponent from "../../../components/SidebarMenuComponents/MasterComponent/ContactMasterDetailsComponent";
import AppCache from '../../../services/AppCache';
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
}));
export default function ContactMasterDetailsPage() {
    const CurrentClientContact = AppCache.GetClientInstallationLocations();
    console.log("CurrentClientContact", CurrentClientContact);
    const navigate = useNavigate();
    const location = useLocation()
    const { contactmaster } = location.state;
    const { cliInstallation } = location.state;
    console.log("contactmaster", contactmaster);
    console.log("cliInstallation", cliInstallation);
    const handelNav = () => {
       
        navigate('/dashboard/clientinstallationdetailspage', {
            replace: true,
            state: {

                cliInstallation: CurrentClientContact
               
            }
        });
    }

    return (
        <Page title="  Contact Details Details | tiketic" >
            <Stack direction="row">
                <IconButton onClick={handelNav}>
                    <Icon icon="ic:baseline-arrow-circle-left" />
                    </IconButton>

            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Contact Details
                </Typography>
                </Stack>
            <Divider />
            <Card sx={{ height: '470px' }}>
                <Scrollbar>
                    <ContentStyle>
                        <ContactMasterDetailsComponent contactmaster={contactmaster} cliInstallation={cliInstallation} />
                    </ContentStyle>
                </Scrollbar>
            </Card>
        </Page>
    );
}

