
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import LoginImageUpload from '../../../components/SidebarMenuComponents/OtherComponent/LoginImageUpload';
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
    justifyContent:'center',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));
export default function LoginImageUploadPage() {
    return (
        <Page title=" Login Image  | tiketic" >
          
             
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={2}>
                Login Image Uploader
            </Typography>

                
                <Card >
                  
                        <ContentStyle>
                            <LoginImageUpload />
                        </ContentStyle>
                   
                </Card>
           
        </Page>
    );
}

