import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, IconButton, Typography, Modal, FormControl, InputLabel, Select, MenuItem, Button, Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Icon } from '@iconify/react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function ContactMasterDetailsComponent({ cliInstallation }) {


    console.log("kkkkkkk", cliInstallation.ContactMaster);
    const TABLE_HEAD = [
       
        { field: 'Id', headerName: 'Id', width: 200 },
        { field: 'ContactPersonName', headerName: 'Contact Person Name', width: 200 },
        { field: 'Designation', headerName: 'Designation', width: 200 },
        { field: 'ContactNo', headerName: 'ContactNo', width: 200 },
        { field: 'Mobile', headerName: 'Mobile', width: 150 },
        { field: 'Location', headerName: 'Location', width: 150 },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },

    ];

    const navigate = useNavigate();
    const [IdValue, setIdValue] = useState();
    const [contactData, setContactData] = useState([]);
    const [contactidValue, setContactIdValue] = useState('');
    const [siteidValue, setSiteIdValue] = useState('');
    const [locate, setLocate] = useState();
    const [contactname, setContactName] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [mobi, setMobi] = useState();
    const [emailValue, setEmailValue] = useState();
    const [desiValue, setDesiValue] = useState();
    const [hideAll, setHideAll] = useState(false);
    const contactid = cliInstallation.ContactId;

    console.log("contactid", contactid);


    const handleClick = (event, cellValues) => {
        setHideAll(true);
        setSiteIdValue(cellValues.row.SiteId);
        setContactIdValue(cellValues.row.ContactId);
        setLocate(cellValues.row.Location);
        setContactName(cellValues.row.ContactPersonName);
        setDesiValue(cellValues.row.Designation);
        setContactNumber(cellValues.row.ContactNo);
        setMobi(cellValues.row.Mobile);
        setEmailValue(cellValues.row.Email);
        setIdValue(cellValues.row.Id);
        console.log("cellValues.row.ContactId", cellValues.row.ContactId);
    };

    const handleLacation = (e) => {
        setLocate(e.target.value)
    };
    const handleContactName = (e) => {
        setContactName(e.target.value)
    };
    const handleDesignation = (e) => {
        setDesiValue(e.target.value)
    };
    const handleContactNumber = (e) => {
        setContactNumber(e.target.value)
    };
    const handleMobile = (e) => {
        setMobi(e.target.value)
    };
    const handleEmail = (e) => {
        setEmailValue(e.target.value)
    };
    const handleAddContact = (e) => {
        setHideAll(true);

                    setLocate("");
                    setContactName("");
                    setDesiValue("");
                    setContactNumber("");
                    setMobi("");
                    setEmailValue("");

                

    };

    useEffect(() => {

        const Contactpayload = { ContactID: cliInstallation.ContactId }

        URLS.getContactMasterData(Contactpayload)
            .then((response) => {

                setContactData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });


    }, []);

    // executive ID
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);


    // CheckBox



    // Add another
    const [currentButtonClick, setCurrentButtonClick] = useState("");
    const handelAddAnotherClick = () => {
        setCurrentButtonClick("addanother")
    };

   

   

    // Modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handelCancel = () => {
        navigate('/dashboard/app', { relpace: true });
    }
    const previousSiteId = null;
    const previousContactId = null;

    const handleSave = () => {
        if (IdValue) {
            const payloadUpdate = {
                Id: IdValue,
                SiteId: siteidValue,
                ContactId: contactidValue,
                Location: locate,
                ContactPersonName: contactname,
                Designation: desiValue,
                ContactNo: contactNumber,
                Mobile: mobi,
                Email: emailValue,
            };

            URLS.editContactMaster(payloadUpdate)
                .then((response) => {
                    if (response.status === 200) {

                        navigate('/dashboard/master/clientinstallationlocations', {
                            replace: true,
                            state: {

                                cliInstallation: { cliInstallation }
                            }
                        });

                    }
                });
        } else {
            const payload = {
                SiteId: siteidValue,
                ContactId: contactidValue,
                Location: locate,
                ContactPersonName: contactname,
                Designation: desiValue,
                ContactNo: contactNumber,
                Mobile: mobi,
                Email: emailValue,
            };
            URLS.postContactMaster(payload)
                .then((response) => {
                    if (response.status === 200) {
                        navigate('/dashboard/master/clientinstallationlocations', {
                            replace: true,
                            state: {
                                cliInstallation: {
                                    cliInstallation
                                }
                            }
                        });


                    }

                });
       }
       
        const Contactpayload = { ContactID: cliInstallation.ContactId }

        URLS.getContactMasterData(Contactpayload)
            .then((response) => {

                setContactData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    };

   
    const handleAddAnother = () => {
        const payload = {
            SiteId: siteidValue,
            ContactId: contactidValue,
            Location: locate,
            ContactPersonName: contactname,
            Designation: desiValue,
            ContactNo: contactNumber,
            Mobile: mobi,
            Email: emailValue,
        };
        URLS.postContactMaster(payload)
            .then((response) => {
                if (response.status === 200) {
                   
                    window.location.reload();
                    setLocate("");
                    setContactName("");
                    setDesiValue("");
                    setContactNumber("");
                    setMobi("");
                    setEmailValue("");

                }

            })
    };
    



    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title="Add New Contact">
                    <Button
                        sx={{ color: 'white' }}
                        onClick={handleAddContact}
                        size="small"
                        variant="contained"
                        color="primary">
                        <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                    </Button>
                </Tooltip>
            </div>

                {hideAll ?
                    <>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={2}>

                               
                                <TextField
                                    fullWidth
                                    label="Contact Person Name"
                                    size="small"
                                    value={contactname}
                                    onChange={handleContactName}
                            />

                            <TextField
                                fullWidth
                                label="Designation"
                                size="small"
                                value={desiValue}
                                onChange={handleDesignation}
                            />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                             

                                <TextField
                                    fullWidth
                                    label="Contact Number"
                                    size="small"
                                    value={contactNumber}
                                    onChange={handleContactNumber}
                                />
                            <TextField
                                fullWidth
                                label="Mobile"
                                size="small"
                                value={mobi}
                                onChange={handleMobile}
                            />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                             

                                <TextField
                                    fullWidth
                                    label="Email"
                                    size="small"
                                    value={emailValue}
                                    onChange={handleEmail}
                            />
                            <TextField
                                fullWidth
                                autoComplete="LocationName"
                                size="small"
                                value={locate}
                                label="Location"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleLacation}
                            />

                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2} marginTop={1}>
                            {contactid ? '' :
                                    <LoadingButton

                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddAnother}
                                    >
                                        Add another
                                    </LoadingButton>}
                               
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                    sx={{ color: 'white' }}
                                    onClick={handleSave}
                                    >
                                        Save
                                    </Button>

                                <Button
                                    sx={{ color: 'white' }}
                                    onClick={handleOpen}
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                            </Stack>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" textAlign="center">
                                        Are you sure?
                                    </Typography>
                                    <Stack justifyContent="center" spacing={2} mt={1}>

                                        <Button
                                            sx={{ color: 'white' }}
                                            onClick={handelCancel}
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            sx={{ color: 'white' }}
                                            onClick={handleClose}
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                        >
                                            No
                                        </Button>
                                    </Stack>
                                </Box>
                            </Modal>
                        </Stack>
                </> : ''}
           
         
           
                <div style={{ height: 250, width: '100%' }}>
                <DataGrid rows={contactData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => contactData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>
           </>
    );
}