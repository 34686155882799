import { useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import MarkAsAbsentComponent from '../../../components/SidebarMenuComponents/OtherComponent/MarkAsAbsentComponent';

// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
    justifyContent: 'center',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));
export default function MarkAsAbsentPage() {
    const { menuAlias } = useParams();
    console.log(menuAlias);
    return (
        <Page title="Mark As Absent  | tiketic" >


            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={2}>
                Mark As Absent
            </Typography>


            <Card >

                <ContentStyle>
                    <MarkAsAbsentComponent/>
                </ContentStyle>

            </Card>

        </Page>
    );
}

