import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import AppCache from '../../../services/AppCache';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function ProductLinking() {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    const TABLE_HEAD = [
        { field: 'CommercialProductName', headerName: 'Commercial Product', width: 250 },
        { field: 'NonCommercialProductName', headerName: 'Non-Commercial Product', width: 400 },
        { field: 'Remark', headerName: 'Remark', width: 300, flex: 1 }, 
       
         {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },

        // {
        //    field: 'delete',
        //    headerName: '',
        //    type: 'delete',
        //    width: 20,
        //    renderCell: (cellValues) => {
        //        return (<div>

        //            <IconButton
        //                onClick={(event) => {
        //                    handleDelete(event, cellValues);
        //                }}><Iconify icon="ic:baseline-delete" width='20px' height='20px' color='primary.main' /></IconButton>

        //        </div>
        //        );
        //    }
        // },

    ];

    // dropdown
    const [products, setProducts] = useState([]);
    const [selectedProductsId, setSelectedProductsId] = useState('');
    const [editData, setEditData] = useState([])
    const handleProducts = (id) => {
        setSelectedProductsId(id);

    };
    const navigate = useNavigate();
    const handleClick = (event, cellValues) => {
        console.log("cellValues.row", cellValues.row);

        const payload = { productLinkingId: cellValues.row.CommercialProductId }
        URLS.getProductLinkingEditData(payload)
            .then((response) => {

                const jsonDataString = JSON.stringify(response.data);

                const modifiedData = JSON.parse(jsonDataString);
                
                setEditData(modifiedData)
                console.log("modifiedData", modifiedData)
                navigate('/dashboard/productlinkingdetailscomponent', { state: { productLinking: modifiedData } })

            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    }


    const handleDelete = (event, cellValues) => {
        const payload = { ProductMasterLinkingId: cellValues.row.Id, DeletedBy: userId }
        console.log("cellValues", cellValues.row);
        console.log("cellValues", cellValues);
        console.log("payload", payload);
     
                URLS.productLinkingDeleteData(payload)
                    .then((response) => {
                        URLS.getProductLinkingData()
                            .then((response) => {
                        setProductLinkingData(response.data);
                        
                    })
                    .catch(error => {
                        handleError(error);
                    });
                        enqueueSnackbar("Record Deleted successfully", { variant: 'success' });
              
            })
            .catch(error => {
                handleError(error);
            });
    }

    useEffect(() => {
        URLS.getCommercialProducts()
            .then((response) => {

                setProducts(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });


    }, []);




    // const navigate = useNavigate();
    // const handleClick = (event, cellValues) => {
    //    navigate('/dashboard/productmasterdetailspage', { state: { proMaster: cellValues.row } })
    // }

   
    const [productLinkingData, setProductLinkingData] = useState([]);

    const [isLoading, setIsLoading] = useState();




    const onFilterClick = () => {
        setIsLoading(true);
        const payload = { productId: selectedProductsId }
        URLS.getProductLinkingListingData(payload)
            .then((response) => {
                setProductLinkingData(response.data);
                setIsLoading(false);

            })
            .catch(error => {
                // On error
                enqueueSnackbar("Data not found", { variant: 'error' });
                /* handleError(error, enqueueSnackbar); */
            });
    };

    
    console.log("selectedProductsId>>>>>", selectedProductsId)
    const handleReset = () => {
        setProductLinkingData([]);
        setSelectedProductsId("")
    }

    useEffect(() => {
        setProductLinkingData([]);
        setSelectedProductsId("")
    }, [location.key]); 

    return (

        <Page title="Product Linking Listing" >


            <Card >


                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <FormControl
                        size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}>
                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Products</InputLabel>
                        <Select
                            label="Prducts"
                            size="small"
                            value={selectedProductsId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handleProducts()} >Select</MenuItem>

                            {products.map((product) => (
                                <MenuItem key={product.Id} value={product.Id} onClick={() => handleProducts(product.Id)}> {product.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>


               
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}

                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >

                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/productlinkingdetailscomponent'
                                variant="contained"
                                size="small"
                                state={{ productLinking: { ProductMasterLinkingId: '', ProductId: '', CommercialProductId: '', Remark: '', CreatedBy:''}}}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleReset}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                        </Tooltip >

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={productLinkingData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => productLinkingData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}