import { useState,useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useResponsive from '../../hooks/useResponsive';


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 46;
const APP_BAR_DESKTOP = 46;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  // background: '#F4F6F8',
    background: '#fff',
   // backgroundColor: '#26c6da'

});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 4,
    paddingBottom: theme.spacing(1),
    transition: 'margin-left 0.2s ease-in-out',
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 4,
   /* paddingLeft: theme.spacing(2), */
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
   

    const [open, setOpen] = useState(false);
    const isDesktop = useResponsive('up', 'md');
    const [width, setWidth] = useState(240);

    const onCloseSidebar = () => {
        setOpen(true);
        setWidth(240);
    };

    const onOpenSidebar = () => {
        setOpen(false);
        setWidth(0);
       
    };
    useEffect(() => {
        if (!isDesktop) {
            setOpen(false);
            setWidth(0);
        }
    }, [isDesktop]);
  return (
    <RootStyle>
      {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)} /> */}
          {/* <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}
          
          <DashboardNavbar dwidth={width} openNav={open} onOpenSidebar={onCloseSidebar} />
          < DashboardSidebar isOpenSidebar={open} onCloseSidebar={onCloseSidebar} dwidth={width} onOpenSidebar={onOpenSidebar} />
          
          <MainStyle dwidth={open ? width : 0}>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
