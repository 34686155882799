import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import ClientMaster from '../../../components/SidebarMenuComponents/MasterComponent/ClientMaster'
import ClientInstallationComponent from "../../../components/SidebarMenuComponents/MasterComponent/ClientInstallationComponent";
import Scrollbar from "../../../components/Scrollbar";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));
export default function ClientInstallationDetailsPage() {

    const location = useLocation()
    const { cliInstallation } = location.state;

    return (
        <Page title="  Client Installation Locations | tiketic" >

            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Client Installation Locations
            </Typography>
            <Card>
                        <Scrollbar>
                        <ContentStyle>
                            <ClientInstallationComponent cliInstallation={cliInstallation}/>
                            </ContentStyle>
                            </Scrollbar>
                   
                    </Card>
               
          
        </Page>
    );
}

