import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, Typography, Modal, IconButton, FormControl, InputLabel, Chip , Select, MenuItem, Button, Box } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { isSunday, isSameDay } from 'date-fns'; // Import necessary date functions
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import Moment from 'moment';
import styled from 'styled-components';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';

// -----------------------------------------------------------------------------------------------------

const PillBadge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 999px;
  background-color: #e0e0e0;
  color: #333;
  font-weight: bold;
  margin-right: 8px;
`;

export default function MarkAsAbsentComponent() {
    const [executives, setExecutives] = useState([]);
    const [executivesChildrens, setExecutivesChildrens] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [ExecutivelistId, setExecutiveId] = useState();
    const [ExecutiveChildrenlistId, setExecutiveChildrenId] = useState();
    const [hide, setHide] = useState(false);
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const ExeId = CurrentLoggedInUser.ExecutiveId;
    const [userId, setUserId] = useState([]);
    const publicHolidays = ['2023-08-15', '2023-10-02', '2023-12-25']; // Replace with actual public holidays
    const [selectedDates, setSelectedDates] = useState([]);
    const [isIncludeAll, setIsIncludeAll] = useState(false);
   

    const handleCheckboxChange = (event) => {
        setHide(true);
        setIsIncludeAll(event.target.checked);
    };
    const handleEffFrom = (newDates) => {
        setSelectedDates((prevDates) => {
            const updatedDates = [...prevDates, newDates];
            console.log('selectedDates:', updatedDates);
            return updatedDates;
        });
    };

    const handleDateDelete = (dateToDelete) => {
        setSelectedDates((prevDates) => prevDates.filter((date) => date !== dateToDelete));
    };

    const handelSelectedExecutive = (id) => {
        setExecutiveId(id);
        setExecutiveChildrenId(id);
    };

   

    useEffect(() => {
       
            setUserId(CurrentLoggedInUser.ExecutiveId);
            const contactId = CurrentLoggedInUser.ExecutiveId;
            console.log("ExecutiveID", contactId);
            const payload = { ExecutiveID: contactId }
            URLS.getExecutiveImmediateListDropDown(payload)
                .then((response) => {
                    setExecutives(response.data);
                })
                .catch(error => {
                    // On error
                    console.log("Error");
                });
           
       
            setUserId(CurrentLoggedInUser.ExecutiveId);
            const contact1Id = CurrentLoggedInUser.ExecutiveId;
        console.log("ExecutiveID", contact1Id);
        const payload1 = { ExecutiveID: contact1Id }
            URLS.getExecutiveListDropDown(payload1)
                .then((response) => {
                    setExecutivesChildrens(response.data);
                    console.log("response.data children", response.data)
                })
                .catch(error => {
                    // On error
                    console.log("Error");
                });
        
       

    }, []);


    const handleSave = () => {
        const payload = {
            
            ExecutiveID: ExeId,
            EmployeeeId: ExecutivelistId || ExecutiveChildrenlistId,
            Date: selectedDates
        }
        URLS.postMarkAsAbsent(payload)
            .then((response) => {
                window.location.reload();
                enqueueSnackbar("Record saved successfully", { variant: 'success' });
            })
            .catch(error => {
                // On error
                console.log("Error");
            });

    }
    return (
        <>
            <Stack direction="row" spacing={4}>
                {hide && isIncludeAll?


                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: '230px' }}
                    >
                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Executive</InputLabel>
                        <Select
                            value={ExecutiveChildrenlistId}
                            label="Executive"
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {executivesChildrens.map((project) => (

                                <MenuItem key={project.Id} value={project.Id} onClick={() => handelSelectedExecutive(project.Id, project.Name)}> {project.Name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    :
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: '230px' }}
                    >
                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Executive</InputLabel>
                        <Select
                            value={ExecutivelistId}
                            label="Executive"
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {executives.map((project) => (

                                <MenuItem key={project.Id} value={project.Id} onClick={() => handelSelectedExecutive(project.Id, project.Name)}> {project.Name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>}

               
                <FormGroup>
                    <FormControlLabel

                        control={<Checkbox checked={isIncludeAll} onChange={handleCheckboxChange} />}
                        label="Include All"
                    />
                </FormGroup>
                </Stack>
            <Stack direction={{ xs: 'row', sm: 'column' }} width="230px" mt={3} spacing={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        multiple
                        label="Select Date"
                        inputFormat="dd/MMM/yyyy"
                        value={selectedDates[selectedDates.length - 1]}
                        onChange={handleEffFrom}
                        renderInput={(params) => <TextField size="small" {...params} />}
                        shouldDisableDate={(date) => {
                            // Disable Sundays (day 0)
                            if (isSunday(date)) {
                                return true;
                            }

                            // Disable public holidays
                            const formattedDate = Moment(date).format('YYYY-MM-DD');
                            if (publicHolidays.includes(formattedDate)) {
                                return true;
                            }

                            return false;
                        }}
                    />
                </LocalizationProvider>
            </Stack>

            <Stack direction="row" spacing={1} sx={{ mt: '20px', ml: '1px' }}>
                {selectedDates.map((date) => (
                    <div key={date} style={{ display: 'flex', alignItems: 'center' }}>
                        

                        <PillBadge>
                            {Moment(date).format('DD-MMM-yyyy')}
                        </PillBadge>
                        <IconButton onClick={() => handleDateDelete(date)} color="error">
                            <Icon icon="ic:baseline-cancel" />
                        </IconButton>
                    </div>
                ))}
            </Stack>



           
            <LoadingButton
                size="medium"
                type="submit"
                onClick={handleSave}
                variant="contained"
                sx={{ color: 'white', width:'230px', mt:2 }}
            >
                Save
            </LoadingButton>
        </>

    );
}