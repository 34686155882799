import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------
const Status = [
    { id: -1, Name: 'All' },
    { id: 0, Name: 'Pending' },
    { id: 1, Name: 'Completed' },
    { id: 2, Name: 'Cancelled' },
];

export default function GenerateInstallationReport() {

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    // Status DropDown
    const [statusId, setStatusId] = useState('');
    const [defaultStatusId, setDefultStatusId] = useState(-1);

    // Client DropDown
    const [clients, setClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('');

    // Dates
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(endOfMonth(new Date()));

    // Report 
    const [reports, setReports] = useState([]);

    // Loading
    const [isLoading, setIsLoading] = useState();

    // Data Table
    const TABLE_HEAD = [
        { field: 'ProductName', headerName: 'Product Name', width: 150 },
        { field: 'ClientName', headerName: 'Client', width: 300 },
        {
            field: 'InstallationStatusUpdateDateTime', headerName: 'Installation Date', width: 300, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy HH:mm")
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
         },

    ];

    // Client dropdown
    const handleClient = (id) => {
        setSelectedClientId(id);
    };

    // Dates 
    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };

    const [data, setData] = useState([])


    // Edit 
     const navigate = useNavigate();
     const handleClick = (event, cellValues) => {
         console.log("cellValues.row", cellValues.row);

         const payload = { EditID: cellValues.row.Id }
         URLS.getGenerateInstallationReportEditData(payload)
             .then((response) => {

                 const jsonDataString = JSON.stringify(response.data);

                 const modifiedData = JSON.parse(jsonDataString);
                 setData(modifiedData);

                 navigate('/dashboard/generateinstallationreportdetailspage', { state: { reports: modifiedData } })
             })
             .catch(error => {
                 handleError(error, enqueueSnackbar);
             });
     }

     // Filter
     const onFilterClick = () => {
        setIsLoading(true);
        const payload = { ClientID: selectedClientId, FromDate: effectiveFromValue, ToDate: effectiveToValue, StatusId: statusId }
        URLS.getGenerateInstallationReportListing(payload)
            .then((response) => {
                setReports(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                // On error
                handleError(error, enqueueSnackbar);
            });

    };

    // Reset
     const handleReset =()=> {
        setSelectedClientId("");
         setStatusId("");
         setDefultStatusId(-1);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setReports([]);
    }

    // Status DropDown
    const handleStatusChange = (event) => {
        const newSelectedCategory = event.target.value;
        setStatusId(newSelectedCategory);
    };
    const handleSelectedStatus = (id) => {
        setDefultStatusId(id);
    };

    // Client dropdown api call
    useEffect(() => {
        URLS.getOAClient()
            .then((response) => {
                setClients(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    }, []);

    useEffect(() => {
        setSelectedClientId("");
        setStatusId("");
        setDefultStatusId(-1);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setReports([]);
    }, [location.key]); 
    console.log("landing page", reports)
    return (

        <Page title="Generate Installation Report" >

            <Card >

                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <FormControl
                        size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}>
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Clients</InputLabel>
                        <Select
                            label="Clients"
                            size="small"
                            value={selectedClientId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handleClient() } >Select</MenuItem>

                            {clients.map((product) => (
                                <MenuItem key={product.ContactId} value={product.ContactId} onClick={() => handleClient(product.ContactId)}> {product.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>


                    {/* <FormControl sx={{ m: 1, minWidth: 120 }}> */}
                    {/*    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Grouping</InputLabel> */}
                    {/*    <Select native defaultValue="" id="grouped-native-select" label="Grouping"> */}
                    {/*        <option aria-label="None" value=""  /> */}
                    {/*        {clients.map((product) => ( */}
                    {/*       <option key={product.ContactId} value={product.ContactId} onClick={() => handleClient(product.ContactId)}> */}
                    {/*            {product.Name} */}
                    {/*         </option> */}
                    {/*      ))} */}
                    {/*    </Select> */}
                    {/* </FormControl> */}

                    {/* <FormControl */}
                    {/*    size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}> */}
                    {/*    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Clients</InputLabel> */}
                    {/*    <Select */}
                    {/*        native */}
                    {/*        label="Clients" */}
                    {/*        size="small" */}
                    {/*        value={selectedClientId} */}
                    {/*        MenuProps={{ */}
                    {/*            PaperProps: { */}
                    {/*                sx: { */}
                    {/*                    backgroundColor: '#fbe9e7', // Set the background color here */}
                    {/*                    maxHeight: '200px', */}
                    {/*                    width: '200px',// Set the max height here */}
                    {/*                } */}
                    {/*            } */}
                    {/*        }} */}
                    {/*    > */}
                    {/*        <option value="" onClick={() => handleClient("")}>Select</option> */}
                    {/*        {clients.map((product) => ( */}
                    {/*            <option key={product.ContactId} value={product.ContactId} onClick={() => handleClient(product.ContactId)}> */}
                    {/*                {product.Name} */}
                    {/*            </option> */}
                    {/*        ))} */}
                    {/*    </Select> */}

                    {/* </FormControl> */}

                    <FormControl fullWidth size="small">
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                            Status
                        </InputLabel>
                        <Select
                            label="Status"
                            size="small"
                            value={defaultStatusId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                    },
                                },
                            }}
                            
                            onChange={handleStatusChange}
                        >

                            {Status.map((category) => (
                                <MenuItem
                                    key={category.id}
                                    value={category.id}
                                    onClick={() => handleSelectedStatus(category.id)}
                                >
                                    {category.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                            onChange={handleEffFrom}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                            onChange={handleEffTo}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>


                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}

                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >

                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/generateinstallationreportdetailspage'
                                variant="contained"
                                size="small"
                                state={{ reports: { Id: '', OADetailsId: '', ClientId: '', Remark: '', IssueId: '', InstallationStatusUpdateDateTime:new Date(), CreatedBy: '', InstallationReportDetailsModel: { InstallationReportDetailsId: '', InstallationReportMasterId: '', InstallationReportQuestionnairesMasterId: '', QuestionId: '', Answers :''} } }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleReset}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                        </Tooltip >

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={reports} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => reports.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}