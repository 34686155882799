import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

// material
import { Icon } from '@iconify/react';
import {
    Button,
    Card,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Tooltip
} from '@mui/material';


import { useSnackbar } from 'notistack';
import URLS from '../../../services/urls.service';



import Page from '../../Page';
import Iconify from '../../Iconify';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar'

import { handleError } from '../../Common/Utils';


// ----------------------------------------------------------------------




function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export default function ApiMaster() {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const TABLE_HEAD = [
       
        { field: 'APIId', headerName: 'API ID', width: 150, hideable: 'true' }, 
        { field: 'APIMethodName', headerName: 'API Method Name', width: 500},
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            minWidth: 20,
            renderCell: (cellValues) => {

                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },
    ];
    const columnVisibilityModel = {
        Id :false
    }
    const [apiMasterFilterData, setApiMasterFilterData] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [selectApiData, setSelectApiData] = useState([]);
    const [selectApiId, setSelectApiId] = useState('');
    const handleClick = (event, cellValues) => {
        
        console.log("cellValues.row", cellValues.row.Id)
        const payload = { APIMasterId: cellValues.row.Id }
        URLS.getAPIMasterEditData(payload)
            .then((response) => {
                const jsonData = JSON.stringify(response.data);
                const editData = JSON.parse(jsonData)
                setData(editData)
                navigate('/dashboard/apimasterdetailpage', { state: { apiMaster: editData  } })
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    }

    const handleApiMasterId = (id) => {
        setSelectApiId(id);
    };

    const onFilterClick = () => {
        setIsLoading(true);
        const payload = { APIMasterId: selectApiId };
        console.log("payload",payload)
        //   let payload = { clientId };
        URLS.getAPIMasterListingData(payload)
            .then((response) => {
                setApiMasterFilterData(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    };

    useEffect(() => {
        URLS.getApiMasterId()
            .then((response) => {
                setSelectApiData(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    }, []);

    const onResetClick1 = (event) => {
        setSelectApiId('');
        setApiMasterFilterData([]);
    };
    useEffect(() => {
        setSelectApiId('');
        setApiMasterFilterData([]);
    }, [location.key]); 

    return (

        <Page title="API Master" >
            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <FormControl
                        size="small"
                        sx={{ minWidth: 230, fontSize: '0.5rem' }}
                    >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >API Id</InputLabel>
                        <Select
                            label="API Id"
                            size="small"
                            value={selectApiId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handleApiMasterId()} >Select</MenuItem>

                            {selectApiData.map((component) => (
                                <MenuItem key={component.Id} value={component.Id} onClick={() => handleApiMasterId(component.Id)}> {component.APIId}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>

                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"

                                onClick={onFilterClick}
                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >


                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/apimasterdetailpage'
                                variant="contained"
                                size="small"
                                state={{ apiMaster: {Id:'', APIId: '', APIMethodName: '', APIAuthKey: '', APITableName: '', APIPostProcessName: ''} }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={onResetClick1}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                    </Stack>

                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={apiMasterFilterData} density={'compact'} columns={TABLE_HEAD} columnVisibilityModel={columnVisibilityModel} getRowId={(row) => apiMasterFilterData.indexOf(row)} components={{
                        Toolbar: GridToolbar, 
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}