import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import IRQuestionnairesListing from "../../../components/SidebarMenuComponents/OAComponent/IRQuestionnairesListing";
import IRQuestionnairesDetailsComponent from "../../../components/SidebarMenuComponents/OAComponent/IRQuestionnairesDetailsComponent";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function IRQuestionnairesDetailsPage() {
    const location = useLocation();

    return (
        <Page title=" IR Questionnaires Details| tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                IR Questionnaires Details
            </Typography>

            <ContentStyle>
                <IRQuestionnairesDetailsComponent/>
            </ContentStyle>
        </Page>
    );
}

