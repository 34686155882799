import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
    Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import * as LocalStorage from 'local-storage';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';


// ----------------------------------------------------------------------

export default function LoginForm() {
    const { enqueueSnackbar } = useSnackbar();
    const [disable, setDisable] = useState(false)

    useEffect(() => {
        setDisable(true);

    }, []);
    console.log()
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            ApplicationAccesId: 'TiketicPortalAccessId',
            remember: false
        },

        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {

            const payload = { LoginId: values.email, Password: values.password, ApplicationAccesId: 'TiketicPortalAccessId' };
            URLS.loginURL(payload)
                .then((response) => {
                    console.log("response", response);
                    actions.setSubmitting(false);
                    // Successful response
                    if (response.status === 404) {
                        enqueueSnackbar("invalid Username or Password");
                    }
                    else if (response.status === 200) {
                        AppCache.SetCurrentLoggedInUser(response.data);
                        // console.log("Dashboard list", response.data)
                        navigate('/dashboard/app', { replace: true });
                    }
                     
                    else {
                        // Failure response
                        alert(response);
                    }
                })
                .catch(error => {
                    actions.setSubmitting(false);
                    enqueueSnackbar("invalid Username or Password");

                });

        }

    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        size="small"
                        type="email"
                        label="Email Address"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        fullWidth
                        size="small"
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    <FormControlLabel
                        disabled={disable}
                        variant="h4"
                        control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                        label="Remember Me"
                    />

                    <Link style={{ cursor: disable ? 'none' : 'auto', opacity: 0.2 }}
                        onClick={disable ? (e) => e.preventDefault() : null} disabled={disable} component={RouterLink} variant="subtitle1" to="#">
                        Forgot Password?
                    </Link>
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"

                    variant="contained"
                    loading={isSubmitting}
                    // sx={{ background :'#04297A'}}
                    // sx={{ background:'#3B1F22'}}
                    sx={{ color: 'white', fontSize: '16px' }}
                >
                    Login
                </LoadingButton>

            </Form>


        </FormikProvider>

    );
}
