import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useNavigate ,useLocation, Navigate  } from 'react-router-dom';


// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography, Button, Modal ,Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useSnackbar } from 'notistack';
import Page from '../../../../components/Page';
import ProjectInformationDetailsComponent from '../../../../components/SidebarMenuComponents/OtherComponent/ProjectInformationComponent/ProjectInformationDetailsComponent';
import Scrollbar from "../../../../components/Scrollbar";
import URLS from '../../../../services/urls.service';
import AppCache from '../../../../services/AppCache';
import ModalPopUp from '../../../../components/Common/ModalPopUp';

// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    display: 'flex',

    flexDirection: 'column',
    padding: theme.spacing(0, 1)
}));

const style = {
    position:'absolute',
    top: '50%',
    justifyContent:'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};
export default function ProjectInformationDetailsPage() {
    const { enqueueSnackbar } = useSnackbar();

    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const location = useLocation()
    const { project } = location.state;
    console.log("project", project);
  
    const [open, setOpen] = useState(false);
   

    const [DomainExpiry, setDomainExpiryValue] = useState(project.DomainExpiry);
    const [SSLExpiry, setSSLExpiryValue] = useState(project.SSLExpiry);
    const [AgreementExpiry, setAgreementExpiryValue] = useState(project.AgreementExpiry);
    const [PurchaseOrderExpiry, setPurchaseOrderExpiryValue] = useState(project.PurchaseOrderExpiry);
    const [userId, setUserId] = useState([]);

    const [clientId, setClientId] = useState(project.ClientID);
    const [clientAlias, setClientAlias] = useState(project.ClientAlias);
    const [clientName, setClientName] = useState(project.ClientName);
    console.log("clientName", clientName);
    console.log("clientAlias", clientAlias);

    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/other/projectinformation', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

    const handleDomain = (newValue) => {
        setDomainExpiryValue(newValue)
    };
    const handleSSLExpiry = (newValue) => {
        setSSLExpiryValue(newValue)
    };
    const handleAgreement = (newValue) => {
        setAgreementExpiryValue(newValue)
    };
    const handlePurchase = (newValue) => {
        setPurchaseOrderExpiryValue(newValue)
    };
    const [clientAliasdropDownData, setclientAliasdropDownData] = useState([]);
    const [serverProData, setServerProData] = useState([]);
    const [serviceProvider, setServiceProvider] = useState([]);
    useEffect(() => {
        URLS.getDropdown()
            .then((response) => {
                setclientAliasdropDownData(response.data);
                console.log("getClientAliasDropdownData", response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });
        URLS.getServerProvider()
            .then((response) => {
                setServerProData(response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });
        URLS.getServiceProvider()
            .then((response) => {
                setServiceProvider(response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });
    }, []);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);
    const [disable, setDisable] = useState(false);
    const [isCheckedActive, setIsCheckedAcive] = useState(project.IsActive);
    const handleIsActiveChange = (event) => {
        console.log("event.target.checked", event.target.checked)
        setIsCheckedAcive(event.target.checked);
    };
    useEffect(() => {
        if (project.ClientID !== "") {
            setDisable(true)
        } else
            setDisable(false)
    }, []);

  

   
    const handelSelectedClient = (id, erpCode,name ) => {
        console.log("page handleselectedClient",id,erpCode,name);
        setClientId(id);
        setClientAlias(erpCode);
        setClientName(name);
    };

    const ProjectInfoDetailsSchema = Yup.object().shape({
        ProjectURL: Yup.string()
            .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Enter correct url!'
            )
            .required('Please Enter Website'),
        ProjectVersion: Yup.string().required('Project Version is required'),
        ProjectDomain: Yup.string().required('Project Domain is required'),
        SSLClient: Yup.string().required('SSL Client is required'),
        WebAPIURL: Yup.string()
            .url('Invalid Website URL')
            .required('Web API URL is required'),

        HttpsUploadURL: Yup.string()
            .url('Invalid Https Upload URL')
            .required('Https Upload URL is required'),

        ServerOwnership: Yup.string().required(' Server Ownership is required'),
        ZylemDBName: Yup.string().required('Zylem Database Name is required'),
        PurchaseOrderNo: Yup.number()
            .min(0, 'Purchase Order Number should not be negative')
            .required('Purchase Order Number is required'),
        PurchaseOrderPeriod: Yup.string().required('Purchase Order Period is required'),
        // ServerName: Yup.string().required('Server Name is required'),
        // ServerIP: Yup.string().required('Server IP is required'),
        // PublicIP: Yup.string().required('Public IP is required'),
        // ConfigurationDetails: Yup.string().required('Configuration Details is required'),
    });
    const navigate = useNavigate();
   
   
    const formik = useFormik({
        initialValues: {
            ClientID: clientId,
            ClientAlias: clientAlias,
            ClientName: clientName,
            ProjectURL: project.ProjectURL,
            ProjectVersion: project.ProjectVersion,
            ProjectDomain: project.ProjectDomain,
            DomainExpiry: project.DomainExpiry,
            SSLClient: project.SSLClient,
            SSLExpiry: project.SSLExpiry,
            
            WebAPIURL: project.WebAPIURL,
            HttpsUploadURL: project.HttpsUploadURL,
            ServerOwnership: project.ServerOwnership,
            ServerProvider: project.ServerProvider,
            ZylemDBName: project.ZylemDBName,
            AgreementExpiry: project.AgreementExpiry,
          
            PurchaseOrderNo: project.PurchaseOrderNo,
            PurchaseOrderExpiry: project.PurchaseOrderExpiry,
          
            PurchaseOrderPeriod: project.PurchaseOrderPeriod,
            ServiceProvider: project.ServiceProvider,
            IsActive: isCheckedActive,
            ServerName: project.ServerName,
            ServerIP: project.ServerIP,
            PublicIP: project.PublicIP,
            ConfigurationDetails: project.ConfigurationDetails,
            ExecutiveID: userId,
           
          
        },
        validationSchema :ProjectInfoDetailsSchema,
        onSubmit: (values, actions) => {
            console.log("Formik", values);
            const payload = {

                "ClientID": clientId,
                "ClientAlias": clientAlias,
                "ClientName": clientName,
                "ProjectURL": values.ProjectURL,
                "ProjectVersion": values.ProjectVersion,
                "ProjectDomain": values.ProjectDomain,
                "DomainExpiry": DomainExpiry,
                "SSLClient": values.SSLClient,
                "SSLExpiry": SSLExpiry,
                "WebAPIURL": values.WebAPIURL,
                "HttpsUploadURL": values.HttpsUploadURL,
                "ServerOwnership": values.ServerOwnership,
                "ServerProvider": values.ServerProvider,
                "ZylemDBName": values.ZylemDBName,
                "AgreementExpiry": AgreementExpiry,
                "PurchaseOrderNo": values.PurchaseOrderNo,
                "PurchaseOrderExpiry": PurchaseOrderExpiry,
                "PurchaseOrderPeriod": values.PurchaseOrderPeriod,
                "ServiceProvider": values.ServiceProvider,
                "IsActive": isCheckedActive,
                "ServerName": values.ServerName,
                "ServerIP": values.ServerIP,
                "PublicIP": values.PublicIP,
                "ConfigurationDetails": values.ConfigurationDetails,
                "ExecutiveID": userId,
            };
            console.log("clientName", clientName)
            console.log("formik project", payload);
            if (clientAlias.length === 0) {
                // Display an error message or perform any validation action
                enqueueSnackbar("Please select Client");

                // You can disable the generate button or show an error message to the user
            } else if (formik.values.ServerName.length === 0){
                enqueueSnackbar("Please select ServerName  on Project Server Tab");

            } else if (formik.values.ServerIP.length === 0) {
                enqueueSnackbar("Please select Server IP on Project Server Tab");

            } else if (formik.values.PublicIP.length === 0) {
                enqueueSnackbar("Please select PublicIP  on Project Server Tab");

            } else if (formik.values.ConfigurationDetails.length === 0) {
                enqueueSnackbar("Please select Configuration Details  on Project Server Tab");

            }else {
                URLS.postProjectInformation(payload)
                    .then((response) => {
                        if (response.status === 200) {
                            navigate('/dashboard/other/projectinformation', { replace: true });
                            enqueueSnackbar("Record saved successfully", { variant: 'success' });
                        }
                        else {
                            // Failure response
                            // alert("Error");
                            console.log("error");

                        }
                    })
            }
        }

    });

    const { handleSubmit } = formik;

  

    return (
        <Page title=" Project Information Details | tiketic" >
            <FormikProvider value={formik}>
                <Form variant="body2" autoComplete="off" noValidate onSubmit={handleSubmit}>
                   
                    <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                        Project Information Details
                    </Typography>

                    <Card>
                                <Scrollbar>
                                    <ContentStyle>

                                <ProjectInformationDetailsComponent serverProData={serverProData} clientAliasdropDownData={clientAliasdropDownData} formik={formik} DomainExpiry={DomainExpiry} SSLExpiry={SSLExpiry} AgreementExpiry={AgreementExpiry} PurchaseOrderExpiry={PurchaseOrderExpiry} handleDomain={handleDomain} handleSSLExpiry={handleSSLExpiry} handleAgreement={handleAgreement} handlePurchase={handlePurchase} clientId={clientId} clientName={clientName} clientAlias={clientAlias} handelSelectedClient={handelSelectedClient} disable={disable} serviceProvider={serviceProvider} isCheckedActive={isCheckedActive} handleIsActiveChange={handleIsActiveChange}/>

                                    </ContentStyle>
                                </Scrollbar>
                        </Card>

                
                    <Stack spacing={2} direction="row" mt={1} justifyContent="center">
                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Save
                        </LoadingButton>

                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>
                       
                    </Stack>
                </Form>
                {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}
            </FormikProvider>
        </Page>
    );
}

