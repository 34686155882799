import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import RequestDetailsComponent from "../../../components/SidebarMenuComponents/ApprovalComponent/RequestDetailsComponent";
import Scrollbar from "../../../components/Scrollbar";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    justifyContent: 'left',
}));
export default function RequestDetailsPage() {
    const location = useLocation()
    const { requestMaster } = location.state;
    const { isDisabled } = location.state;
    const { selectedlevels } = location.state;
    console.log("selectedlevels", selectedlevels);
    return (
        <Page title="Request | tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Request Category Details
            </Typography>
            <Card>
                <Scrollbar>
            <ContentStyle>
                        <RequestDetailsComponent requestMaster={requestMaster} isDisabled={isDisabled} selectedlevels={selectedlevels}/>
                    </ContentStyle>
                    </Scrollbar>
                </Card>
        </Page>
    );
}

