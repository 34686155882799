import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    FormControlLabel,
    Checkbox,
    Grid,
    Modal,
    Box,
    Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { startOfMonth, endOfMonth } from 'date-fns';
import Moment from 'moment';
import Page from '../../Page';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import { handleError } from '../../Common/Utils';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------




function descendingComparator(a, b, orderBy) {


    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    // if (query) {

    //    return filter(array, (_project) => _project.ClientAlias.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    // }
    return stabilizedThis.map((el) => el[0]);
}

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 10,
    p: 2,
};
export default function ExecutiveExpenses({ executiveExpenses }) {

    const TABLE_HEAD = [
        { field: 'ClientName', headerName: 'Date', width: 150 },
        { field: 'ClientAlias', headerName: 'Category', width: 150 },
        { field: 'SaplMailerAPI', headerName: 'Project', width: 150  },
        { field: 'FromEmailId', headerName: 'Client', width: 150 },
        { field: 'CallListEmail', headerName: 'Amount', width: 150  },
        { field: 'ProjectStatus', headerName: 'Remark', width: 150  },
        { field: 'IsActive', headerName: 'Description', width: 150  },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => (
                <div>
                    <IconButton
                        onClick={(event) => {
                            handleEdit(event, cellValues);
                        }}>
                        <Iconify icon="ic:outline-edit" width="20px" height="20px" color="primary.main" />
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'delete',
            headerName: '',
            type: 'delete',
            width: 20,
            renderCell: (cellValues) => (
                <div>
                    <IconButton
                        onClick={(event) => {
                            handleDelete(cellValues);
                        }}>
                        <Iconify icon="ic:baseline-delete" width="20px" height="20px" color="primary.main" />
                    </IconButton>
                </div>
            ),
        },
    ];

    const [rows, setRows] = useState([
        {
            id: 1,
            ClientName: Moment().format('DD-MMM-yyyy'),
            ClientAlias: 'Travel',
            SaplMailerAPI: 'Project Alpha',
            FromEmailId: 'Client A',
            CallListEmail: '1000',
            ProjectStatus: 'Completed',
            IsActive: 'Yes',
        },
    ]);

    const { enqueueSnackbar } = useSnackbar();
    const [fromDateValue, setFromDateValue] = useState(startOfMonth(new Date()));
    const [toDateValue, setToDateValue] = useState(endOfMonth(new Date()));
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleEdit = (event, cellValues) => {
        console.log('Edit clicked', cellValues);
    };

    const handleDelete = ( cellValues) => {
        console.log('Delete clicked', cellValues);
        setSelectedRow(cellValues.row);
        setOpen(true);
    };
    const handleEffFrom = (newValue) => {
        setFromDateValue(newValue)
    };

    const handleEffTo = (newValue) => {
        setToDateValue(newValue)
    };
    const handleClose1 = () => setOpen(false);

    const formatRowData = (row) => `
        Date: ${row.ClientName}
        Category: ${row.ClientAlias}
        Project: ${row.SaplMailerAPI}
        Client: ${row.FromEmailId}
        Amount: ${row.CallListEmail}
        Remark: ${row.ProjectStatus}
        Description: ${row.IsActive}
    `;

    return (

        <Page title="Client Master" >


            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={2}>
                   
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="From Date"
                                    inputFormat="dd/MMM/yyyy"
                                    value={Moment(fromDateValue).format('DD-MMM-yyyy')}
                                    onChange={handleEffFrom}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="To Date"
                                    inputFormat="dd/MMM/yyyy"
                                    value={Moment(toDateValue).format('DD-MMM-yyyy')}
                            onChange={handleEffTo}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </LocalizationProvider>

                        
                    {/*  <FormControlLabel control={<Checkbox checked={isCheckedActive} onChange={handleIsActiveChange} />} label="IsActive" style={{ width: '150px' }} /> */}
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>
                        <Tooltip title="Filter">
                            <Button variant="contained" size="small" onClick={{}}>
                                <Icon icon="ic:outline-filter-alt" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Add New">
                            <Button
                                component={RouterLink}
                                to='/dashboard/ExecutiveExpensesDetailsPage'
                                variant="contained"
                                size="small"
                                state={{ clientMaster: { ContactId: '', SubContactID: '', ClientName: '', ERPCode: '', Remarks: '', IsActive: '', SaplMailerAPI: '', fromEmailId: '', CallListEmail: '', ProjectStatus: '', ClientGrouping: '', ExecutiveID: '', TeamsDataMaster: [], ExecutivesDataMaster: [] } }}
                            >
                                <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                       
                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={rows} density={'compact'} columns={TABLE_HEAD}  components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
           
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...style }}>


                    <Typography id="modal-modal-title" variant="subtitle2" component="h2" textAlign="center">
                        {selectedRow ? formatRowData(selectedRow) : 'No data available'}
                    </Typography>
                    <Stack direction="row" mt={2} spacing={2}>
                        <Button
                            sx={{ color: 'white' }}
                            size="small"
                            variant="contained"
                            color="primary"
                        >
                            Delete
                        </Button>
                        <Button
                            sx={{ color: 'white' }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleClose1}

                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>

            </Modal>

        </Page>


    );
}