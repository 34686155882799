import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Divider,
    Tooltip,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../../Iconify';
import Scrollbar from '../../../Scrollbar';
import URLS from '../../../../services/urls.service';
import Page from '../../../Page';
import LoadingProgressBar from '../../../../theme/overrides/LoadingProgressBar';
import ProjectInformationMoreMenu from './ProjectInformationMoreMenu';
import ProjectInformationListHead from './ProjectInformationListHead';


// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    // if (query) {
    //    return filter(array, (_project) => _project.ClientAlias.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    // }
    return stabilizedThis.map((el) => el[0]);
}

export default function ProjectInformation() {

    const TABLE_HEAD = [
        { field: 'ClientAlias', headerName: 'Client Alias', width: 100 },
        { field: 'ClientName', headerName: 'Client Name', width: 260 },
        {
            field: 'DomainExpiry', headerName: 'Domain Expiry', width: 130, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy")
        },
        {
            field: 'SSLExpiry', headerName: 'SSL Expiry', width: 120, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy")
        },
        {
            field: 'AgreementExpiry', headerName: 'Agreement Expiry', width: 130, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy")
        },
        {
            field: 'PurchaseOrderExpiry', headerName: 'Purchase Order Expiry', width: 130, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy")
        },
        {
            field: 'actions',
            headerName: '',
            flex:1,
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },

    ];
    const navigate = useNavigate();
    const handleClick = (event, cellValues) => {
        navigate('/dashboard/projectinformationdetailspage', { state: { project: cellValues.row } })
    }
    const location = useLocation();

    const [testdd, setTestDD] = useState([]);
    console.log("testdd", testdd);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('ClientAlias');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [clientAliasdropDownData, setclientAliasdropDownData] = useState([]);

    const [projectInformation, setProjectInformation] = useState([]);

    const [isLoading, setIsLoading] = useState();



    const [clientId, setClientId] = useState('');

    const handelSelectedClient = (id) => {
        console.log("id", id);
        setClientId(id);

    };
    const onFilterClick = () => {

        if (clientId) {
            setIsLoading(true);

            const payload = { ClientAliasID: clientId };
            //   let payload = { clientId };

            URLS.getProjectinformationDropDown(payload)
                .then((response) => {

                    setProjectInformation(response.data);
                    setIsLoading(false);

                })
                .catch(error => {
                    // On error
                    console.log(" Error ");
                });
        } else {
            URLS.getProjectInformationTable()
                .then((response) => {
                    setProjectInformation(response.data);
                    console.log("sasasdadasad");
                    setIsLoading(false);

                })
                .catch(error => {
                    // On error
                    console.log(" Error ");
                });
        }
       
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = projectInformation.map((n) => n.margCode);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const onResetClick = (event) => {
        setClientId("");
        setProjectInformation([]);
    };

    useEffect(() => { 
        setClientId("");
        setProjectInformation([]);
    }, [location.key])
    const filteredProjects = applySortFilter(projectInformation, getComparator(order, orderBy), filterName);

    const isProjectNotFound = filteredProjects.length === 0;

    useEffect(() => {
        URLS.getClientAliasDropDownList()
            .then((response) => {
                console.log("response.data", response.data);
                setTestDD(response.data);
            })
            .catch(error => {
                // On error ji
                console.log(" Error ");
            });
    }, []);

    return (

        <Page title="Project Information" >


            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <FormControl
                        size="small"
                        sx={{ minWidth: 230, fontSize: '0.5rem' }}
                    >
                        <InputLabel inputlabelprops={{ style: {fontSize: '14px' } }} >Client Alias</InputLabel>
                        <Select
                            label="Client Alias"
                            size="small"
                            value={clientId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>
                            {Array.isArray(testdd) ? (
                             testdd.map((component) => (
                                 <MenuItem key={component.id} value={component.id} onClick={() => handelSelectedClient(component.id, component.clientAlias)}> {component.clientAlias}</MenuItem>
                             ))
                            ) : null}
                        </Select>

                    </FormControl>


                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"

                                onClick={onFilterClick}
                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >







                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/projectinformationdetailspage'
                                variant="contained"
                                size="small"
                                state={{ project: { ClientID: '', ClientAlias: '', ClientName: '', ProjectURL: '', ProjectVersion: '', ProjectDomain: '', DomainExpiry: new Date(), SSLClient: '', SSLExpiry: new Date(), WebAPIURL: '', HttpsUploadURL: '', ServerOwnership: '', ServerProvider: '', ZylemDBName: '', AgreementExpiry: new Date(), PurchaseOrderNo: '', PurchaseOrderExpiry: new Date(), PurchaseOrderPeriod: '', ServiceProvider:'',IsActive:'' ,ServerName: '', ServerIP: '', PublicIP: '', ConfigurationDetails: '', ExecutiveID: '' } }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={onResetClick}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                        </Tooltip >

                    </Stack>

                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={projectInformation} disableSelectionOnClick density={'compact'} columns={TABLE_HEAD} pageSize={pageSize} onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20,30]} getRowId={(row) => projectInformation.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>

            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}