import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import ServiceEntry from "../../../components/SidebarMenuComponents/ServiceComponent/ServicesComponents/ServiceEntry";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function ServicesPage() {
    const location = useLocation();

    return (
        <Page title="  Quick Service Entry | tiketic" >
            {/* <Typography variant="h6" color="grey" marginLeft={3} > */}
            {/*    Quick Service Entry */}
            {/* </Typography> */}

            <ContentStyle>
                <ServiceEntry />
            </ContentStyle>
        </Page>
    );
}

