import { faker } from '@faker-js/faker';
// @mui
import { useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import { PowerBIEmbed } from 'powerbi-client-react';
import {models}  from 'powerbi-client';
// import { styled } from '@mui/material/styles';
// import Page from '../components/Page';
// import Iconify from '../components/Iconify';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import AppCache from '../services/AppCache';


import '../services/PowerBiEmbedStyleSheet.css';
import URLS from '../services/urls.service';
// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
} from '../sections/@dashboard/app';
// ----------------------------------------------------------------------


//   const dashboardArray = [
//    {
//        dashboardId: 1,
//        DashboardName: 'RLS',

//    },
//    {
//        dashboardId: 2,
//        DashboardName: 'RLS 1',

//    },
//    {
//        dashboardId: 3,
//        DashboardName: 'RLS 3',

//    },

//  ]



const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #4e342e',

    position: 'sticky',

    '& .MuiTabs-indicator': {

    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontsize: '20px',
    color: '#b71c1c',
    Transition: '2s',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#4e342e',

        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4e342e',

        fontWeight: theme.typography.fontWeightMedium,

    },
    '&.Mui-focusVisible': {
        backgroundColor: '#fff',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function DashboardApp() {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [dashboardTabsArray, setdashboardTabsArray] = useState([]);
    const handleChange = (event, newValue) => {

        console.log("response  event.target  ", event.target);
        console.log("response  event.target.value ", event.target.value);
        console.log("response  newValue  ", newValue);
        setValue(newValue);

    };




    const handleHide = () => {
        // setHide(true)
    };

    const handleShow = () => {
        // setHide(false)
    };
    const file = '/static/Dashboard.pdf'
    const type = 'pdf'
    const containerStyle = {
        width: '100%',
        margin: 0,
        overflow: 'hidden',
    };

    const fileViewerStyle = {
        width: '100%',
        height: '600px',
        border: 'none',
        overflow: 'hidden',
        objectFit: 'contain'
    };
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [testData, setTestData] = useState([]);
    const desiredHeight = '600px';
    const [userId, setUserId] = useState([]);
    const [DashboardURL, setDashboardURL] = useState([]);
    const [IspowerBI, setIspowerBI] = useState([]);
    const [embedConfigId, setEmbedConfigId] = useState([]);
    const [embedConfigEmbedUrl, setEmbedConfigEmbedUrl] = useState("");
    const [embedConfigAccessToken, setEmbedConfigAccessToken] = useState("");
    const [embedConfigExpiration, setEmbedConfigExpiration] = useState("");

    const [dashboardData, setDashboardData] = useState([]);

    useEffect(() => {
        // PreviousUrl calls
        URLS.configFileURL().then((response) => {
            if (response.status === 200) {
                const config = JSON.parse(JSON.stringify(response.data));
                // AppCache.SetCurrentAPIURL(config.SmileServiceApiBaseUrl);
                //   AppCache.SetPowerBIURL(config);
            }
            else {
                // Failure response
                alert("Api URL  Failed");
            }
        })

        setUserId(CurrentLoggedInUser.ExecutiveId);
        const LoginId = CurrentLoggedInUser.ExecutiveId;
        const payload = {
            UserID: LoginId,
            TabName: ""
        }
        console.log("payload", payload);
        URLS.getPowerBIDashBoard(payload)
            .then((response) => {
                setDashboardData(response.data);
                //    console.log("response response.data  ", response.data);
                //    console.log("DashboardURL ", response.data.DashboardURL);
                //    console.log("IspowerBI ", response.data.IspowerBI);

                setDashboardURL(response.data.DashboardURL);
                setIspowerBI(response.data.IspowerBI);
                setdashboardTabsArray(response.data.DashboardTabslist || []);

                const arrayControlValue = response.data.DashboardURL;
                const arrayMenu = arrayControlValue.split(',');
                const RLS = arrayMenu[0];
                const userName = arrayMenu[1];

                // console.log("response username use effect", userName);

                // const userName = "=232&ReportId=cee14564-601d-4cd7-ab92-d7b1f394c975&DatasetId=603199d7-276a-48d2-a8cb-420ae6f44121";

                if (RLS === 'NO') {
                    URLS.getPowerBINoRlsData(userName)
                        .then((response) => {
                            setEmbedConfigId(response.data.Id);
                            setEmbedConfigEmbedUrl(response.data.EmbedUrl);
                            setEmbedConfigAccessToken(response.data.EmbedToken.Token);
                            setEmbedConfigExpiration(response.data.EmbedToken.Expiration);
                            setTestData(response.data);

                            // console.log(" testData.setEmbedConfigId, RLS = No ", testData.Id);
                            setEmbedConfigId(testData.Id);
                            //  console.log(" testData.EmbedToken.Token , RLS = No ", testData.EmbedToken.Token);

                            //  setEmbedConfigAccessToken(testData.EmbedToken.Token);



                        })
                        .catch(error => {
                            // On error
                            console.log(" Error URLS.getPowerBIData(userName), RLS = NO ", error);
                        });

                    //  displayNORLSDashboard(userName);
                } else if (RLS === 'YES') {
                    URLS.getPowerBIData(userName)
                        .then((response) => {
                            setEmbedConfigId(response.data.Id);
                            setEmbedConfigEmbedUrl(response.data.EmbedUrl);
                            setEmbedConfigAccessToken(response.data.EmbedToken.Token);
                            setEmbedConfigExpiration(response.data.EmbedToken.Expiration);

                            setTestData(response.data);

                            // setEmbedConfigId(testData.Id);
                            //  console.log(" testData.EmbedToken.Token , RLS = YES ", testData.EmbedToken.Token);

                            // setEmbedConfigAccessToken(testData.EmbedToken.Token);

                        })
                        .catch(error => {
                            // On error
                            console.log(" Error URLS.getPowerBIData(userName), RLS = YES ", error);
                        });
                }
            })
            .catch(error => {
                console.log(" Error ");
            });

    }, []);


    const tabHandleChange = (event, newValue, dashboardName) => {
        //   console.log("response  event.target  ", event.target);
        //  console.log("response  event.target.value ", event.target.value);
        console.log("response  dashboardName ", dashboardName);
        console.log("response  newValue  ", newValue);


        setValue(newValue);

        setUserId(CurrentLoggedInUser.ExecutiveId);
        const LoginId = CurrentLoggedInUser.ExecutiveId;
        const TabNameValue = dashboardName;
        const payload = {
            UserID: LoginId,
            TabName: TabNameValue
        }
        console.log("payload", payload);
        URLS.getPowerBIDashBoard(payload)
            .then((response) => {
                console.log("response ", response);
                setDashboardData(response.data);
                setDashboardURL(response.data.DashboardURL);
                setIspowerBI(response.data.IspowerBI);
              /*  setdashboardTabsArray(response.data.DashboardTabslist || []); */

                const arrayControlValue = response.data.DashboardURL;
                const arrayMenu = arrayControlValue.split(',');
                const RLS = arrayMenu[0];
                const userName = arrayMenu[1];

                console.log("response username", userName);
                // const userName = "=232&ReportId=cee14564-601d-4cd7-ab92-d7b1f394c975&DatasetId=603199d7-276a-48d2-a8cb-420ae6f44121";
                if (RLS === 'NO') {

                    URLS.getPowerBINoRlsData(userName)
                        .then((response) => {
                            console.log("response No RLS ", response);
                            setEmbedConfigId(response.data.Id);
                            setEmbedConfigEmbedUrl(response.data.EmbedUrl);
                            setEmbedConfigAccessToken(response.data.EmbedToken.Token);
                            setEmbedConfigExpiration(response.data.EmbedToken.Expiration);
                            setTestData(response.data);

                            console.log(" response.data.EmbedToken.Expiration, RLS = No ", response.data.EmbedToken.Expiration);
                            console.log(" response.data.Id , RLS = No ", response.data.Id);
                            console.log(" response.data.EmbedToken.Token , RLS = No ", response.data.EmbedToken.Token);
                            console.log(" testData.embedConfigAccessToken, RLS = No ", testData.EmbedToken.Token);
                            console.log(" testData.setEmbedConfigId, RLS = No ", testData.Id);
                            //   setEmbedConfigId(testData.Id);
                            //  setEmbedConfigAccessToken(testData.EmbedToken.Token);

                            // setTestData(response.data);
                        })
                        .catch(error => {
                            // On error
                            console.log(" Error URLS.getPowerBIData(userName), RLS = NO ", error);
                        });
                }
                else if (RLS === 'YES') {
                    URLS.getPowerBIData(userName)
                        .then((response) => {
                            console.log("response Yes RLS ", response);

                            setEmbedConfigId(response.data.Id);
                            setEmbedConfigEmbedUrl(response.data.EmbedUrl);
                            setEmbedConfigAccessToken(response.data.EmbedToken.Token);
                            setEmbedConfigExpiration(response.data.EmbedToken.Expiration);
                            setTestData(response.data);

                            //  setEmbedConfigId(testData.Id);
                            //  setEmbedConfigAccessToken(testData.EmbedToken.Token);

                            console.log(" response.data.EmbedToken.Expiration, RLS = YES ", response.data.EmbedToken.Expiration);
                            console.log(" testData.embedConfigAccessToken, RLS = YES ", testData.EmbedToken.Token);

                        })
                        .catch(error => {
                            // On error
                            console.log(" Error URLS.getPowerBIData(userName), RLS = YES ", error);
                        });
                }
            })
            .catch(error => {
                console.log(" Error ");
            });
    }
    console.log("dashboardarray>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", dashboardTabsArray)

    return (
        <Page title="Dashboard"  >

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {/*    <AntTabs value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example">    */}
                <AntTabs value={value} variant="fullWidth" aria-label="basic tabs example">
                    {(dashboardTabsArray || []).map((dashboard, index) => (
                        // <AntTab key={dashboard.dashboardId} onClick={(event) => handleChange(event, index)} label={dashboard.DashboardName} />
                        <AntTab key={dashboard.dashboardId} onClick={(event) => tabHandleChange(event, index, dashboard.DashboardName)} label={dashboard.DashboardName} />
                    ))}
                </AntTabs>
            </Box>


            {(dashboardTabsArray || []) .map((dashboard, index) => ( 
                <TabPanel key={dashboard.dashboardId} onChange={handleChange} value={value} index={index} > 
                    <div> 
                        <PowerBIEmbed 
                            embedConfig={{ 
                                type: 'report',   // Supported types: report, dashboard, tile, visual and qna 
                                //  id: testData.embedConfigId, 
                                // id: "cee14564-601d-4cd7-ab92-d7b1f394c975", 
                                id: embedConfigId, 

                                //  accessToken: testData.EmbedToken.Token, 



                                accessToken: embedConfigAccessToken, 
                                // accessToken: embedConfigAccessToken, 
                                // tokenType: models.TokenType.Aad, 
                                // embedUrl: embedConfigEmbedUrl, 

                                // id: embedConfigId, 
                                embedUrl: testData.EmbedUrl, 
                                // accessToken: embedConfigAccessToken, 
                                // tokenType: models.TokenType.Aad, 
                                tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed 
                                // tokenExpiry = embedConfigExpiration, 


                                settings: { 
                                    panes: { 
                                        filters: { 
                                            expanded: true, 
                                            visible: true 
                                        } 
                                    }, 
                                } 
                            }} 

                            eventHandlers={ 
                                new Map([ 
                                    ['loaded', function () { console.log('Report loaded'); }], 
                                    ['rendered', function () { console.log('Report rendered'); }], 
                                    ['error', function (event) { console.log('PowerBi Error rendered'); console.log(event.detail); }] 
                                ]) 
                            } 

                            //  cssClassName="Embed-container" 
                            cssClassName="Embed-container" 


                            getEmbeddedComponent={(embeddedReport) => { 
                                window.report = embeddedReport; 
                            } 
                            } 
                        /> 
                    </div> 
                </TabPanel> 




             ))} 





            {/*   <Grid container spacing={2}> */}
            {/*    <Grid item xs={12} sm={6} md={3}> */}
            {/*      <AppWidgetSummary title="Weekly Sales" total={714000} icon={'ant-design:android-filled'} /> */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} sm={6} md={3}> */}
            {/*      <AppWidgetSummary title="New Users" total={1352831} color="info" icon={'ant-design:apple-filled'} />  */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} sm={6} md={3}> */}
            {/*      <AppWidgetSummary title="Item Orders" total={1723315} color="warning" icon={'ant-design:windows-filled'} /> */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} sm={6} md={3}> */}
            {/*      <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} /> */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} md={6} lg={8}> */}
            {/*      <AppWebsiteVisits */}
            {/*        title="Website Visits" */}
            {/*        subheader="(+43%) than last year" */}
            {/*        chartLabels={[ */}
            {/*          '01/01/2003', */}
            {/*          '02/01/2003', */}
            {/*          '03/01/2003', */}
            {/*          '04/01/2003', */}
            {/*          '05/01/2003', */}
            {/*          '06/01/2003', */}
            {/*          '07/01/2003', */}
            {/*          '08/01/2003', */}
            {/*          '09/01/2003', */}
            {/*          '10/01/2003', */}
            {/*          '11/01/2003', */}
            {/*        ]} */}
            {/*        chartData= {[  */}
            {/*          { */}
            {/*            name: 'Team A', */}
            {/*            type: 'column', */}
            {/*            fill: 'solid', */}
            {/*            data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30], */}
            {/*          }, */}
            {/*          { */}
            {/*            name: 'Team B', */}
            {/*            type: 'area', */}
            {/*            fill: 'gradient', */}
            {/*            data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43], */}
            {/*          }, */}
            {/*          { */}
            {/*            name: 'Team C', */}
            {/*            type: 'line', */}
            {/*            fill: 'solid', */}
            {/*            data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39], */}
            {/*          }, */}
            {/*        ]} */}
            {/*      /> */}
            {/*    </Grid> */}

            {/*            <Grid item xs={12} md={6} lg={4} style={ containerStyle}> */}
            {/*                <div style={fileViewerStyle}> */}
            {/*                    <FileViewer fileType={type} filePath={file} /> */}
            {/*                </div> */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} md={6} lg={8}> */}
            {/*      <AppConversionRates */}
            {/*        title="Conversion Rates" */}
            {/*        subheader="(+43%) than last year" */}
            {/*        chartData={[ */}
            {/*          { label: 'Italy', value: 400 }, */}
            {/*          { label: 'Japan', value: 430 }, */}
            {/*          { label: 'China', value: 448 }, */}
            {/*          { label: 'Canada', value: 470 }, */}
            {/*          { label: 'France', value: 540 }, */}
            {/*          { label: 'Germany', value: 580 }, */}
            {/*          { label: 'South Korea', value: 690 }, */}
            {/*          { label: 'Netherlands', value: 1100 }, */}
            {/*          { label: 'United States', value: 1200 }, */}
            {/*          { label: 'United Kingdom', value: 1380 }, */}
            {/*        ]} */}
            {/*      /> */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} md={6} lg={4}> */}
            {/*      <AppCurrentSubject */}
            {/*        title="Current Subject" */}
            {/*        chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']} */}
            {/*        chartData={[ */}
            {/*          { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] }, */}
            {/*          { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] }, */}
            {/*          { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] }, */}
            {/*        ]} */}
            {/*        chartColors={[...Array(6)].map(() => theme.palette.text.secondary)} */}
            {/*      /> */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} md={6} lg={8}> */}
            {/*      <AppNewsUpdate */}
            {/*        title="News Update" */}
            {/*        list={[...Array(5)].map((_, index) => ({ */}
            {/*          id: faker.datatype.uuid(), */}
            {/*          title: faker.name.jobTitle(), */}
            {/*          description: faker.name.jobTitle(), */}
            {/*          image: `/static/mock-images/covers/cover_${index + 1}.jpg`, */}
            {/*          postedAt: faker.date.recent(), */}
            {/*        }))} */}
            {/*      /> */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} md={6} lg={4}> */}
            {/*      <AppOrderTimeline */}
            {/*        title="Order Timeline" */}
            {/*        list={[...Array(5)].map((_, index) => ({ */}
            {/*          id: faker.datatype.uuid(), */}
            {/*          title: [ */}
            {/*            '1983, orders, $4220', */}
            {/*            '12 Invoices have been paid', */}
            {/*            'Order #37745 from September', */}
            {/*            'New order placed #XF-2356', */}
            {/*            'New order placed #XF-2346', */}
            {/*          ][index], */}
            {/*          type: `order${index + 1}`, */}
            {/*          time: faker.date.past(), */}
            {/*        }))} */}
            {/*      /> */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} md={6} lg={4}> */}
            {/*      <AppTrafficBySite */}
            {/*        title="Traffic by Site" */}
            {/*        list={[ */}
            {/*          { */}
            {/*            name: 'FaceBook', */}
            {/*            value: 323234, */}
            {/*            icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />, */}
            {/*          }, */}
            {/*          { */}
            {/*            name: 'Google', */}
            {/*            value: 341212, */}
            {/*            icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />, */}
            {/*          }, */}
            {/*          { */}
            {/*            name: 'Linkedin', */}
            {/*            value: 411213, */}
            {/*            icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />, */}
            {/*          }, */}
            {/*          { */}
            {/*            name: 'Twitter', */}
            {/*            value: 443232, */}
            {/*            icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />, */}
            {/*          }, */}
            {/*        ]} */}
            {/*      /> */}
            {/*    </Grid> */}

            {/*    <Grid item xs={12} md={6} lg={8}> */}
            {/*      <AppTasks */}
            {/*        title="Tasks" */}
            {/*        list={[ */}
            {/*          { id: '1', label: 'Create FireStone Logo' }, */}
            {/*          { id: '2', label: 'Add SCSS and JS files if required' }, */}
            {/*          { id: '3', label: 'Stakeholder Meeting' }, */}
            {/*          { id: '4', label: 'Scoping & Estimations' }, */}
            {/*          { id: '5', label: 'Sprint Showcase' }, */}
            {/*        ]} */}
            {/*      /> */}
            {/*    </Grid> */}
            {/*   </Grid> */}
        </Page>
    );
}
