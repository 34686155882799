import { useLocation, Navigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// material
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Stack, Container, Card, Box, FormControl, InputLabel, Select, Button, MenuItem, Typography, TextField, Input, CardMedia , Tabs, Tab } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
import Page from '../../components/Page';
import URLS from '../../services/urls.service';
import Scrollbar from '../../components/Scrollbar';

// ----------------------------------------------------------------------------------------------

const imageStyle = {
    aspectRatio: 16 / 9,
    height: 100,
    objectFit: "contain",
};
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',

    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #4e342e',


    position: 'sticky',


    '& .MuiTabs-indicator': {

    },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontsize: '20px',
    color: '#b71c1c',
    Transition: '2s',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#4e342e',

        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4e342e',

        fontWeight: theme.typography.fontWeightMedium,

    },
    '&.Mui-focusVisible': {
        backgroundColor: '#fff',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ClientLocationDropDown = [
    { Id: 0, value: 'false' },
    { Id: 1, value: 'true' }
];


const bloodGroupArray = [
    { Id: 'O +ve', value: 'O +ve' },
    { Id: 'O -ve', value: 'O -ve' },
    { Id: 'A +ve', value: 'A +ve' },
    { Id: 'A -ve', value: 'A -ve' },
    { Id: 'B +ve', value: 'B +ve' },
    { Id: 'B -ve', value: 'B -ve' },
    { Id: 'AB +ve', value: 'AB +ve' },
    { Id: 'AB -ve', value: 'AB -ve' }
];

export default function ProfileDetails() {

    const location = useLocation();
    const { prfile } = location.state;
    console.log("peeepppp", prfile.IsAtClient);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    };

    const [report, setReport] = useState([])
    const [office, setOffice] = useState([])
    const [department, setDepartment] = useState([])
    const [designation, setDesignation] = useState([])
    const [client, setClient] = useState([])
    const [gender, setGender] = useState([])
    const [maritalStatus, setMaritalStatus] = useState([])
    const [hide, setHide] = useState(false);



    useEffect(() => {
        URLS.getReportingToDropDown()
            .then((response) => {

                setReport(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

        URLS.getOfficeDropDown()
            .then((response) => {

                setOffice(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getDepartmentDropDown()
            .then((response) => {

                setDepartment(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

        URLS.getDesignationDropDown()
            .then((response) => {

                setDesignation(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getClientsPopOverDropDown()
            .then((response) => {

                setClient(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getGenderDropDown()
            .then((response) => {

                setGender(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getMaritalStatusDropDown()
            .then((response) => {

                setMaritalStatus(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    }, []);



    // Reporting To DropDown
    const [reportId, setReportId] = useState(prfile.ReportingTo);
    const handleSelectedReporting = (id) => {

        setReportId(id);

    };
    // Office DropDown
    const [officeValue, setOfficeValue] = useState(prfile.OfficeLocationId);
    const handleSelectedOffice = (id) => {

        setOfficeValue(id);
    };
    // Department DropDown
    const [DeptValue, setDeptValue] = useState(prfile.Department);
    const handleSelectedDepartment = (id) => {

        setDeptValue(id);

    };
    // Designation DropDown
    const [desiValue, setDesiValue] = useState(prfile.Designation);
    const handleSelectedDesignation = (id) => {

        setDesiValue(id);
    };
    // Client DropDown
    const [clientValue, setClientValue] = useState(prfile.CurrentClientId);
    const handleSelectedClient = (id) => {
        setClientValue(id);
    };

    // At Client Location Dropdown
    const [clientLocationId, setClientLocationId] = useState(prfile.IsAtClient);
    const handleSelectedClientLocation = (value) => {
        setClientLocationId(value);

    };

    // Gender Dropdown
    const [genderValue, setGenderValue] = useState(prfile.SexId);
    const handleSelectedGender = (id) => {
        setGenderValue(id);
    };

    // Blood group Dropdown
    const [bloodValue, setBloodValue] = useState(prfile.BloodGroup);
    const handleSelectedBloodGroup = (value) => {
        setBloodValue(value);
    };


    // Marital status Dropdown
    const [maritalValue, setMaritalValue] = useState(prfile.MaritalStatusId);
    const handleSelectedMaritalStatus = (id) => {
        setMaritalValue(id);
    };

    // Dates 
    //  DOB
    const [dobValue, setDobValue] = useState(prfile.DateOfBirth);
    const handleDob = (newValue) => {
        setDobValue(newValue)
    };

    // Official DOB
    const [officialdobValue, setOfficialDobValue] = useState(prfile.OfficialDOB);
    const handleOfficialDob = (newValue) => {
        setOfficialDobValue(newValue)
    };

    // Date of Joining
    const [joiningValue, setJoiningValue] = useState(prfile.DateOfJoining);
    const handleJoiningDob = (newValue) => {
        setJoiningValue(newValue)
    };

    // Confirmation  Date 
    const [confirmationValue, setConfirmationValue] = useState(prfile.DateOfConfirmation);
    const handleConfirmationDate = (newValue) => {
        setConfirmationValue(newValue)
    };

    // Wedding Date  Date 
    const [weddingValue, setWeddingValue] = useState(prfile.DateOfWedding);
    const handleWeddingDate = (newValue) => {
        setWeddingValue(newValue)
    };


    // Hide buttons
    const handleHide = () => {
        setHide(true)
    };

    const handleShow = () => {
        setHide(false)
    };

    const passSchema = Yup.object().shape({
        NewPassword: Yup.string()
            .required('New Password is required')
            .min(8, 'Password must be at least 8 characters long'),
        ConfirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('NewPassword')], 'Passwords must match'),
    });

    const formik = useFormik({
        initialValues: {
            FirstName: prfile.FirstName,
            LastName: prfile.LastName,
            ExecutiveEmailId: prfile.ExecutiveEmailId,
            PhoneNo: prfile.PhoneNo,

            ReportingTo: prfile.ReportingTo,
            OfficeLocationId: prfile.OfficeLocationId,
            ERPCode: prfile.ERPCode,
            Department: prfile.Department,

            DateOfBirth: prfile.DateOfBirth,
            DateOfJoining: prfile.DateOfJoining,
            DateOfConfirmation: prfile.DateOfConfirmation,
            Designation: prfile.Designation,

            CurrentClientId: prfile.CurrentClientId,
            IsAtClient: prfile.IsAtClient,
            OfficeDeskNo: prfile.OfficeDeskNo,
            LoginId: prfile.LoginId,

            Address: prfile.Address,
            EmailId: prfile.EmailId,
            EmergencyContactNo: prfile.EmergencyContactNo,


            Aadhar: prfile.Aadhar,
            UAN: prfile.UAN,
            PAN: prfile.PAN,
            BankName: prfile.BankName,
            Branch: prfile.Branch,
            AccountNo: prfile.AccountNo,
            IFSC: prfile.IFSC,
            BankAccountName: prfile.BankAccountName,
            ESIC: prfile.ESIC,
            OfficialDOB: prfile.OfficialDOB,
            BloodGroup: prfile.BloodGroup,
            SexId: prfile.SexId,
            MaritalStatusId: prfile.MaritalStatusId,
            DateOfWedding: prfile.DateOfWedding,
            PermAddress: prfile.PermAddress,
            NewPassword: prfile.NewPassword,
            ConfirmPassword: prfile.ConfirmPassword,
            UploadPhoto: prfile.UploadPhoto,
            ExecutiveId: 233

        },
        validationSchema: passSchema,
        onSubmit: (values, actions) => {
            console.log("hi")
        }
    })

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewUrl('');
        }
    };

    const handleUpload = () => {
        // Perform any desired logic here, such as displaying a success message
        console.log('Upload success');
    };


    return (


        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >


                <Scrollbar>
                    <Typography variant="h5" color="#b71c1c" marginLeft={2} marginTop={1}>
                        Profile Details
                    </Typography>
                    <Card>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <AntTabs value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example">
                                    <AntTab onClick={handleShow} label="Official Details" {...a11yProps(0)} />
                                    <AntTab onClick={handleShow} label="Personal Details" {...a11yProps(1)} />
                                    <AntTab onClick={ handleHide} label="Other Details" {...a11yProps(2)} />
                                </AntTabs >
                            </Box>
                            <Scrollbar>
                                <Card >
                                    <TabPanel value={value} index={0}>


                                        <Stack justifyContent="center" spacing={2}>

                                            <Stack direction="row" spacing={2}>
                                                <TextField size="small" id="outlined-basic" label="First Name" variant="outlined" fullWidth  {...getFieldProps('FirstName')}
                                                    error={Boolean(touched.FirstName && errors.FirstName)}
                                                    helperText={touched.FirstName && errors.FirstName} />
                                                <TextField size="small" id="outlined-basic" label="Last Name" variant="outlined" fullWidth {...getFieldProps('LastName')}
                                                    error={Boolean(touched.LastName && errors.LastName)}
                                                    helperText={touched.LastName && errors.LastName} />
                                                <TextField
                                                    size="small"
                                                    label="Email"
                                                    fullWidth
                                                    variant="outlined" {...getFieldProps('ExecutiveEmailId')}
                                                />
                                            </Stack>




                                            <Stack direction="row" spacing={2}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                                        Reporting To
                                                    </InputLabel>
                                                    <Select
                                                        label="Reporting To"
                                                        value={reportId}
                                                        MenuProps={{
                                                            style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                                                        }}
                                                    >
                                                        <MenuItem key={0} value={""}>
                                                            Select
                                                        </MenuItem>
                                                        {report.map((repo) => (
                                                            <MenuItem
                                                                key={repo.Id}
                                                                value={repo.Name}
                                                                onClick={() => handleSelectedReporting(repo.Id)}
                                                            >
                                                                {repo.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>



                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Office</InputLabel>
                                                    <Select
                                                        label="Office"
                                                        value={officeValue}
                                                        MenuProps={{
                                                            style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                                                        }}
                                                    >
                                                        <MenuItem key={0} value={""} >Select</MenuItem>
                                                        {office.map((ofc) => (
                                                            <MenuItem key={ofc.Id} value={ofc.Id} onClick={() => handleSelectedOffice(ofc.Id, ofc.Name)}> {ofc.Name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                <TextField size="small" id="outlined-basic" label="ERP Code" variant="outlined" fullWidth {...getFieldProps('ERPCode')}
                                                    error={Boolean(touched.ERPCode && errors.ERPCode)}
                                                    helperText={touched.ERPCode && errors.ERPCode} />
                                            </Stack>



                                            <Stack direction="row" spacing={2}>
                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Department</InputLabel>
                                                    <Select
                                                        label="Department"
                                                        value={DeptValue}
                                                        MenuProps={{
                                                            style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                                                        }}
                                                    >
                                                        <MenuItem key={0} value={""} >Select</MenuItem>
                                                        {department.map((dept) => (
                                                            <MenuItem key={dept.Id} value={dept.TeamName} onClick={() => handleSelectedDepartment(dept.Id)}> {dept.TeamName}</MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        label="Official DOB"
                                                        inputFormat="dd/MMM/yyyy"
                                                        value={Moment(officialdobValue).format('DD-MMM-yyyy')}
                                                        onChange={handleOfficialDob}
                                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                <TextField size="small" id="outlined-basic" label="Address" variant="outlined" fullWidth  {...getFieldProps('Address')}
                                                    error={Boolean(touched.Address && errors.Address)}
                                                    helperText={touched.Address && errors.Address} />

                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        label="Joining Date"
                                                        inputFormat="dd/MMM/yyyy"
                                                        value={Moment(joiningValue).format('DD-MMM-yyyy')}
                                                        onChange={handleJoiningDob}
                                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                                    />
                                                </LocalizationProvider>

                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        label="Confirmation Date"
                                                        inputFormat="dd/MMM/yyyy"
                                                        value={Moment(confirmationValue).format('DD-MMM-yyyy')}
                                                        onChange={handleConfirmationDate}
                                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                                    />
                                                </LocalizationProvider>

                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Designation</InputLabel>
                                                    <Select
                                                        label="Designation"
                                                        value={desiValue}
                                                        MenuProps={{
                                                            style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                                                        }}
                                                    >
                                                        <MenuItem key={0} value={""} >Select</MenuItem>

                                                        {designation.map((desi) => (

                                                            <MenuItem key={desi.Id} value={desi.Id} onClick={() => handleSelectedDesignation(desi.Id, desi.Name)}> {desi.Name}</MenuItem>
                                                        ))}


                                                    </Select>

                                                </FormControl>

                                            </Stack>

                                            <Stack direction="row" spacing={2}>
                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Client</InputLabel>
                                                    <Select
                                                        label="Client"
                                                        value={clientValue}
                                                        MenuProps={{
                                                            style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                                                        }}
                                                    >
                                                        <MenuItem key={0} value={""} >Select</MenuItem>

                                                        {client.map((ofc) => (

                                                            <MenuItem sx={{ width: '20px' }} key={ofc.Id} value={ofc.Id} onClick={() => handleSelectedClient(ofc.Id, ofc.Name)}> {ofc.Name}</MenuItem>
                                                        ))}


                                                    </Select>

                                                </FormControl>
                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >At Client Location</InputLabel>
                                                    <Select
                                                        label="At Client Location"
                                                        value={clientLocationId}
                                                        MenuProps={{
                                                            style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                                                        }}
                                                    >
                                                        <MenuItem key={0} value={""} >Select</MenuItem>

                                                        {ClientLocationDropDown.map((ofc) => (

                                                            <MenuItem key={ofc.Id} value={ofc.value} onClick={() => handleSelectedClientLocation(ofc.Id, ofc.value)}> {ofc.value}</MenuItem>
                                                        ))}


                                                    </Select>

                                                </FormControl>
                                                <TextField size="small" id="outlined-basic" label="Office Desk Number" fullWidth variant="outlined"  {...getFieldProps('OfficeDeskNo')}
                                                    error={Boolean(touched.OfficeDeskNo && errors.OfficeDeskNo)}
                                                    helperText={touched.OfficeDeskNo && errors.OfficeDeskNo} />
                                            </Stack>
                                            <Stack direction="row" spacing={2}>

                                                <TextField size="small" id="outlined-basic" label="Login Id" variant="outlined" fullWidth  {...getFieldProps('LoginId')}
                                                    error={Boolean(touched.LoginId && errors.LoginId)}
                                                    helperText={touched.LoginId && errors.LoginId} />
                                                <TextField
                                                    size="small"
                                                    label="Phone Number"
                                                    fullWidth
                                                    variant="outlined" {...getFieldProps('PhoneNo')}
                                                />
                                            </Stack>
                                        </Stack>
                                    </TabPanel>

                                    <TabPanel value={value} index={1}>
                                        <Stack justifyContent="center" spacing={2}>
                                            <Stack direction="row" spacing={2}>
                                                <TextField size="small" id="outlined-basic" label="Email Personal" variant="outlined" fullWidth {...getFieldProps('EmailId')}
                                                    error={Boolean(touched.EmailId && errors.EmailId)}
                                                    helperText={touched.EmailId && errors.EmailId} />
                                                <TextField size="small" id="outlined-basic" label="Emergency Contact Number" variant="outlined" fullWidth  {...getFieldProps('EmergencyContactNo')}
                                                    error={Boolean(touched.EmergencyContactNo && errors.EmergencyContactNo)}
                                                    helperText={touched.EmergencyContactNo && errors.EmergencyContactNo} />
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        label="Birth Date"
                                                        inputFormat="dd/MMM/yyyy"
                                                        value={Moment(dobValue).format('DD-MMM-yyyy')}
                                                        onChange={handleDob}
                                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />} />
                                                </LocalizationProvider>
                                            </Stack>




                                            <Stack direction="row" spacing={2}>

                                                <TextField size="small" id="outlined-basic" label="UAN Number" fullWidth variant="outlined" {...getFieldProps('UAN')}
                                                    error={Boolean(touched.UAN && errors.UAN)}
                                                    helperText={touched.UAN && errors.UAN} />
                                                <TextField size="small" id="outlined-basic" label="Adhaar Number" variant="outlined" fullWidth  {...getFieldProps('Aadhar')}
                                                    error={Boolean(touched.Aadhar && errors.Aadhar)}
                                                    helperText={touched.Aadhar && errors.Aadhar} />
                                                <TextField size="small" id="outlined-basic" label="Pan Number" variant="outlined" fullWidth {...getFieldProps('PAN')}
                                                    error={Boolean(touched.PAN && errors.PAN)}
                                                    helperText={touched.PAN && errors.PAN} />
                                            </Stack>

                                            <Stack direction="row" spacing={2}>

                                                <TextField size="small" id="outlined-basic" label="Bank Name" fullWidth variant="outlined" {...getFieldProps('BankName')}
                                                    error={Boolean(touched.BankName && errors.BankName)}
                                                    helperText={touched.BankName && errors.BankName} />
                                                <TextField size="small" id="outlined-basic" label="Branch" variant="outlined" fullWidth  {...getFieldProps('Branch')}
                                                    error={Boolean(touched.Branch && errors.Branch)}
                                                    helperText={touched.Branch && errors.Branch} />
                                                <TextField size="small" id="outlined-basic" label="Account Number" variant="outlined" fullWidth {...getFieldProps('AccountNo')}
                                                    error={Boolean(touched.AccountNo && errors.AccountNo)}
                                                    helperText={touched.AccountNo && errors.AccountNo} />
                                            </Stack>


                                            <Stack direction="row" spacing={2}>

                                                <TextField size="small" id="outlined-basic" label="IFSC Code" fullWidth variant="outlined" {...getFieldProps('IFSC')}
                                                    error={Boolean(touched.IFSC && errors.IFSC)}
                                                    helperText={touched.IFSC && errors.IFSC} />
                                                <TextField size="small" id="outlined-basic" label="Bank Account Name" variant="outlined" fullWidth  {...getFieldProps('BankAccountName')}
                                                    error={Boolean(touched.BankAccountName && errors.BankAccountName)}
                                                    helperText={touched.BankAccountName && errors.BankAccountName} />
                                                <TextField size="small" id="outlined-basic" label="ESIC" variant="outlined" fullWidth {...getFieldProps('ESIC')}
                                                    error={Boolean(touched.ESIC && errors.ESIC)}
                                                    helperText={touched.ESIC && errors.ESIC} />
                                            </Stack>


                                            <Stack direction="row" spacing={2}>
                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Blood Group</InputLabel>
                                                    <Select
                                                        label="Blood Group"
                                                        value={bloodValue}
                                                        MenuProps={{
                                                            style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                                                        }}
                                                    >
                                                        <MenuItem key={0} value={""} >Select</MenuItem>

                                                        {bloodGroupArray.map((blood) => (

                                                            <MenuItem key={blood.Id} value={blood.value} onClick={() => handleSelectedBloodGroup(blood.Id, blood.value)}> {blood.value}</MenuItem>
                                                        ))}


                                                    </Select>

                                                </FormControl>

                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Gender</InputLabel>
                                                    <Select
                                                        label="Gender"
                                                        value={genderValue}
                                                        MenuProps={{
                                                            style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                                                        }}
                                                    >
                                                        <MenuItem key={0} value={""} >Select</MenuItem>

                                                        {gender.map((ofc) => (

                                                            <MenuItem key={ofc.Id} value={ofc.Name} onClick={() => handleSelectedGender(ofc.Id, ofc.Name)}> {ofc.Name}</MenuItem>
                                                        ))}


                                                    </Select>

                                                </FormControl>

                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Marital Status</InputLabel>
                                                    <Select
                                                        label="Marital Status"
                                                        value={maritalValue}
                                                        MenuProps={{
                                                            style: { maxHeight: '200px', width: '200px' } // Adjust the maxHeight and width as desired
                                                        }}
                                                    >
                                                        <MenuItem key={0} value={""} >Select</MenuItem>

                                                        {maritalStatus.map((mari) => (

                                                            <MenuItem key={mari.Id} value={mari.Id} onClick={() => handleSelectedMaritalStatus(mari.Id, mari.Name)}> {mari.Name}</MenuItem>
                                                        ))}


                                                    </Select>

                                                </FormControl>

                                            </Stack>

                                            <Stack direction="row" spacing={2}>

                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        label="Wedding Date"
                                                        inputFormat="dd/MMM/yyyy"
                                                        value={Moment(weddingValue).format('DD-MMM-yyyy')}
                                                        onChange={handleWeddingDate}
                                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                <TextField size="small" id="outlined-basic" label="Permanant Address" variant="outlined" fullWidth  {...getFieldProps('PermAddress')}
                                                    error={Boolean(touched.PermAddress && errors.PermAddress)}
                                                    helperText={touched.PermAddress && errors.PermAddress} />
                                            </Stack>
                                        </Stack>
                                    </TabPanel>

                                    <TabPanel value={value} index={2}>
                                        <Stack direction="column" spacing={2}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography variant="subtitle1">Upload Photo</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack direction="row" spacing={ 8} alignItems="center">
                                                      
                                                        <Button variant="contained" component="label">
                                                            Upload
                                                            <input hidden onChange={handleFileChange} accept="image/*"  type="file" />
                                                        </Button>
                                                        {previewUrl && (
                                                            <Card sx={{ maxWidth: 300, borderRadius: 0, padding:0,margin:0 }}>
                                                                <CardMedia
                                                                    component="img"
                                                                    image={previewUrl}
                                                                    alt="Preview"
                                                                    style={ imageStyle} // Set the aspect ratio here (e.g., '16/9', '4/3', '1/1')
                                                                />
                                                            </Card>
                                                        )}
                                                      
                                                    </Stack>
                                                    {/* <Stack spacing={2} direction="row" mt={1} justifyContent="center"> */}
                                                    {/*    <LoadingButton */}
                                                    {/*        size="medium" */}
                                                    {/*        type="submit" */}
                                                    {/*        variant="contained" */}
                                                    {/*        sx={{ color: 'white' }} */}

                                                    {/*    >  */}
                                                    {/*        Save */}
                                                    {/*    </LoadingButton> */}

                                                    {/*    <Button */}
                                                    {/*        sx={{ color: 'white' }} */}
                                                    {/*        size="medium" */}
                                                    {/*        variant="contained" */}
                                                    {/*        color="primary" */}
                                                    {/*    > */}
                                                    {/*        Cancel */}
                                                    {/*    </Button> */}
                                                    {/* </Stack> */}
                                                </AccordionDetails>
                                            </Accordion>


                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography variant="subtitle1">Change Password</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack direction="row" spacing={2}>
                                                        <TextField size="small" id="outlined-basic" label="New Password" variant="outlined" fullWidth {...getFieldProps('NewPassword')}
                                                            error={Boolean(touched.NewPassword && errors.NewPassword)}
                                                            helperText={touched.NewPassword && errors.NewPassword} />
                                                        <TextField size="small" id="outlined-basic" label="Confirm Password" fullWidth variant="outlined" {...getFieldProps('ConfirmPassword')}
                                                            error={Boolean(touched.ConfirmPassword && errors.ConfirmPassword)}
                                                            helperText={touched.ConfirmPassword && errors.ConfirmPassword} />
                                                    </Stack>
                                                    <Stack spacing={2} direction="row" mt={1} justifyContent="center">
                                                        <LoadingButton
                                                            size="medium"
                                                            type="submit"
                                                            variant="contained"
                                                            sx={{ color: 'white' }}

                                                        >
                                                            Save
                                                        </LoadingButton>

                                                        <Button
                                                            sx={{ color: 'white' }}
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>


                                        </Stack>

                                    </TabPanel>
                                </Card>
                            </Scrollbar>
                        </Box>
                    </Card>
                </Scrollbar>
                {/* {hide ? '' : */}
                {/*    <Stack spacing={2} direction="row" mt={1} justifyContent="center"> */}

                {/*        <LoadingButton */}
                {/*            size="medium" */}
                {/*            type="submit" */}
                {/*            variant="contained" */}
                {/*            sx={{ color: 'white' }} */}

                {/*        > */}
                {/*            Save */}
                {/*        </LoadingButton> */}

                {/*        <Button */}
                {/*            sx={{ color: 'white' }} */}
                {/*            size="medium" */}
                {/*            variant="contained" */}
                {/*            color="primary" */}
                {/*        > */}
                {/*            Cancel */}
                {/*        </Button> */}
                {/*    </Stack>} */}
            </Form>
        </FormikProvider>

    );
}

