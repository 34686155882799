import { useLocation } from "react-router-dom";
// material
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Page from '../../../components/Page';
import ExecutiveExpensesDetails from "../../../components/SidebarMenuComponents/MasterComponent/ExecutiveExpensesDetails";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function ExecutiveExpensesDetailsPage() {
    const location = useLocation();
    const { executiveExpenses } = location.state;

    return (
        <Page title=" Executive Expenses Detailss| tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Executive Expenses Details
            </Typography>

            <ContentStyle>
                <ExecutiveExpensesDetails executiveExpenses={executiveExpenses} />
            </ContentStyle>
        </Page>
    );
}

