import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useNavigate, useLocation, Navigate } from 'react-router-dom';
// material

import { Tooltip, Card, Autocomplete, Divider, Typography, Modal, IconButton, Box, Stack, Tabs, Tab, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import Scrollbar from '../../Scrollbar';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
import ContactMasterDetailsPage from '../../../pages/SidebarMenus/MasterMenu/ContactMasterDetailsPage';

// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #4e342e',
    position: 'sticky',
    '& .MuiTabs-indicator': {

    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontsize: '20px',
    color: '#b71c1c',
    Transition: '2s',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#4e342e',

        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4e342e',

        fontWeight: theme.typography.fontWeightMedium,

    },
    '&.Mui-focusVisible': {
        backgroundColor: '#fff',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function ClientInstallationComponent({ cliInstallation }) {

    const { enqueueSnackbar } = useSnackbar();

    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/master/clientinstallationlocations', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };



    // IsSite
    const [isCheckedActive, setIsCheckedAcive] = useState(cliInstallation.IsSite || true);
    const handleIsActiveChange = (event) => {
        setIsCheckedAcive(event.target.checked);
    };

    // dropdown
    const navigate = useNavigate();
    const [contactvalue, setContactValue] = useState("");
    const handleClick = (event, cellValues) => {
        navigate('/dashboard/clientinstallationdetailspage', { state: { cliInstallation: cellValues.row } })

    }
    const [clientInstallationData, setClientInstallationData] = useState([]);
    useEffect(() => {
        URLS.getClientInstallationLocationData()
            .then((response) => {

                setClientInstallationData(response.data);
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });



    }, []);



    const [contactTypeDropDown, setContactTypeDropDown] = useState([]);
    const [amcDropDown, setAMCDropDown] = useState([]);
    const [classificationDropDown, setClassificationDropDown] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);

    useEffect(() => {
        URLS.getContactTypeIdDropDown()
            .then((response) => {
                setContactTypeDropDown(response.data);

            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });

        URLS.getAMCDropDownData()
            .then((response) => {
                setAMCDropDown(response.data);

            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
        URLS.getClassificationDropDown()
            .then((response) => {
                setClassificationDropDown(response.data);

            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
        URLS.getProjectVersionAutocomplete()
            .then((response) => {

                setSelectedProject(response.data);
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
        URLS.getClientMasterData()
            .then((response) => {

                setSelectedClient(response.data);
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });

    }, []);






    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {

        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);







    // Tabs

    const [value, setValue] = useState(0);

    const handleTabsChange = (event, newValue) => {
        setValue(newValue);

    };

    // Contact Dropdowns

    const [contactsId, setContactsId] = useState(cliInstallation.ContactTypeId);
    const [contactsName, setContactsName] = useState(cliInstallation.ContactTypeId);
    const handelSelectedContacts = (id, name) => {

        setContactsId(id);
        setContactsName(name);

    };
    // Amc Dropdowns
    const [amcId, setAMCId] = useState(cliInstallation.AMCTypeId);
    const [amcName, setAMCName] = useState(cliInstallation.AMCTypeId);
    const handelSelectedAMC = (id, name) => {

        setAMCId(id);
        setAMCName(name);

    };
    // Classification Dropdowns
    const [classificationId, SetClassificationId] = useState(cliInstallation.Classification);
    const [classificationName, SetClassificationName] = useState(cliInstallation.Classification);
    const handelSelectedClassification = (id, name) => {

        SetClassificationId(id);
        SetClassificationName(name);

    };

    // Client
    const [clientId, setClientId] = useState(cliInstallation.ClientId);
    const [clientName, setClientName] = useState(cliInstallation.ClientId);
    const handelSelectedClient = (id, name) => {

        setClientId(id);
        setClientName(name);

    };
    // Project
    const [projectId, setProjectId] = useState(cliInstallation.ProjectVersionId);
    const [projectName, setProjectName] = useState(cliInstallation.ProjectVersionId);
    const handelSelectedProject = (id, name) => {
        setProjectId(id);
        setProjectName(name);

    };

    // Dates 
    const [startDateValue, setStartDateValue] = useState(cliInstallation.StartDate);
    const [endDateValue, setEndDateValue] = useState(cliInstallation.EndDate);

    const [hide, setHide] = useState(false);

    const handleShow = () => {
        setHide(false)
    };

    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    const handleStart = (newValue) => {
        setStartDateValue(newValue)
    };
    const handleEnd = (newValue) => {
        setEndDateValue(newValue)
    };
    const [contactData, setContactData] = useState();
    console.log("contactDataClientInstallation", contactData);
    const ClientInstallationSchema = Yup.object().shape({
        Name: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Name is required'),
        ERPCode: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('ERP Code is required'),
        Remarks: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Remark is required'),
        NoOfLicences: Yup.string().max(100, 'Too Long!').required('Number Of Licences is required'),
          WebSite: Yup.string()
            .url('Invalid website URL')
            .required('Website URL is required'),

        // Yup.string().email('Invalid email').required('Email is required'),
        Address: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Address is required'),
        Country: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Country is required'),
        State: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('State is required'),
        City: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('City is required'),
        District: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('District is required'),
        PinCode: Yup.string().min(2, 'Too Short!').max(6, 'Too Long!').required('PinCode is required'),
        Region: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Region is required'),
        FirstContactBy: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('First Contact By is required'),
        // ContactPersonName: Yup.string()
        //    .min(2, 'Too Short!')
        //    .max(100, 'Too Long!')
        //    .required('Contact name is required'),
        // Designation: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Designation is required'),
        // ContactNo: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Contact Number is required'),
        // Mobile: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Mobile is required'),

        // Remarks: Yup
        //   .string()
        //  .matches(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, 'Special symbols are not allowed.'),

    });

    const formik = useFormik({
        initialValues: {
            SiteId: cliInstallation.SiteId,
            ContactId: cliInstallation.ContactId,
            Name: cliInstallation.Name,
            ERPCode: cliInstallation.ERPCode,

            ContactTypeId: contactsId,
            Remarks: cliInstallation.Remarks,
            IsSite: isCheckedActive,
            ClientId: clientId,

            ProjectVersionId: projectId,
            StartDate: startDateValue,
            EndDate: endDateValue,
            AMCTypeId: amcId,

            NoOfLicences: cliInstallation.NoOfLicences,
            WebSite: cliInstallation.WebSite,
            Address: cliInstallation.Address,
            Country: cliInstallation.Country,

            State: cliInstallation.State,
            City: cliInstallation.City,
            District: cliInstallation.District,
            PinCode: cliInstallation.PinCode,

            Region: cliInstallation.Region,
            Classification: classificationId,
            FirstContactBy: cliInstallation.FirstContactBy,

            ExecutiveID: userId,

        },
        validationSchema: ClientInstallationSchema,
        onSubmit: (values, actions) => {
            if (cliInstallation.ContactId > 0) {
                const payload = {
                    "SiteId": values.SiteId,
                    "ContactId": values.ContactId,
                    "Name": values.Name,
                    "ERPCode": values.ERPCode,
                    "ContactTypeId": contactsId,
                    "Remarks": values.Remarks,
                    "IsSite": isCheckedActive,
                    "ClientId": clientId,
                    "ProjectVersionId": projectId,
                    "StartDate": startDateValue,
                    "EndDate": endDateValue,
                    "AMCTypeId": amcId,
                    "NoOfLicences": values.NoOfLicences,
                    "WebSite": values.WebSite,
                    "Address": values.Address,
                    "Country": values.Country,
                    "State": values.State,
                    "City": values.City,
                    "District": values.District,
                    "PinCode": values.PinCode,
                    "Region": values.Region,
                    "Classification": classificationId,
                    "FirstContactBy": values.FirstContactBy,

                };


                URLS.editClientInstallationLocations(payload)
                    .then((response) => {
                        if (response.status === 200) {
                            AppCache.SetClientInstallationLocations(payload);
                            console.log("payload", payload);
                            window.location.reload();
                            // navigate('/dashboard/master/clientinstallationlocations', { replace: true });
                        }
                        else {
                            // Failure response
                            // alert("Error");
                            console.log("success");
                            console.log("error");

                        }
                    })
                const Contactpayload = { ContactID: cliInstallation.ContactId }

                URLS.getContactMasterData(Contactpayload)
                    .then((response) => {
                        if (response.status === 200) {
                            setContactData(response.data);



                            navigate('/dashboard/contactmasterdetailspage', {
                                replace: true,
                                state: {
                                    cliInstallation: {
                                        SiteId: values.SiteId, ContactId: values.ContactId,
                                        Name: values.Name, ERPCode: values.ERPCode, ContactTypeId: contactsId, Remarks: values.Remarks, IsSite: isCheckedActive, ClientId: clientId, ProjectVersionId: projectId, StartDate: startDateValue, EndDate: endDateValue, AMCTypeId: amcId, NoOfLicences: values.NoOfLicences, WebSite: values.WebSite, Address: values.Address, Country: values.Country, State: values.State, City: values.City, District: values.District, PinCode: values.PinCode, Region: values.Region, Classification: classificationId, FirstContactBy: values.FirstContactBy, ContactMaster: response.data.map((contactPerson) => ({
                                            Location: contactPerson.Location,
                                            ContactPersonName: contactPerson.ContactPersonName,
                                            Designation: contactPerson.Designation,
                                            ContactNo: contactPerson.ContactNo,
                                            Mobile: contactPerson.Mobile,
                                            Email: contactPerson.Email,
                                            Id: contactPerson.Id
                                        })),
                                    }


                                }
                            });
                        }
                        else {
                            // Failure response
                            // alert("Error");
                            console.log("success");
                            console.log("error");

                        }
                    })


            } else {
                const payload = {
                    "SiteId": values.SiteId,
                    "ContactId": values.ContactId,
                    "Name": values.Name,
                    "ERPCode": values.ERPCode,
                    "ContactTypeId": contactsId,
                    "Remarks": values.Remarks,
                    "IsSite": isCheckedActive,
                    "ClientId": clientId,
                    "ProjectVersionId": projectId,
                    "StartDate": startDateValue,
                    "EndDate": endDateValue,
                    "AMCTypeId": amcId,
                    "NoOfLicences": values.NoOfLicences,
                    "WebSite": values.WebSite,
                    "Address": values.Address,
                    "Country": values.Country,
                    "State": values.State,
                    "City": values.City,
                    "District": values.District,
                    "PinCode": values.PinCode,
                    "Region": values.Region,
                    "Classification": classificationId,
                    "FirstContactBy": values.FirstContactBy,

                };
                URLS.postClientInstallationLocations(payload)
                    .then((response) => {
                        if (response.status === 200) {
                            AppCache.SetClientInstallationLocations(response.data);
                            console.log("response.data", response.data);

                            navigate('/dashboard/contactmasterdetailspage', {
                                replace: true,
                                state: {
                                    cliInstallation: {
                                        Name: values.Name, ERPCode: values.ERPCode, ContactTypeId: contactsId, Remarks: values.Remarks, IsSite: isCheckedActive, ClientId: clientId, ProjectVersionId: projectId, StartDate: startDateValue, EndDate: endDateValue, AMCTypeId: amcId, NoOfLicences: values.NoOfLicences, WebSite: values.WebSite, Address: values.Address, Country: values.Country, State: values.State, City: values.City, District: values.District, PinCode: values.PinCode, Region: values.Region, Classification: classificationId, FirstContactBy: values.FirstContactBy, ContactMaster: { SiteId: response.data.SiteId, ContactId: response.data.ContactId, Location: '', ContactPersonName: '', Designation: '', ContactNo: '', Mobile: '', Email: '' }
                                    }
                                }

                            });
                        }
                        else {
                            // Failure response
                            // alert("Error");
                            console.log("success");
                            console.log("error");

                        }
                    })



            }
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;



    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <AntTabs value={value} onChange={handleChangeValue}  variant="fullWidth" aria-label="basic tabs example">
                    <AntTab onClick={handleShow} label="Client Installation Location" {...a11yProps(0)} />
                    <AntTab onClick={handleShow} label=" Contact Details" {...a11yProps(1)} />
                </AntTabs >
            </Box>
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                    <TabPanel value={value} index={0}>
                <Stack spacing={2}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Client Name "
                            {...getFieldProps('Name')}
                            error={Boolean(touched.Name && errors.Name)}
                            helperText={touched.Name && errors.Name}
                        />
                        <TextField
                            fullWidth
                            label="ERP Code"
                            size="small"
                            {...getFieldProps('ERPCode')}
                            error={Boolean(touched.ERPCode && errors.ERPCode)}
                            helperText={touched.ERPCode && errors.ERPCode}
                        />
                        <FormControl
                            fullWidth
                            size="small"

                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Contact Type</InputLabel>
                            <Select
                                label="Contact Type"
                                value={contactsId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} >Select</MenuItem>

                                {contactTypeDropDown.map((contact) => (

                                    <MenuItem key={contact.Id} value={contact.Id} onClick={() => handelSelectedContacts(contact.Id, contact.Name)}> {contact.Name}</MenuItem>
                                ))}


                            </Select>

                        </FormControl>
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                        <FormControl
                            fullWidth
                            size="small"

                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Client</InputLabel>
                            <Select
                                label="Client"
                                size="small"
                                value={clientId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} >Select</MenuItem>

                                {selectedClient.map((client) => (
                                    <MenuItem key={client.ContactId} value={client.ContactId} onClick={() => handelSelectedClient(client.ContactId, client.Name)}> {client.Name}</MenuItem>
                                ))}
                            </Select>

                        </FormControl>

                        <FormControl
                            fullWidth
                            size="small"

                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Project Version</InputLabel>
                            <Select
                                label="Project Version"
                                size="small"
                                value={projectId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} >Select</MenuItem>

                                {selectedProject.map((project) => (
                                    <MenuItem key={project.ProjectVersionId} value={project.ProjectVersionId} onClick={() => handelSelectedProject(project.ProjectVersionId, project.VersionName)}> {project.VersionName}</MenuItem>
                                ))}
                            </Select>

                        </FormControl>



                        <TextField
                            fullWidth
                            label="Numbar Of Licences"
                            size="small"
                            {...getFieldProps('NoOfLicences')}
                            error={Boolean(touched.NoOfLicences && errors.NoOfLicences)}
                            helperText={touched.NoOfLicences && errors.NoOfLicences}

                        />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="Start Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(startDateValue).format('DD-MMM-yyyy')}
                                onChange={handleStart}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="End Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(endDateValue).format('DD-MMM-yyyy')}
                                onChange={handleEnd}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>
                        <FormControl
                            fullWidth
                            size="small"
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >AMC Type</InputLabel>
                            <Select
                                label="AMC Type"
                                value={amcId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} >Select</MenuItem>

                                {amcDropDown.map((amc) => (

                                    <MenuItem key={amc.Id} value={amc.Id} onClick={() => handelSelectedAMC(amc.Id, amc.Name)}> {amc.Name}</MenuItem>
                                ))}


                            </Select>

                        </FormControl>
                    </Stack>


                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Website "
                            {...getFieldProps('WebSite')}
                            error={Boolean(touched.WebSite && errors.WebSite)}
                            helperText={touched.WebSite && errors.WebSite}
                        />
                        <TextField
                            fullWidth
                            label="Address"
                            size="small"
                            {...getFieldProps('Address')}
                            error={Boolean(touched.Address && errors.Address)}
                            helperText={touched.Address && errors.Address}
                        />
           
                        <TextField
                            fullWidth
                            size="small"
                            label="Pincode "
                            {...getFieldProps('PinCode')}
                            error={Boolean(touched.PinCode && errors.PinCode)}
                            helperText={touched.PinCode && errors.PinCode}
                        />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                        <TextField
                            fullWidth
                            label="State"
                            size="small"
                            {...getFieldProps('State')}
                            error={Boolean(touched.State && errors.State)}
                            helperText={touched.State && errors.State}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            label="District "
                            {...getFieldProps('District')}
                            error={Boolean(touched.District && errors.District)}
                            helperText={touched.District && errors.District}
                        />
                        <TextField
                            fullWidth
                            label="City"
                            size="small"
                            {...getFieldProps('City')}
                            error={Boolean(touched.City && errors.City)}
                            helperText={touched.City && errors.City}
                        />
                    </Stack>


                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Country "
                            {...getFieldProps('Country')}
                            error={Boolean(touched.Country && errors.Country)}
                            helperText={touched.Country && errors.Country}
                        />
                       
                        <TextField
                            fullWidth
                            label="Region"
                            size="small"
                            {...getFieldProps('Region')}
                            error={Boolean(touched.Region && errors.Region)}
                            helperText={touched.Region && errors.Region}
                        />
                        <FormControl
                            fullWidth
                            size="small"
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Classification</InputLabel>
                            <Select
                                label="Classification"
                                value={classificationId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} >Select</MenuItem>

                                {classificationDropDown.map((classification) => (

                                    <MenuItem key={classification.Id} value={classification.Id} onClick={() => handelSelectedClassification(classification.Id, classification.Name)}> {classification.Name}</MenuItem>
                                ))}


                            </Select>

                        </FormControl>
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Remarks"
                            size="small"
                            {...getFieldProps('Remarks')}
                            error={Boolean(touched.Remarks && errors.Remarks)}
                            helperText={touched.Remarks && errors.Remarks}
                        />


                        <TextField
                            fullWidth
                            label="First Contact By"
                            size="small"
                            {...getFieldProps('FirstContactBy')}
                            error={Boolean(touched.FirstContactBy && errors.FirstContactBy)}
                            helperText={touched.FirstContactBy && errors.FirstContactBy}
                        />

                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={isCheckedActive} onChange={handleIsActiveChange} />} label="IsActive" />
                        </FormGroup>

                    </Stack>

                        </Stack>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ContactMasterDetailsPage/>
                    </TabPanel>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={4} marginTop={1}>

                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        sx={{ color: 'white' }}
                    >
                        Next
                    </LoadingButton>

                    <Button
                        sx={{ color: 'white' }}
                        onClick={handelCancelClick}
                        size="medium"
                        variant="contained"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Stack>
                {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}


            </Form>


        </FormikProvider>

        </>
    );
}