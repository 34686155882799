import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, Typography, Modal, FormControl, InputLabel, Select, MenuItem, Button, Box, Autocomplete, Grid } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function ProductLinkingDetailsComponent({ productLinking }) {

    console.log("productLinking", productLinking);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // const productId = proMaster.ID;
    const [isDelete, setIsDelete] = useState(false);


    // executive ID
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);


    const ProductMasterqwerLinkingId = productLinking[0]?.Id;
    console.log("ProductMasterLinkingId", ProductMasterqwerLinkingId);
    // Modal
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/oa/productlinking', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

    // dropdown
    const [products, setProducts] = useState([]);
    const [selectedProductsId, setSelectedProductsId] = useState(productLinking[0]?.CommercialProductId || "");
    const handleProducts = (id) => {
        setSelectedProductsId(id);

    };


    const [productsNonCommercial, setProductsNonCommercial] = useState([]);


    const [nonCommercialProductIds, setNonCommercialProductIds] = useState([]);

    const handleNonCommercialProduct = (selectedProducts) => {
        // Filter out duplicate product IDs
      

        // Update state with unique product IDs
        setNonCommercialProductIds(selectedProducts)
        const previouslySelectedIds = productLinking[0]?.ProductId ? productLinking[0]?.ProductId.split(',').map(id => parseInt(id, 10)) : [];
        const deselected = previouslySelectedIds.some(id => !selectedProducts.find(product => product.Id === id));

        setIsDelete(deselected);
    };

    useEffect(() => {
        URLS.getCommercialProducts()
            .then((response) => {

                setProducts(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

        URLS.getProductNonCommercial()
            .then((response) => {

                setProductsNonCommercial(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
     

    }, []);

    const ProductMasterSchema = Yup.object().shape({
        Remark: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Remark is required'),

    });

    const formik = useFormik({
        initialValues: {
            ProductMasterLinkingId: ProductMasterqwerLinkingId,
            ProductId: selectedProductsId,
            CommercialProductId: nonCommercialProductIds,
            Remark: productLinking[0]?.Remark,
            CreatedBy: userId,
            EditedBy: 0,
            IsDelete: false,
        },
     
        onSubmit: (values, actions) => {
            if (!selectedProductsId) {
                enqueueSnackbar("Please select a commerical product ", { variant: 'error' });
                return;
            }   
            if (nonCommercialProductIds.length === 0) {
                enqueueSnackbar("Please select a non-commerical product ", { variant: 'error' });
                return;
            }   
            const nonCommercialIds = nonCommercialProductIds.map(item => item.Id).join(',');
            
            console.log("check>>>>>>>>", nonCommercialProductIds.length < productsNonCommercial.length, nonCommercialProductIds.length, values.CommercialProductId.length)
            const payload = {
                "ProductMasterLinkingId":0,
                "ProductId": nonCommercialIds,
                "CommercialProductId": selectedProductsId,
                "Remark": values.Remark || "",
                "CreatedBy": userId,
                "EditedBy": userId,
                "IsDelete": isDelete,
            };
            console.log("payload>>>>>.",payload)
            URLS.saveProductMasterLinking(payload)
                .then((response) => {
                    if (response.status === 200) {
                        navigate('/dashboard/oa/productlinking', { replace: true });

                    }
                    enqueueSnackbar("Data Saved successfully", { variant: 'success' });
                })
                .catch(error => {
                    handleError(error, enqueueSnackbar);
                });
            console.log("payload", payload);
        },
    });
   

    useEffect(() => {
    const preselectedIds = productLinking[0]?.ProductId ? productLinking[0]?.ProductId.split(',') : [];
        // Map preselectedIds to their corresponding names
        const preselectedProducts = preselectedIds.map(id => {
            const product = productsNonCommercial.find(item => item.Id === parseInt(id, 10));
            return product ? { Id: product.Id, Name: product.Name } : null;
        }).filter(Boolean); // Filter out any null values

        // Set the mapped products into the state
        setNonCommercialProductIds(preselectedProducts);
    }, [productsNonCommercial]); // Update when productsNonCommercial or preselectedIds change



    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    console.log("productsNonCommercial..", productsNonCommercial, "nonCommercialProductIds",nonCommercialProductIds)

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <FormControl size="small" fullWidth>
                                <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Commercial Products</InputLabel>
                                <Select
                                    label="Commercial Products"
                                    size="small"
                                    value={selectedProductsId}
                                    onChange={(event) => handleProducts(event.target.value)}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7', // Set the background color here
                                                maxHeight: '200px',
                                                width: '200px',// Set the max height here
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem key={0} value={""}>Select</MenuItem>
                                    {products.map((product) => (
                                        <MenuItem key={product.Id} value={product.Id}>{product.Name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Autocomplete
                                multiple
                                fullWidth
                                size="small"
                                id="checkboxes-tags-demo"
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.Name}
                                onChange={(event, value) => handleNonCommercialProduct(value)}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox checked={selected} />
                                        {option.Name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Non-Commercial Product"
                                    />
                                )}
                                getOptionSelected={(option, value) => {
                                    console.log("Comparing option:", option);
                                    console.log("With value:", value);
                                    console.log("Result:", option.Id === value.Id);
                                    return option.Id === value.Id;
                                }}
                                options={productsNonCommercial}
                                value={nonCommercialProductIds}
                                isOptionEqualToValue={(option, value) => option.Id === value.Id}
                            />

                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="Remark"
                                size="small"
                                {...getFieldProps('Remark')}
                                error={Boolean(touched.Remark && errors.Remark)}
                                helperText={touched.Remark && errors.Remark}
                            />
                        </Grid>
                    </Grid>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Save
                        </LoadingButton>

                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>
                    </Stack>

                </Stack>


            </Form>

            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider>
    );
}