import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import RequestMaster from "../../../components/SidebarMenuComponents/ApprovalComponent/RequestMaster";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function RequestMasterPage() {
     const location = useLocation();
    const request = location.state;
    const requestedId = location.state;
    const statusId = location.state;
    const fromDate = location.state;
    const toDate = location.state;
    console.log("requestedIdssssss", requestedId);
    console.log("request", request);
     
    return (
        <Page title="  Request Master  | tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Request Master
            </Typography>

            <ContentStyle>
                <RequestMaster request={request} requestedId={requestedId} statusId={statusId} fromDate={fromDate} toDate={toDate}/>
            </ContentStyle>
        </Page>
    );
}

