import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Box, Button, Card, FormControl, FormControlLabel, Tab, Typography,Tabs,FormLabel, Grid, MenuItem, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';

import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { useFormik, Form, FormikProvider } from 'formik';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Moment from 'moment';
import { startOfMonth, endOfMonth } from 'date-fns';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import AppCache from '../../../services/AppCache';
import URLS from '../../../services/urls.service';
import ModalPopUp from '../../Common/ModalPopUp';
import { handleError } from '../../Common/Utils';
import ExecutiveExpensesDetailsTabPanel from './ExecutiveExpensesDetailsTabPanel';
import Scrollbar from '../../Scrollbar';


// -----------------------------------------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ExecutiveExpensesDetails({ executiveExpenses }) {
    console.log("executiveExpenses>>>>>", executiveExpenses)
    const navigate = useNavigate();

    // const productId = proMaster.ID;
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const [fromDateValue, setFromDateValue] = useState(startOfMonth(new Date()));
    const [selectedType, setSelectedType] = useState('expense');
    const [selectedAllocationType, setSelectedAllocationType] = useState('');

    const [value, setValue] = useState(0);
    const [hide, setHide] = useState(false);
    const [fieldSets, setFieldSets] = useState([
        {
            OAMasterDetailsId: 0,
            oaProductId: "",
            Remark: "",
            Rate: "",
            Quantity: "",
            Month: "",
            SeqNo: "",
            OAPayementTerm: ""
        },
    ]);

    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/master/executiveexpenses', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);
    };
    const handleEffFrom = () => {

    }
    const handleRadioChange = (event) => {
        setSelectedType(event.target.value);
    };
    const handleAllocationRadioChange = (event) => {
        setSelectedAllocationType(event.target.value);
    };

    const handleTabChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    const handleShow = () => {
        setFieldSets((prevFieldSets) => {
            const updatedFieldSets = [...prevFieldSets];
            return updatedFieldSets;
        });
        setHide(false)
    };

    const handleNextClick = () => {
        setValue(1); 
    }

    const formik = useFormik({
        initialValues: {
          
        },
        onSubmit: (values, actions) => {
           
            console.log("payload>>>>>>>>>", values)
        },
    });

    const { handleSubmit } = formik

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={2}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date of expense"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(fromDateValue).format('DD-MMM-yyyy')}
                            onChange={handleEffFrom}
                            renderInput={(params) => <TextField  size="small" {...params} />}
                        />
                        </LocalizationProvider>
                        <FormControl>

                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selectedType}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="expense" control={<Radio />} label="Expense" />
                                <FormControlLabel value="receipt" control={<Radio />} label="Receipt" />
                                <FormControlLabel value="return" control={<Radio />} label="Return" />
                            </RadioGroup>

                        </FormControl>
                    </Stack>
                    {selectedType === 'expense' && (
                        <>
                            <Scrollbar>
                            <ContentStyle>
                                <ExecutiveExpensesDetailsTabPanel formik={formik} handleShow={handleShow} handleTabChangeValue={handleTabChangeValue} value={value} fieldSets={fieldSets}
                                    handleAllocationRadioChange={handleAllocationRadioChange} selectedAllocationType={selectedAllocationType}
                                />
                                </ContentStyle>
                            </Scrollbar>
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>
                                {value === 0 && (
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ color: 'white' }}
                                    onClick={handleNextClick }
                                >
                                    Next
                                </Button>
                                )}
                                {value === 1 && (
                                      <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ color: 'white' }}

                                >
                                    Save
                                </Button>
                                )}

                                <Button
                                    sx={{ color: 'white' }}
                                    onClick={handelCancelClick}

                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </>
                       
                    )}
                    {(selectedType === 'receipt' || selectedType === 'return')  && (
                        <>
                            <Stack spacing={4} style={{ marginTop:'20px' }}>
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={4} >
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Amount"
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Remark"
                                />
                            </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row', }} justifyContent="center" spacing={4} >
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ color: 'white' }}

                                >
                                    Save
                                </Button>
                                <Button
                                    sx={{ color: 'white' }}
                                    onClick={handelCancelClick}

                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                </Stack>
                            </Stack>
                        </>

                    )}
                </Stack>
                
             
            </Form>

            { openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} /> }

        </FormikProvider>

               
    );
}