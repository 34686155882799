
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Accordion, AccordionDetails, Stack, TextField, FormControl, InputLabel, Select, Grid, Modal, IconButton, MenuItem, Button, paymentId, Box, Typography, Tabs, Tab, TextareaAutosize, Card, Autocomplete, Chip, RadioGroup, Radio } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


import { styled } from '@mui/material/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';




const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};

const remarkTextFieldStyle = {
    width: '48.5%'
}

export default function AllocationExecutiveExpensesTabPanel({ formik, oaProductsData, fieldSets, selectedAllocationType, handleAllocationRadioChange }) {

    const TABLE_HEAD = [
        { field: 'client', headerName: 'Client', flex: 1 },
        { field: 'project', headerName: 'Project', flex: 1 },
        { field: 'allocation', headerName: 'Allocation', flex: 1 },

    ];


    const validateInput = (input) => {
        return /^\d*$/.test(input); // Regular expression to allow only numeric input
    };

    return (
        <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                <FormControl>

                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={selectedAllocationType}
                        onChange={handleAllocationRadioChange}
                    >
                        <FormControlLabel value="net" control={<Radio />} label="Net" />
                        <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
                       
                    </RadioGroup>

                </FormControl>
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                <FormControl fullWidth size="small">
                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Projects</InputLabel>
                    <Select
                        label="Projects"
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    backgroundColor: '#fbe9e7',
                                    maxHeight: '200px',
                                    width: '200px',
                                },
                            },
                        }}
                    >
                        <MenuItem key={0} value={""}>Select</MenuItem>

                    </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Clients</InputLabel>
                    <Select
                        label="Clients"
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    backgroundColor: '#fbe9e7',
                                    maxHeight: '200px',
                                    width: '200px',
                                },
                            },
                        }}
                    >
                        <MenuItem key={0} value={""}>Select</MenuItem>

                    </Select>
                </FormControl>
                           
           </Stack>
            <div style={{ marginTop: '20px' }} />
            <div style={{ height: '54vh', width: '100%' }}>
                <DataGrid
                    rows={{}}
                    density="compact"
                    columns={TABLE_HEAD}
                    getRowId={(row) => row.id} // Use the ID property as the row ID
                    components={{
                        Toolbar: GridToolbar,
                    }}
                />
            </div>       
        </Stack>
    );
}