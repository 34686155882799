import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    // if (query) {
    //   
    //    return filter(array, (_project) => _project.ClientAlias.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    // }
    return stabilizedThis.map((el) => el[0]);
}

export default function POMasterListing() {

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    // Client DropDown
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('');

    // Calender
    const [effectiveFromValue, setEffectiveFromValue] = useState('' || startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState('' || endOfMonth(new Date()));

    // Page
    const [page, setPage] = useState(0);

    // Order
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Post Report Data
    const [poMasterData, setPoMasterData] = useState([]);

    // Loading
    const [isLoading, setIsLoading] = useState();

    // Data Table
    const TABLE_HEAD = [
        { field: 'Id', headerName: 'ID', width: 150 },
        { field: 'PONumber', headerName: 'PO Number', width: 150 },
        { field: 'PODate', headerName: 'PO Date', width: 300 },
        { field: 'ClientName', headerName: 'Client', width: 300, flex: 1 },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },

    ];

    // Client Dropdown
    const handleClient = (id) => {
        setSelectedClientId(id);

    };

    // Date
    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };

    // Client DropDown Api call
    useEffect(() => {
        URLS.getOAClient()
            .then((response) => {

                setSelectedClient(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

    }, []);

    const [data, setData] = useState([])

    // Edit
    const navigate = useNavigate();
    const handleClick = (event, cellValues) => {
      
        const payload = { EditID: cellValues.row.Id }
        URLS.getPOMasterEditData(payload)
            .then((response) => {
                const jsonDataString = JSON.stringify(response.data);

                const modifiedData = JSON.parse(jsonDataString);
                setData(modifiedData);

                // navigate('/dashboard/clientmasterdetailspage', { state: { cliMaster: cellValues.row, teamPanelData: response.data, productTeam: response.data } })
                navigate('/dashboard/pomasterdetailspage', { state: { poMaster: modifiedData } })
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    }


    // Filter
    // Filter
    const onFilterClick = () => {
        setIsLoading(true);

        // Format the selected dates to ensure consistency
        const fromDate = Moment(effectiveFromValue).startOf('day').format('DD MMM YYYY');
        const toDate = Moment(effectiveToValue).endOf('day').format('DD MMM YYYY');

        // Prepare the payload with the formatted dates
        const payload = { ClientID: selectedClientId, FromDate: fromDate, ToDate: toDate };

        URLS.getPOMasterListing(payload)
            .then((response) => {
                setPoMasterData(response.data);
                setIsLoading(false);
                enqueueSnackbar("Fetch data successfully", { variant: 'success' });
            })
            .catch(error => {
                enqueueSnackbar("Data not found", { variant: 'error' });
                // handleError(error, enqueueSnackbar);
            });
    };


    // Sorting
    const filteredProjects = applySortFilter(poMasterData, getComparator(order, orderBy), filterName);

    const isProjectNotFound = filteredProjects.length === 0;

    // Reset
    const handleReset = () => {
        setSelectedClientId("");
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setPoMasterData([]);
    }

    useEffect(() => {
        setSelectedClientId("");
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setPoMasterData([]);
    }, [location.key]); 

    return (

        <Page title="PO Master Listing" >
            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <FormControl
                        size="small"
                        fullWidth
                    >
                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Clients</InputLabel>
                        <Select
                            label="Clients"
                            size="small"
                            value={selectedClientId}

                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}

                        >
                            <MenuItem key={0} value={""} onClick={() => handleClient()} >Select</MenuItem>

                            {selectedClient.map((product) => (
                                <MenuItem key={product.ContactId} value={product.ContactId} onClick={() => handleClient(product.ContactId)}> {product.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>


                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                            onChange={handleEffFrom}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                            onChange={handleEffTo}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}

                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/pomasterdetailspage'
                                variant="contained"
                                size="small"
                                state={{ poMaster: { POMasterId: '', FromDate: '', ToDate: '', ExecutiveId: '', selectedPODetails:'',PODetailsModel: { PODetailsId: '', POMasterId: '', Name:'',ProductId: '', PONumber: '', PODetailsRemark: '', Rate: '', Quantity: '', ExecutiveId: '' } } }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleReset}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                        </Tooltip >

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={poMasterData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => poMasterData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}