import { React, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography, Divider, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { useFormik, Form, FormikProvider, isNaN } from 'formik';
import axios from 'axios';
import { handleError } from '../../../components/Common/Utils';
import AppCache from '../../../services/AppCache';
import URLS from '../../../services/urls.service';


import ModalPopUp from '../../../components/Common/ModalPopUp';
import Page from '../../../components/Page';
import OAMasterDetails from '../../../components/SidebarMenuComponents/OAComponent/OAMasterDetails';


// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function OAMasterDetailsPage() {

    const StatusData = [
        { Id: 2, value: 'Close' },
        { Id: 1, value: 'Open' }
    ];

    const navigate = useNavigate();
    const location = useLocation();
    const { oaMaster } = location.state;
    const { oaDetails } = location.state;
    const { isClicked } = location.state;
    console.log("isClicked>>>>>>>>>>>>>>>>>", isClicked,oaMaster)

    const [userId, setUserId] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const [value, setValue] = useState(0);
    const [hide, setHide] = useState(false);
    const [prductData, setPrductData] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedExecutives, setSelectedExecutives] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState([]);
    const [oaProductsData, setOAProductsData] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState(oaMaster[0]?.ClientId || '');
    const [executivesId, setExecutivesId] = useState(oaMaster[0]?.Responsible || "");
    const [paymentId, setPaymentid] = useState(oaMaster[0]?.PaymentTerm || "");
    const [statusId, setStatusId] = useState(oaMaster[0]?.Status === 0 ? 2 : 1);
    console.log("statusId>>>>>>>>", statusId, oaMaster[0]?.Status)
    const [oaProductId, setOaProductId] = useState((oaMaster && oaMaster.length > 0) ? oaMaster[0].ProductId : "");
    const [saveDisable, setSaveDisable] = useState(false);

    const [selectProductPayment, setSelectProductPayment] = useState([]);

    const [productPayemntId, setProductPayemntId] = useState(oaMaster[0]?.PaymentTerm || "");

    const [oaNumber, setOaNumber] = useState('')

    const initialRefQuotationDate = oaMaster[0]?.RefQuotationDate ? new Date(oaMaster[0]?.RefQuotationDate) : new Date();

    // Check if the parsed date is valid
    const isValidDate = !isNaN(initialRefQuotationDate.getTime());

    // If the parsed date is valid, format it. Otherwise, use the current date.
    const formattedInitialDate = isValidDate
        ? `${initialRefQuotationDate.getDate()} ${initialRefQuotationDate.toLocaleString('default', { month: 'long' })} ${initialRefQuotationDate.getFullYear()}`
        : `${new Date().getDate()} ${new Date().toLocaleString('default', { month: 'long' })} ${new Date().getFullYear()}`;

    // Set the state with the formatted date
    const [refQuotationDate, setRefQuotationDate] = useState(formattedInitialDate);

    const initialOADate = oaMaster[0]?.OADate ? new Date(oaMaster[0].OADate) : new Date();
    const isValidOADate = !isNaN(initialOADate.getTime());

    // If the parsed date is valid, format it. Otherwise, use the current date.
    const formattedInitialOADate = isValidOADate
        ? `${initialOADate.getDate()} ${initialOADate.toLocaleString('default', { month: 'long' })} ${initialOADate.getFullYear()}`
        : `${new Date().getDate()} ${new Date().toLocaleString('default', { month: 'long' })} ${new Date().getFullYear()}`;

    const [oaDateValue, setOaDatevalue] = useState(formattedInitialOADate);

    console.log("formattedInitialOADate", oaDateValue)
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [oaPaymentTerm, setOaPaymentTerm] = useState(paymentId || '');

    const [fieldSets, setFieldSets] = useState([
        {
            OAMasterDetailsId: 0,
            oaProductId: "",
            Remark: "",
            Rate: "",
            Quantity: "",
            Month: "",
            SeqNo: "",
            OAPayementTerm:""
        },
    ]);

    // message
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };

    // cancle confirm
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/oa/oamaster', { replace: true });
        }
        else {
            setOpenModalCancel(false);
        }
    };

    // Tabs
    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    // Hide
    const handleHide = () => {
        setHide(true)
    };

    // show
    const handleShow = () => {
        setFieldSets((prevFieldSets) => {
            const updatedFieldSets = [...prevFieldSets];
            return updatedFieldSets;
        });
        setHide(false)
    };

    // cancel
    const handelCancelClick = () => {
        setOpenModalCancel(true);
    };

   
    console.log("oaNumber>>>>>>>>>>>>>>>>.", oaMaster[0]?.OANumber)
    // Clients dropdown
    const handleClient = (id) => {
        formik.setFieldValue('ClientId', id);
        setSelectedClientId(id);
    };

    // DropDown Executives responsible
    const handleExecutives = (id) => {
        setExecutivesId(id);
    };

    // payment dropdown
    const handlePayment = (id) => {
        formik.setFieldValue('PaymentTerm', id);
        setPaymentid(id);
        setFieldSets((prevFieldSets) =>
            prevFieldSets.map((fieldSet) => ({
                ...fieldSet,
                OAPayementTerm: fieldSet.OAPayementTerm === oaPaymentTerm ? id : fieldSet.OAPayementTerm,
            }))
        );
    };

    // status dropdown
    const handleStatusChange = (value) => {
        setStatusId(value);

    };

    // refQuotation calendar
    const handleRefQuotationDateChange = (newValue) => {
        const selectedDate = new Date(newValue);
        const day = selectedDate.getDate().toString().padStart(2, '0');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthIndex = selectedDate.getMonth();
        const month = monthNames[monthIndex];
        const year = selectedDate.getFullYear();
        const formattedDate = `${day} ${month} ${year}`;
        setRefQuotationDate(formattedDate);

    };

    // oaDate dropdown
    // oaDate dropdown
    const handleOADateChange = (newValue) => {
        const selectedDate = new Date(newValue);
        const day = selectedDate.getDate().toString().padStart(2, '0');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthIndex = selectedDate.getMonth();
        const month = monthNames[monthIndex];
        const year = selectedDate.getFullYear();
        const formattedDate = `${day} ${month} ${year}`;
        setOaDatevalue(formattedDate);
    };

    // productId
    const handleProductIDChange = (id) => {
        setOaProductId(id);
    };

    const handleProductPaymentIdChange = (index, id) => {
        setFieldSets((prevFieldSets) => {
            const updatedFieldSets = [...prevFieldSets];
            updatedFieldSets[index].OAPayementTerm = id;
            return updatedFieldSets;
        });
    };

    // prdocutData
    const handleProductsChange = (index, id, name) => {
        // Update the product information in the specific field set at the given index
        setFieldSets((prevFieldSets) => {
            const updatedFieldSets = [...prevFieldSets];
            updatedFieldSets[index] = {
                ...updatedFieldSets[index],
                oaProductId: id,

                // You can include other product-related information as needed
            };

            return updatedFieldSets;
        });
    };

    // Add Button
   

    const handleAddButtonClick = () => {
        setFieldSets((prevFieldSets) => {
            // Determine the default payment term for the new field set
            const defaultPaymentTerm = prevFieldSets.length > 0 ? prevFieldSets[0].OAPayementTerm : '';
            const newSeqNo = fieldSets.length + 1;
            // Create a new field set with the default payment term
            const newFieldSet = {
                oaProductId: '',
                Quantity: '',
                Rate: '',
                Month: '',
                OAPayementTerm: defaultPaymentTerm,
                Remark: '',
                SeqNo: newSeqNo.toString(),
            };

            return [
                ...prevFieldSets,
                newFieldSet
            ];
        });
    };


    // filed
    const handleFieldChange = (index, fieldName, value) => {
        setFieldSets((prevFieldSets) => {
            const updatedFieldSets = [...prevFieldSets];
            // Update the specific field in the field set
            updatedFieldSets[index][fieldName] = value;
            // If the field being updated is "ProductPayemntId", update all matching payment terms
            if (fieldName === 'OAPayementTerm') {
                updatedFieldSets.forEach((fieldSet, idx) => {
                    if (idx !== index && fieldSet.OAPayementTerm === value) {
                        updatedFieldSets[idx].OAPayementTerm = value;
                    }
                });
            }
            return updatedFieldSets;
        });
    };


    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        if (oaDetails && oaDetails.length > 0) {
            const newFieldSets = oaDetails.map(data => ({
                ...data
            }));
            setFieldSets(newFieldSets);
        }
    }, []);

    useEffect(() => {
        const { productTeam } = location.state;
        if (productTeam && Array.isArray(productTeam) && productTeam.length > 0) {
            setPrductData(productTeam);
            // setTeams([{ teamMasterID: 0, teamIndex: 0, teamName: '', projectId: 0, selectedExecutives: [] }]);
        } else {
            setPrductData([{ teamMasterID: 0, teamIndex: 0, teamName: '', projectId: 0, selectedExecutives: [] }]);
        }
    }, [location.state]);

    useEffect(() => {
        URLS.getClientMasterData()
            .then((response) => {
                setSelectedClient(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

        URLS.getExecutivesDropData()
            .then((response) => {
                setSelectedExecutives(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

        URLS.getPaymentTermDropDown()
            .then((response) => {
                setSelectedPayment(response.data);
                setSelectProductPayment(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

    }, []);

    useEffect(() => {

        URLS.getOAProductsDropDown()
            .then((response) => {

                setOAProductsData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

    }, []);


    const oaMasterSchema = Yup.object().shape({
        ClientId: Yup.string()
            .required('Please select a client'),
        PaymentTerm: Yup.string().required('Please select a payment term'),

    });

    console.log("paymentId>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", paymentId)
    const formik = useFormik({
        initialValues: {
            OAMasterId: (oaMaster && oaMaster.length > 0) ? oaMaster[0].OAMasterId : 0,
            OANumber: oaMaster[0]?.OANumber || "",
            OADate: (oaMaster && oaMaster.length > 0) ? oaDateValue : "",
            ClientId: selectedClientId || "",
            BuyersRefOrderNo: oaMaster[0]?.BuyersRefOrderNo,
            RefQuotationNo: oaMaster[0]?.RefQuotationNo,
            RefQuotationDate: (oaMaster && oaMaster.length > 0) ? refQuotationDate : "",
            Responsible: executivesId,
            PaymentTerm: paymentId,
            Status:  statusId,
            Amount: oaMaster[0]?.Amount,
            TaxAmount: oaMaster[0]?.TaxAmount,
            TotalAmount: oaMaster[0]?.TotalAmount,
            Remark: oaMaster[0]?.Remark || " ",
            CreatedBy: userId,
        },
       
        validationSchema: oaMasterSchema,

        onSubmit: (values, actions) => {

            const payload = {
                OAMasterId: (oaMaster && oaMaster.length > 0) ? oaMaster[0].OAMasterId : 0,
                "OANumber": values.OANumber,
                "OADate": oaDateValue,
                "ClientId": selectedClientId,
                "BuyersRefOrderNo": values.BuyersRefOrderNo || "",
                "RefQuotationNo": values.RefQuotationNo || "",
                "RefQuotationDate": refQuotationDate,
                "Responsible": executivesId,
                "PaymentTerm": paymentId || "",
                "Status": statusId === 2 ? 0 : values.Status, 
                "Amount": values.Amount || "",
                "TaxAmount": values.TaxAmount || "",
                "TotalAmount": values.TotalAmount || "",
                "Remark": values.Remark || "",
                CreatedBy: userId,
                EditedBy: userId,
                "OAMasterDetailsSaveModel":
                    fieldSets.map((fieldSet, index) => ({
                        "OAMasterDetailsId": fieldSet.OAMasterDetailsId,
                        "ProductId": fieldSet.oaProductId,
                        "OADetailsRemark": fieldSet.Remark,
                        "Rate": fieldSet.Rate,
                        "Quantity": fieldSet.Quantity,
                        "Month": fieldSet.Month,
                        "SeqNo": index + 1,
                        "OAPayementTerm": fieldSet.OAPayementTerm

                    }))
            };
            console.log("values>>>>>>>>", values)
            URLS.saveOAMasterListing(payload)
                .then((response) => {
                    if (response.status === 200) {
                      /*  navigate('/dashboard/oamasterdetailspage', { replace: true }); */
                        setStatusId(values.Status);
                        setSaveDisable(true)

                        const oaNumberMatch = response.data.message.match(/OANumber:([^|]+)/);
                        if (oaNumberMatch && oaNumberMatch[1]) {
                            const extractedOaNumber = oaNumberMatch[1];

                            // Use extractedOaNumber as needed
                            setOaNumber(extractedOaNumber);
                        }
                    }
                    enqueueSnackbar("OA Master data saved successfully", { variant: 'success' });
                })
                .catch(error => {
                    enqueueSnackbar("Product data is not filled while saving OA Master data.Details are incomplete", { variant: 'error' });
                   /* handleError(error, enqueueSnackbar); */
                });
            console.log("payload>>>>>>>>>>>",payload)
        },
    });

    const handelPrintClick = () => {
        const { values } = formik;

      
        const objOAMaster = {
            OAMasterId: (oaMaster && oaMaster.length > 0) ? oaMaster[0].OAMasterId : 0,
            "OANumber": oaMaster[0]?.OANumber ? oaMaster[0]?.OANumber : oaNumber,
            "OADate": oaDateValue,
            "ClientId": selectedClientId,
            "BuyersRefOrderNo": values.BuyersRefOrderNo,
            "RefQuotationNo": values.RefQuotationNo || "",
            "RefQuotationDate": refQuotationDate,
            "Responsible": executivesId,
            "PaymentTerm": paymentId || "",
            "Status": statusId === 2 ? 0 : values.Status,
            "Amount": values.Amount || "",
            "TaxAmount": values.TaxAmount || "",
            "TotalAmount": values.TotalAmount || "",
            "Remark": values.Remark || "",
            CreatedBy: userId,
            EditedBy: userId,
            "OAMasterDetailsSaveModel":
                fieldSets.map((fieldSet, index) => ({
                    "OAMasterDetailsId": fieldSet.OAMasterDetailsId,
                    "ProductId": fieldSet.oaProductId,
                    "OADetailsRemark": fieldSet.Remark,
                    "Rate": fieldSet.Rate,
                    "Quantity": fieldSet.Quantity,
                    "Month": fieldSet.Month,
                    "SeqNo": index + 1,
                    "OAPayementTerm": fieldSet.OAPayementTerm

                })),
        }
              const  IsCostSheet= isClicked === true || false


        if (isClicked === true) {
            URLS.generateOAPDFTrue(objOAMaster)
                .then((response) => {
                    if (response.data && response.data.filepath) {
                        // Open the PDF file in a new browser tab or window
                        const pdf = response.data.filepath
                        window.open(pdf, '');
                        console.log("filepath>>>>>>>>>>>>>>>>>>>>>>>>.", pdf, response)

                    } else {
                        enqueueSnackbar(" No PDF file found.", { variant: 'error' });
                    }
                })
                .catch(error => {
                    enqueueSnackbar("Error generating PDF", { variant: 'error' });
                });
        } else {
            URLS.generateOAPDFFalse(objOAMaster)
                .then((response) => {
                    if (response.data && response.data.filepath) {
                        // Open the PDF file in a new browser tab or window
                        const pdf = response.data.filepath
                        window.open(pdf, '');
                        console.log("filepath>>>>>>>>>>>>>>>>>>>>>>>>.", pdf, response)

                    } else {
                        enqueueSnackbar(" No PDF file found.", { variant: 'error' });
                    }
                })
                .catch(error => {
                    enqueueSnackbar("Error generating PDF", { variant: 'error' });
                });
        }

       

        console.log("print payload>>>>>>>>>>>>>>>>>", isClicked)

        // Open the PDF file in a new browser tab or window
    };

    console.log("++++++++++++++++++++++++++++++++++++", oaNumber)
    const deleteTeam = (teamIndexToDelete) => {
        // Filter out the team to delete based on teamIndex
        const updatedTeams = fieldSets.filter((team, index) => index !== teamIndexToDelete);


        setFieldSets(updatedTeams);
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
    
    return (
        <Page title="  OA Master Details | tiketic" >
            <FormikProvider value={formik}>
                <Form variant="body2" autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                        OA Master Details
                    </Typography>

                    <ContentStyle>
                        <OAMasterDetails oaMaster={oaMaster} oaDetails={oaDetails} deleteTeam={deleteTeam} formik={formik} StatusData={StatusData} value={value} handleChangeValue={handleChangeValue}
                            hide={hide} handleHide={handleHide} handleShow={handleShow} selectedClient={selectedClient} selectedClientId={selectedClientId}
                            handleClient={handleClient} selectedExecutives={selectedExecutives} executivesId={executivesId} handleExecutives={handleExecutives} statusId={statusId}
                            handleStatusChange={handleStatusChange} refQuotationDate={refQuotationDate} handleRefQuotationDateChange={handleRefQuotationDateChange}
                            oaDateValue={oaDateValue} handleOADateChange={handleOADateChange} oaProductsData={oaProductsData} oaProductId={oaProductId}
                            handleProductsChange={handleProductsChange} fieldSets={fieldSets} handleAddButtonClick={handleAddButtonClick} handleFieldChange={handleFieldChange}
                            handleProductIDChange={handleProductIDChange} selectedPayment={selectedPayment} paymentId={paymentId} handlePayment={handlePayment} selectProductPayment={selectProductPayment}
                            productPayemntId={productPayemntId} handleProductPaymentIdChange={handleProductPaymentIdChange} oaPaymentTerm={oaPaymentTerm} />
                    </ContentStyle>

                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>
                        {isClicked === true ? (
                            <Button
                                size="medium"
                                variant="contained"
                                sx={{ color: 'white' }}
                                onClick={handelPrintClick}

                            >
                                Cost Sheet
                            </Button>
                        ) :
                            (
                                <>
                                    <LoadingButton
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        sx={{ color: 'white' }}
                                        disabled={saveDisable}
                                    >
                                        Save
                                    </LoadingButton>
                                    <Button
                                        sx={{ color: 'white' }}
                                        onClick={handelPrintClick}
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Print
                                    </Button>
                                </>
                        )}

                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}

                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Close
                        </Button>
                       
                    </Stack>
                </Form>
                {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

            </FormikProvider>
        </Page>
    );
}

