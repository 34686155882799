import { useLocation } from "react-router-dom";
// material
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components

import ApiMaster from "../../../components/SidebarMenuComponents/MasterComponent/ApiMaster";
import Page from "../../../components/Page";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function ApiMasterPage() {
    const location = useLocation();
    const apiMaster = location.state;
    console.log("landing page", apiMaster)
    return (
        <Page title="API Master Listing| tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                API Master Listing
            </Typography>

            <ContentStyle>
                <ApiMaster apiMaster={apiMaster } />
            </ContentStyle>
        </Page>
    );
}

