import * as LocalStorage from 'local-storage';

let _CurrentLoggedInUser;
let _CurrentAPIURL;
let _SmilePreviousURL;
let _ClientInstallationLocations;
let _PowerBIURL;


class AppCache {

    constructor() {

        this.sessionTimeout = 20 * 60 * 1000; // 30 minutes in milliseconds
        this.sessionTimer = null;
        this.activityEvents = ['mousedown', 'mousemove', 'keypress', 'scroll']; // Add any additional events as needed

        this.startSessionTimer = () => {
            this.sessionTimer = setTimeout(this.clearStorage, this.sessionTimeout);
        };

        this.resetSessionTimer = () => {
            clearTimeout(this.sessionTimer);
            this.startSessionTimer();
        };

        this.clearStorage = () => {
            console.log('Session expired');
            _CurrentAPIURL = null;
            _CurrentLoggedInUser = null;
            LocalStorage.clear();


            // You can perform any additional actions here, such as redirecting to the login page
            window.location.href = '/login';
        };
        this.handleUserActivity = () => {
            this.resetSessionTimer();
        };
        this.activityEvents.forEach(event => {
            document.addEventListener(event, this.handleUserActivity);
        });

        // Start the session timer when the application initializes
        this.startSessionTimer();
    

        this.GetCurrentLoggedInUser = () => {
            _CurrentLoggedInUser = LocalStorage.get("_CurrentLoggedInUser");
            return (_CurrentLoggedInUser);
        };

        this.GetCurrentAPIURL = () => {
            _CurrentAPIURL = LocalStorage.get("_CurrentAPIURL");
            return (_CurrentAPIURL);
        };


        this.GetSmilePreviousURL = () => {
            _SmilePreviousURL = LocalStorage.get("_SmilePreviousURL");
            return (_SmilePreviousURL);
        };

        //  this.GetPowerBIURL = () => {
        //    _PowerBIURL = LocalStorage.get("_PowerBIURL");
        //    return (_PowerBIURL);
        //  };

        this.GetClientInstallationLocations = () => {
            _ClientInstallationLocations = LocalStorage.get("_ClientInstallationLocations");
            return (_ClientInstallationLocations);
        };



        this.SetCurrentLoggedInUser = (darbordMenus) => {
            const CurrentLoggedInUser = {
                Menus: darbordMenus.DashBoardMenuslist,
                ExecutiveName: darbordMenus.UserName,
                ExecutiveId: darbordMenus.LoggedInId,
            }
            _CurrentLoggedInUser = CurrentLoggedInUser;
            LocalStorage.set("_CurrentLoggedInUser", _CurrentLoggedInUser);
            // console.log("CurrentLoggedInUser", CurrentLoggedInUser)
            this.resetSessionTimer();
        };

        this.SetCurrentAPIURL = (url) => {

            const CurrentAPIURL = {
                BaseAPIURL: url.SmileServiceApiBaseUrl
            }
            _CurrentAPIURL = CurrentAPIURL.BaseAPIURL;
             console.log("CurrentAPIURL", _CurrentAPIURL)
            LocalStorage.set("_CurrentAPIURL", _CurrentAPIURL);
        };

        this.SetSmilePreviousURL = (url) => {

            const SmilePreviousURL = {
                PreviousURL: url.SmileServicePreviousUrl
            }
            _SmilePreviousURL = SmilePreviousURL.PreviousURL;
            LocalStorage.set("_SmilePreviousURL", _SmilePreviousURL);
            this.resetSessionTimer();
        };



        //  this.SetPowerBIURL = (url) => {

        //    const PowerBIURL = {
        //        PowerBiURL: url.PowerBIUrl
        //    }
        //    _PowerBIURL = PowerBIURL.PowerBiURL;
        //    LocalStorage.set("_PowerBIURL", _PowerBIURL);
        //  };




        this.ClearStorage = () => {
            _CurrentAPIURL = null;
            _CurrentLoggedInUser = null;
            clearTimeout(this.sessionTimer);
            LocalStorage.clear();

        };

        this.SetClientInstallationLocations = (cliInstallation) => {
            const CurrentClientContact = {
                ContactId: cliInstallation.ContactId,
                SiteId: cliInstallation.SiteId,
                Name: cliInstallation.Name,
                ERPCode: cliInstallation.ERPCode,
                ContactTypeId: cliInstallation.ContactTypeId,
                Remarks: cliInstallation.Remarks,
                ClientId: cliInstallation.ClientId,
                ProjectVersionId: cliInstallation.ProjectVersionId,
                StartDate: cliInstallation.StartDate,
                EndDate: cliInstallation.EndDate,
                AMCTypeId: cliInstallation.AMCTypeId,
                NoOfLicences: cliInstallation.NoOfLicences,
                WebSite: cliInstallation.WebSite,
                Address: cliInstallation.Address,
                Country: cliInstallation.Country,
                State: cliInstallation.State,
                City: cliInstallation.City,
                District: cliInstallation.District,
                PinCode: cliInstallation.PinCode,
                Region: cliInstallation.Region,
                Classification: cliInstallation.Classification,
                FirstContactBy: cliInstallation.FirstContactBy,
                IsSite: cliInstallation.IsSite,
            }
            _ClientInstallationLocations = CurrentClientContact;
            LocalStorage.set("_ClientInstallationLocations", _ClientInstallationLocations);
            console.log("_ClientInstallationLocations", _ClientInstallationLocations)
        };


    }
}
export default new AppCache();