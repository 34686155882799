import { React, useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    Select,
    Menu,
    Fade,
    Tooltip, Box
} from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Icon } from '@iconify/react';
import Moment from 'moment';
import { useSnackbar } from 'notistack';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import {useLocation } from 'react-router-dom';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import Page from '../../Page';
import AppCache from '../../../services/AppCache';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';

import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


export default function ServiceWeightage() {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedRow, setSelectedRow] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const handleDocumentClick = (event) => {
            const gridContainer = document.querySelector('.MuiDataGrid-root'); // get the DataGrid container element
            if (gridContainer && !gridContainer.contains(event.target)) {
                setSelectedRow(null); // deselect the row if the click target is outside the DataGrid
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [setSelectedRow]);




    useEffect(() => {

        setUserId(CurrentLoggedInUser.ExecutiveId);
        const contactId = CurrentLoggedInUser.ExecutiveId;
        const payloadTeam = { ExecutiveLoginId: contactId }
       
        URLS.getTeamNameData(payloadTeam)
            .then((response) => {
                console.log("sadasdassadsadd",response.data);
                setTeamNameDownData(response.data);
                setTeamName("");
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

        const payloadExecutive = { ExecutiveLoginId: contactId };
        //  Gets ExecutiveName Dropdown Data
        URLS.getExecutiveNameData(payloadExecutive)
            .then((response) => {
                console.log(response.data);
                setExecutiveNameDownData(response.data);
                setExecutiveName("");
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

    }, []);



    //  function ActionsCell({ param }) {
    //    const { id, ServiceId, ProductiveStatus, Weightage } = param;
    //    const [selectedProductiveStatus, setSelectedProductiveStatus] = useState(
    //        ProductiveStatus === true ? 'True' : ProductiveStatus === false ? 'False' : 'None'
    //    );
    //    const [selectedWeightValue, setSelectedWeightValue] = useState(Weightage || '0');


    //        const { id } = param.row;
    //        const serviceid = param.row.ServiceId;
    //        const [selectedProductiveStatus, setSelectedProductiveStatus] = useState(
    //            ProductiveStatus === true
    //                ? 'True'
    //                : ProductiveStatus === false
    //                    ? 'False'
    //                    : 'None'
    //        );
    //        const [selectedWeightValue, setSelectedWeightValue] = useState(
    //            Weightage || '0'
    //        );

    //        //const [selectedProductiveStatus, setSelectedProductiveStatus] = useState(params.row.ProductiveStatus === true ? 'True' : (params.row.ProductiveStatus === false ? 'False' : 'None'));
    //        //const [selectedWeightValue, setSelectedWeightValue] = useState(params.row.Weightage || '0');
    //        //   const [selectedServiceIdValue, setselectedServiceIdValue] = useState(params.row.ServiceId);
    //        //    const handleProductStatus = (event) => {
    //        //    const { value } = event.target;
    //        //    setSelectedProductiveStatus(value);
    //        //   };

    //        const handleProductiveStatusChange = (event, id) => {
    //            const { value } = event.target;
    //            let changedWeightagevalue = 0;

    //            setSelectedProductiveStatus(event.target.value);
    //            if (event.target.value === 'None' || event.target.value === 'False') {
    //                setSelectedWeightValue(0);
    //            } else if (event.target.value === 'True') {
    //                setSelectedWeightValue(100);
    //                changedWeightagevalue = 100;
    //            }

    //            const updatedRow = {
    //                ...param.row,
    //                ProductiveStatus: value,
    //                Weightage: changedWeightagevalue
    //            };
    //            // const serviceId = updatedRow.ServiceId;
    //            const updatedRows = ServiceWeightageGridData.map(row => {
    //                if (row.ServiceId === param.row.ServiceId) {
    //                    return updatedRow;
    //                }
    //                return row;
    //            });

    //            setServiceWeightageGridData(updatedRows);
    //        };

    //        const handleGridWeightage = (event) => {
    //            const { value } = event.target;

    //            setSelectedWeightValue(value);

    //            const updatedRow = {
    //                ...param.row,
    //                ProductiveStatus: selectedProductiveStatus,
    //                Weightage: value
    //            };

    //            const updatedRows = ServiceWeightageGridData.map(row => {
    //                if (row.ServiceId === param.row.ServiceId) {
    //                    return updatedRow;
    //                }
    //                return row;
    //            });
    //            setServiceWeightageGridData(updatedRows);
    //        };

    //        const handleClick = (event, id, selectedProductiveStatus, selectedWeightValue, serviceid) => {
    //            const payload = {
    //                ServiceId: serviceid,
    //                IsProductive: selectedProductiveStatus === 'True' ? true : (selectedProductiveStatus === 'False' ? false : null),
    //                Weightage: parseInt(selectedWeightValue),
    //                UserID: userId
    //            };
    //            // Call API with payload
    //            URLS.postServiceWeightage(payload)
    //                .then((response) => {
    //                    console.log(response.data);
    //                })
    //                .catch(error => {
    //                    // On error
    //                    console.log(" Error ");
    //                });
    //        };
    //        // Concatenate the two field values with a comma delimiter
    //        const productiveStatusAndWeightage = `${selectedProductiveStatus},${selectedWeightValue}`;
    //        return (
    //            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
    //                <Box width={100}>
    //                    <FormControl fullWidth>
    //                        <Select
    //                            labelId={`productivestatus-${id}`}
    //                            id={`productivestatus-${id}`}
    //                            value={selectedProductiveStatus}
    //                            onChange={(event) => handleProductiveStatusChange(event, id)}
    //                            label="Productive Status"
    //                        >
    //                            {ProductiveStatusArr.map((status) => (
    //                                <MenuItem key={status.Id} value={status.productiveStatus}>
    //                                    {status.productiveStatus}
    //                                </MenuItem>
    //                            ))}
    //                        </Select>

    //                    </FormControl>
    //                </Box>
    //                <Box flexGrow={1}>
    //                    <TextField
    //                        fullWidth
    //                        labelId={`gridWeightage-${id}`}
    //                        id={`gridWeightage-${id}`}
    //                        type="number"
    //                        onChange={(event) => handleGridWeightage(event, id)}
    //                        value={selectedWeightValue}
    //                        inputProps={{
    //                            min: 0,
    //                            max: 100,
    //                            step: 1,
    //                            pattern: "\\d*",
    //                        }}
    //                    />
    //                </Box>
    //                <Tooltip title="Save">

    //                    <IconButton
    //                        onClick={(event) => {
    //                            handleClick(event, id, selectedProductiveStatus, selectedWeightValue, serviceid);
    //                        }}
    //                    >
    //                        <Iconify icon="ic:baseline-save" color='primary.main' />
    //                    </IconButton>
    //                </Tooltip>
    //            </Stack>


    //        );

    //  }




    const TABLE_HEAD = [
        { field: 'ServiceId', headerName: 'ServiceId', width: 5, hide: 'true' },
        {
            field: 'Date', headerName: 'Date', width: 100, renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.row.Date} 
                </div>
            ),},
        { field: 'IssueId', headerName: 'Issueid', width: 70 },
        {
            field: 'ClientName',
            headerName: 'Company Name',
            width: 220,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.row.ClientName} ({params.row.SiteName})
                </div>
            ),
        }, {
            field: 'ExecutiveName', headerName: 'Executive', width: 180, renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.row.ExecutiveName}
                </div>
            ), },
        { field: 'Duration', headerName: 'Duration', width: 70 },
        { field: 'ServiceRemarks', headerName: 'Service Remarks', width: 300 },
        {
            field: 'actions',
            headerName: 'Productive Status & Weightage',
            width: 250,
            renderCell: (params) => {
                const { id } = params.row;
                const serviceid = params.row.ServiceId;
               

              
                let selectedProductiveStatus = 'None';
                let selectedWeightValue = "0";

                if (params.row.ProductiveStatus === true || params.row.ProductiveStatus === 'True') {
                    selectedProductiveStatus = 'True';
                }
                else if (params.row.ProductiveStatus === false || params.row.ProductiveStatus === 'False' ) {
                    selectedProductiveStatus = 'False';

                }
                else {
                    selectedProductiveStatus = 'None';
                }


                if (params.row.Weightage !== "") {
                    selectedWeightValue = params.row.Weightage;
                }
                

                const handleProductiveStatusChange = (event, id) => {
                    const { value } = event.target;
                    let changedWeightagevalue = 0;

                    selectedProductiveStatus = event.target.value;
                    console.log("selectedProductiveStatus", selectedProductiveStatus);
                    if (event.target.value === 'None' || event.target.value === 'False') {
                        selectedWeightValue = "0";
                    } else if (event.target.value === 'True') {
                        selectedWeightValue = "100";
                        changedWeightagevalue = 100;
                    }

                    const updatedRow = {
                        ...params.row,
                        ProductiveStatus: event.target.value ,
                        Weightage: changedWeightagevalue
                    };
                    // const serviceId = updatedRow.ServiceId;
                    const updatedRows = ServiceWeightageGridData.map(row => {
                        if (row.ServiceId === params.row.ServiceId) {
                            return updatedRow;
                        }
                        return row;
                    });
                    setServiceWeightageGridData(updatedRows);
                };

                const handleGridWeightage = (event) => {
                    const { value } = event.target;
                    if (value === "" || (/^[0-9]{0,3}$/.test(value) && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100)) {
                        selectedWeightValue = value;
                    } else {
                        enqueueSnackbar("Invalid input: value must be less than 100");
                        selectedWeightValue(0);
                    }
                    

                    const updatedRow = {
                        ...params.row,
                        Weightage: value
                    };

                    const updatedRows = ServiceWeightageGridData.map(row => {
                        if (row.ServiceId === params.row.ServiceId) {
                            return updatedRow;
                        }
                        return row;
                    });
                    setServiceWeightageGridData(updatedRows);
                };

                const handleClick = (rowss) => {
                    console.log("event in save click ", rowss);
                    let isProductive = null;
                    if (rowss.ProductiveStatus === 'True' || rowss.ProductiveStatus === true) {
                        isProductive = true;
                    } else if (rowss.ProductiveStatus === 'False' || rowss.ProductiveStatus === false) {
                        isProductive = false;
                    }
                    const payload = {
                        ServiceId: rowss.ServiceId,
                        IsProductive: isProductive,
                        Weightage: rowss.Weightage,
                        UserID: userId
                    };

                    // Call API with payload
                    URLS.postServiceWeightage(payload)
                        .then((response) => {
                            enqueueSnackbar("Record saved successfully", { variant: 'success' });
                        })
                        .catch(error => {
                            // On error
                            handleError(error, enqueueSnackbar);
                        });
                };

                return (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                        <Box width={100}>
                            <FormControl fullWidth>
                                <Select
                                    labelId={`productivestatus-${id}`}
                                    id={`productivestatus-${id}`}
                                    defaultValue={selectedProductiveStatus}
                                    onChange={(event) => handleProductiveStatusChange(event, id)}
                                    label="Productive Status"
                                    MenuProps={{
                                        style: { maxHeight: '200px' } // Adjust the maxHeight and width as desired
                                    }}
                                >
                                    {ProductiveStatusArr.map((status) => (
                                        <MenuItem key={status.Id} value={status.productiveStatus}>
                                            {status.productiveStatus}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </Box>
                        <Box flexGrow={1}>
                            <TextField
                                fullWidth
                                labelId={`gridWeightage-${id}`}
                                id={`gridWeightage-${id}`}
                                type="number"
                                onChange={(event) => handleGridWeightage(event, id)}
                                value={selectedWeightValue}
                                inputProps={{
                                    min: 0,
                                    max: 100,
                                    step: 1,
                                    pattern: "\\d*",
                                }}
                            />
                        </Box>
                        <Tooltip title="Save">

                            <IconButton
                                onClick={() => {
                                    handleClick(params.row);
                                }}
                            >
                                <Iconify icon="ic:baseline-save" color='primary.main' />
                            </IconButton>
                        </Tooltip>
                    </Stack>


                );
            },
            valueGetter: (params) => {
                const { id } = params.row;
                const serviceid = params.row.ServiceId;
                let selectedProductiveStatus = 'None';

                if (params.row.ProductiveStatus === true || params.row.ProductiveStatus === "True") {
                    selectedProductiveStatus = 'True';
                }
                else if (params.row.ProductiveStatus === false || params.row.ProductiveStatus === "False") {
                    selectedProductiveStatus = 'False';

                }
                else {
                    selectedProductiveStatus = 'None';
                }
                const weightValue = params.row.Weightage || '0';
                return `${selectedProductiveStatus} (${weightValue}%)`;
            }
        }



    ];
    const ProductiveStatusArr = [
        {
            Id: 1, productiveStatus: 'None'
        },
        {
            Id: 2, productiveStatus: 'True'
        },
        {
            Id: 3, productiveStatus: 'False'
        },

    ]
    const productiveStatusFilterArr = [
        {
            Id: 1, productiveStatus: 'New'
        },
        {
            Id: 2, productiveStatus: 'All'
        },
        {
            Id: 3, productiveStatus: 'True'
        },
        {
            Id: 4, productiveStatus: 'False'
        },

    ]
    const weightageFilterArr = [
        {
            Id: 1, symbols: '>'
        },
        {
            Id: 2, symbols: '>='
        },
        {
            Id: 3, symbols: '<'
        },
        {
            Id: 4, symbols: '<='
        },
        {
            Id: 5, symbols: '='
        },
        {
            Id: 6, symbols: '<>'
        },
    ]


    const [pageSize, setPageSize] = useState(10);

    const [ServiceWeightageGridData, setServiceWeightageGridData] = useState([]);

    const [teamNameDownData, setTeamNameDownData] = useState([]);

    const [executiveNameDownData, setExecutiveNameDownData] = useState([]);

    const [effectiveFromValue, setEffectiveFromValue] = useState(new Date());

    const [effectiveToValue, setEffectiveToValue] = useState(new Date());

    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [userId, setUserId] = useState([]);

    const [teamName, setTeamName] = useState('');

    const [executiveName, setExecutiveName] = useState('');

    const [filteredProductiveStatus, setFilteredProductiveStatus] = useState("New");

    const [weightageFiltered, setWeightageFiltered] = useState(">=");

    const [weightage, setWeightage] = useState('0');

    const [isLoading, setIsLoading] = useState();

    //  const handleWeightageChange = (event) => {
    //    const inputValue = event.target.value;
    //    if (inputValue === "" || (/^[0-9]{0,2}$/.test(inputValue) && parseInt(inputValue) >= 0 && parseInt(inputValue) <= 100)) {
    //        setWeightage(inputValue);
    //    }
    //    else {
    //        setWeightage('0');
    //    }
    //  };
    const handleWeightageChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue === "" || (/^[0-9]{0,3}$/.test(inputValue) && parseInt(inputValue, 10) >= 0 && parseInt(inputValue, 10) <= 100)) {
            setWeightage(inputValue);
        }
        else {
            enqueueSnackbar("Invalid input: value must be less than 100");

            setWeightage('0');
        }
    };

    const handelSelectedTeamName = (Id) => {
        setTeamName(Id);
        console.log(" deparmentId ", Id);
        const payloadsExecutive = { DepartmentId: Id };
        URLS.getExecutiveNameDepartmentDD(payloadsExecutive)
            .then((response) => {
                console.log(response.data);
                setExecutiveNameDownData(response.data);
                setExecutiveName("");

            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        console.log("id", Id)
    };

    const handelSelectExecutive = (Id) => {
        setTeamName("");
        setUserId(CurrentLoggedInUser.ExecutiveId);
        const contactId = CurrentLoggedInUser.ExecutiveId;
        const payloadExecutive = { ExecutiveLoginId: contactId }
        URLS.getExecutiveNameData(payloadExecutive)
            .then((response) => {
                console.log(response.data);
                setExecutiveNameDownData(response.data);
                setExecutiveName("");
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        const payloadsExecutive = { DepartmentId: Id };
        URLS.getExecutiveNameDepartmentDD(payloadsExecutive)
            .then((response) => {
                console.log(response.data);
                setExecutiveNameDownData(response.data);
                setExecutiveName("");

            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    };

    const handelChangetExecutive = () => {
        setExecutiveName("");

       
    };


    const handelSelectedExecutiveName = (Id) => {
        setExecutiveName(Id);
        console.log("id", Id)
    };

    const handelSelectedProductiveStatus = (productiveStatus) => {
        setFilteredProductiveStatus(productiveStatus);
        console.log("productiveStatus", productiveStatus)
    };
    const handelSelectedWeightage = (symbols) => {
        setWeightageFiltered(symbols);
        // setExecutiveName("");

        console.log("symbols", symbols)
    };

    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue);
        setEffectiveToValue(newValue);

    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };

    const onResetClick = () => {
        // handelSelectExecutive();
        setTeamName("");
        setExecutiveName("");
        setServiceWeightageGridData([]);
        setEffectiveFromValue(new Date());
        setEffectiveToValue(new Date());
        setFilteredProductiveStatus("New");
        setWeightageFiltered(">=");
        setWeightage('0');
    };
    
    const handleSaveAll = () => {
        setIsLoading(true);
        const payload = [];
        ServiceWeightageGridData.forEach(row => {
            const serviceId = row.ServiceId;
            //  const isProductive = row.ProductiveStatus === 'True' ? true : (row.ProductiveStatus === 'False' ? false : null);
            let isProductive = 'None';
            if (row.ProductiveStatus === 'True' || row.ProductiveStatus === true) {
                isProductive = true;
            } else if (row.ProductiveStatus === 'False' || row.ProductiveStatus === false) {
                isProductive = false;
            }

            const weightage = row.Weightage;
            payload.push({ ServiceId: serviceId, IsProductive: isProductive, Weightage: weightage, UserID: userId });
        });

        // Call API with payload
        URLS.postServiceWeightageAll(payload)
            .then((response) => {
                setIsLoading(false);
                enqueueSnackbar("Record saved successfully", { variant: 'success' });

            })
            .catch(error => {
                // On error
               console.log("err")
            });
    };

    const onFilterClick = () => {
        setServiceWeightageGridData([]);
        setIsLoading(true);
        const executiveNamevalue = executiveName;
        if (!teamNameDownData.length && !executiveNameDownData.length) {
            enqueueSnackbar('No Data Found', { variant: 'error' });
            setIsLoading(false);
        }
 else {


            const payload = {
                TeamNameId: teamName,
                ExecutiveId: executiveNamevalue,
                ProductiveStatus: filteredProductiveStatus,
                FromDate: effectiveFromValue,
                ToDate: effectiveToValue,
                WeightageSymbol: weightageFiltered,
                Weightage: weightage,
                UserID: userId
            };

            URLS.postFilterServiceWeightage(payload)
                .then((response) => {
                    console.log("postFilterServiceWeightage Response", response.data);
                    setServiceWeightageGridData(response.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    // On error
                    console.log(" Error ");
                    setIsLoading(false);

                });
        };
    };

    const handleRowClick = (params) => {
        // Check if the clicked row is already selected
        if (selectedRow && selectedRow.id === params.row.id) {
            // Deselect the row if it's already selected
            setSelectedRow(null);
        } else {
            // Otherwise, select the clicked row
            setSelectedRow(params.row);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const fileExtention = '.xlsx';

    const FileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(ServiceWeightageGridData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: FileType });
        FileSaver.saveAs(data, `ServiceWeightageGridData{fileExtention}`);

    }
    useEffect(() => {
        setTeamName("");
        setExecutiveName("");
        setServiceWeightageGridData([]);
        setEffectiveFromValue(new Date());
        setEffectiveToValue(new Date());
        setFilteredProductiveStatus("New");
        setWeightageFiltered(">=");
        setWeightage('0');
    }, [location.key]); 

    return (
        <Page title="ServiceWeightage" >
            <Card >
                <Stack direction={{ xs: "row", md: "column" }} alignItems="flex-start" justifyContent='flex-start'   >
                    <Stack direction={{ xs: "column", md: "row" }} alignItems="flex-start" justifyContent="space-between" m={1} spacing={3}>
                        <FormControl size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}  >
                            <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Team Name</InputLabel>
                            <Select
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                                label="Team Name"
                                size="small"
                                value={teamName}>
                                <MenuItem key={0} value={""} onClick={() => handelSelectExecutive()}>Select Department</MenuItem>
                                {teamNameDownData.map((component) => (
                                    <MenuItem key={component.Id} value={component.Id} onClick={() => handelSelectedTeamName(component.Id)}> {component.TeamName}</MenuItem>
                                ))}

                            </Select>

                        </FormControl>

                        <FormControl size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}  >
                            <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Executives Name</InputLabel>
                            <Select
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                                label="Executive Name"
                                size="small"
                                value={executiveName}  >
                                <MenuItem key={0} value={""} onClick={() => handelChangetExecutive()}>Select Executive</MenuItem>
                                {executiveNameDownData.map((component) => (
                                    <MenuItem key={component.Id} value={component.Id} onClick={() => handelSelectedExecutiveName(component.Id)}> {component.Name}</MenuItem>
                                ))}

                            </Select>

                        </FormControl>


                        <FormControl size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}  >
                            <InputLabel sx={{ fontSize: '0.8rem' }}>Productive Status</InputLabel>
                            <Select MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                                label="Productive Status"
                                size="small"
                                value={filteredProductiveStatus}  >

                                {productiveStatusFilterArr.map((component) => (
                                    <MenuItem key={component.Id} value={component.productiveStatus} onClick={() => handelSelectedProductiveStatus(component.productiveStatus)}> {component.productiveStatus}</MenuItem>
                                ))}

                            </Select>

                        </FormControl>

                        <Box width={100} sx={{ position: 'relative' }}>
                            <FormControl size="small" sx={{ minWidth: 300, fontSize: '0.5rem' }} >

                                <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={1} >
                                    <Select
                                       MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                                        value={weightageFiltered}>
                                        {weightageFilterArr.map((component) => (
                                            <MenuItem key={component.Id} value={component.symbols} onClick={() => handelSelectedWeightage(component.symbols)} >
                                                {component.symbols}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        size="small"
                                        type="number"
                                        value={weightage}
                                        onChange={handleWeightageChange}
                                        inputProps={{
                                            min: 0,
                                            max: 100,
                                            step: 1,
                                            pattern: "\\d*",
                                        }}
                                    />

                                </Stack>
                            </FormControl>
                            <InputLabel sx={{ position: 'absolute', left: '60%', bottom: '30px', transform: 'translateX(-50%)', fontSize: '12px' }}>
                                Weightage
                            </InputLabel>
                        </Box>
                    </Stack>
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" alignItems="flex-start" m={1} spacing={3} flexGrow={1} >
                    <Stack direction={{ xs: "column", md: "row" }} spacing={3} >
                        <FormControl size="small" sx={{ minWidth: 300, fontSize: '0.5rem' }}   >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Start Date"
                                    inputFormat="dd/MMM/yyyy"
                                    value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                                    onChange={handleEffFrom}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl size="small" sx={{ minWidth: 300, fontSize: '0.5rem' }}  >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="End Date"
                                    inputFormat="dd/MMM/yyyy"
                                    value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                                    onChange={handleEffTo}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Stack>

                    <Stack direction="row" justifyContent="flex-start" m={1} spacing={3}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Tooltip title="Filter">
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={onFilterClick}
                                ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                            </Tooltip >
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Tooltip title="Reset">
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={onResetClick}
                                ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                            </Tooltip >
                        </Stack>

                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Tooltip title="Save All">
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleSaveAll}
                                >
                                    <Icon icon="ic:round-save-all" width="25px" height="25px" />
                                </Button>
                            </Tooltip >
                        </Stack>

                        <Tooltip title="Export">
                            <IconButton id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick} sx={{ marginBottom: 1 }} >
                                <Icon height="15px" icon="ic:baseline-density-small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={handleClose}>  <Button sx={{ color: 'black' }} size="small" onClick={exportToExcel}>Export to excel</Button></MenuItem>


                        </Menu>
                    </Stack>

                </Stack>
                <Divider />
                {/*  -----------------------------------------------------------------  */}
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid rows={ServiceWeightageGridData} density={ 'compact'}  columns={TABLE_HEAD} disableSelectionOnClick onRowClick={handleRowClick} selectionModel={selectedRow ? [selectedRow.id] : []} pageSize={pageSize} onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20]} getRowId={(row) => ServiceWeightageGridData.indexOf(row)} components={{
                            Toolbar: GridToolbar,
                        }} />
                </div>
            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}

        </Page>
    );
}