import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import URLS from '../../../../services/urls.service';
import { UserListHead, UserMoreMenu } from '../../../../sections/@dashboard/user';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.projectServerId.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function ProjectServerDrives() {

    const TABLE_HEAD = [

        { field: 'ProjectServerID', headerName: 'Project Server Id', width: 250 },
        { field: 'DriveName', headerName: 'Drive Name', width: 250 },
        { field: 'TotalSpace', headerName: 'Total Space', width: 250 },
        { field: 'FreeSpace', headerName: 'Free Space', width: 250 },
        { field: 'InsertedAT', headerName: 'Inserted AT', width: 100 },
    ];
    const [pageSize, setPageSize] = useState(10);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('projectServerId');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

   

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = drivesData.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    const [drivesData, setDrivesData] = useState([]);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - drivesData.length) : 0;

    const filteredUsers = applySortFilter(drivesData, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    useEffect(() => {




        // APIUrl calls
        URLS.getProjectServerDrives().then((response) => {
            if (response.status === 200) {

                console.log("serverDrivesData", response);
                setDrivesData(response.data);
                // AppCache.SetCurrentAPIURL(config.SmileServiceApiBaseUrl);


            }
            else {
                // Failure response
                alert("Api URL  Failed");
            }
        })

    }, []);

    return (
        <Page title="Project informatio details">
            <Card>
                <div style={{ height: '63vh', width: '100%' }}>
                    <DataGrid rows={drivesData} pageSize={pageSize} onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 30]} columns={TABLE_HEAD} getRowId={(row) => drivesData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>
            </Card>
        </Page>
    );
}
