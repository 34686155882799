import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import ProductLinkingDetailsComponent from "../../../components/SidebarMenuComponents/OAComponent/ProductLinkingDetailsComponent";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function ProductLinkingDetailsPage() {
    const location = useLocation();
    const { productLinking } = location.state;
    return (
        <Page title="  Product Linking Details| tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Product Linking Details
            </Typography>

            <ContentStyle>
                <ProductLinkingDetailsComponent productLinking={productLinking}/>
            </ContentStyle>
        </Page>
    );
}

