import { React, useEffect, useState,useRef } from 'react';
import { Grid, Container, Typography, Paper, useTheme, useMediaQuery, Stack, Box, Modal, Tooltip, TextField, Button, MenuItem, Select, TextareaAutosize, IconButton, Menu, Fade, Divider, InputLabel, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import { CSVLink } from 'react-csv';
// urls
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useSnackbar } from 'notistack';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { useLocation } from 'react-router-dom';
import Iconify from '../../../Iconify';
import URLS from '../../../../services/urls.service';
import AppCache from '../../../../services/AppCache';
import { handleError } from '../../../Common/Utils';


const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 10,
    p: 2,
};

export default function ServiceEntry() {


   

    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [Hite, setHite] = useState()

    // Section Client

    const TABLE_HEAD = [
        {
            field: 'Client', headerName: 'Client', width: 170, renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.row.Client} ({params.row.TotalTimeSpentInMin})
                </div>
            ),},
    ];
    const TABLE_HEAD2 = [
        {
            field: 'Activity',
            headerName: 'Activity',
            width: 170,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.row.Activity} ({params.row.TotalTimeSpentInMin})
                </div>
            ),
        },
    ];
    // Section service

    const TABLE_HEAD1 = [

        { field: 'ServiceId', headerName: 'Service Id', width: 100 },
        { field: 'Client', headerName: 'Client', width: 200 },
        { field: 'SiteName', headerName: 'Site', width: 200 },
        { field: 'ProjectVersion', headerName: 'Project', width: 200 },
        { field: 'Activity', headerName: 'Activity ', width: 100 },
        { field: 'TotalTimeSpentInMin', headerName: 'Service In Min', width: 110 },
        { field: 'ServiceRemark', headerName: 'Service Remark ', width: 200 },


        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick1(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },

    ];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [serviceid, setServiceId] = useState('');
    const [client, setClient] = useState('');
    const [clientName, setClientName] = useState('');
    const [clientDropdown, setClientDropdown] = useState([]);
    const [site, setSite] = useState();
    const [siteDropdown, setSiteDropdown] = useState([]);
    const [project, setProject] = useState('');
    const [projectDropdown, setProjectDropdown] = useState([]);
    const [activity, setActivity] = useState('');
    const [activityName, setActivityName] = useState();
    const [activityDropdown, setActivityDropdown] = useState([]);
    const [startTime, setStartTime] = useState(new Date());
    const [serviceInMin, setServiceInMin] = useState(0);
    const [serviceRemark, setServiceRemark] = useState();
    const [secAdate, setSecADate] = useState(new Date());
    const [dateError, setDateError] = useState(false);
    const [serviceCount, setServiceCount] = useState(0);
    const [totalServiceTime, setTotalServiceTime] = useState("00:00");
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    const [IssueId, setIssueId] = useState();
    console.log("Current selecteed Date:-", secAdate);
    console.log("StartTime when page loads:-", startTime);
    const [sectionCDatagrid, setSectionCDatagrid] = useState([]);
    const [activityDatagrid, setActivityDatagrid] = useState([]);
    const [sectionDDatagrid, setSectionDDatagrid] = useState([]);
    const [hideAll, setHideAll] = useState(false);
    const [totalserviceCount, setTotalServiceCount] = useState();
    const [totalServiceMinutes, setTotalServiceMinutes] = useState();
    const hours = Math.floor(totalServiceMinutes / 60);
    const minutes = totalServiceMinutes % 60;
    const location = useLocation();

    // Change Date button validation
    const [isDisable, setIsDisable] = useState(false);

    // Change Date popover state
    const [isShow, setIsShow] = useState(false);

    const [open1, setOpen1] = useState(false);
    const handleOpen = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const handelCancel1 = () => {
        setIsDisable(false);
        setProject("");
        setActivity("");
        setClient("");
        setSite("");
        setServiceId("");
        handleClose1();
        setServiceRemark("");
        setHideAll(false);
    }
    const [selected, setSelected] = useState(false);
    // Edit button 
    const handleCheckboxChange = (event) => {
        setSelected(event.target.checked);
    };

    const handleClick1 = (event, cellValues) => {
        setProject(cellValues.row.ProjectVersionId);
        setActivity(cellValues.row.ActivityId);
        setClient(cellValues.row.ClientId);
        setSite(cellValues.row.SiteId);
        setServiceRemark(cellValues.row.ServiceRemark);
        setStartTime(Moment(cellValues.row.StartDate).format("HH:mm"));
        setServiceInMin(cellValues.row.TotalTimeSpentInMin);
        setServiceId(cellValues.row.ServiceId);
        console.log("cellValues.row.SiteId", cellValues.row);
    }


    const serviceInMinRef = useRef(null);
    const [showMinBorder, setShowMinBorder] = useState(false);
    const handleChange = (event) => {
        setServiceInMin(event.target.value);
        console.log("setServiceInMin console of Service in min", event.target.value)
    };

    const handleServiceInMinBlur = (event) => {
        const inputMinutes = event.target.value;
        console.log("inputMinutes", inputMinutes);
        const [startHour, startMinute] = startTime.split(':').map(num => (num));
        const startTimeObj = new Date();
        startTimeObj.setHours(startHour, startMinute, 0);

        const currentDate = new Date().toLocaleDateString();
        const selectedDate = secAdate.toLocaleDateString();

        if (selectedDate !== currentDate) {
            // skip validation if selected date is not the same as the current date
            setServiceInMin(inputMinutes);
            console.log("inputMinutes after set", inputMinutes);
            setShowMinBorder(false);
            return;
        }

        const endTime = Moment(startTimeObj).add(inputMinutes, 'minutes');
        const currentTime = new Date();
        console.log("EndTime to calculate starttime  in minutes", endTime)
        if (endTime < currentTime) {
            // valid input, set service duration in minutes
            setServiceInMin(inputMinutes);
            console.log("setServiceInMin after endtime set", inputMinutes);
            setShowMinBorder(false);
        } else {
            // invalid input, show error message
            setShowMinBorder(true);
            enqueueSnackbar("Invalid input. The service duration cannot be greater than the current time.");
            if (serviceInMinRef.current) {
                serviceInMinRef.current.querySelector('input').focus(); // Set focus on the text field
            }
        }
    };
    const fileExtention = '.xlsx';

    const FileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const exportToExcel = async () => {
        const filteredData = sectionCDatagrid.map((entry) => {
            const filteredEntry = {};
            TABLE_HEAD.forEach((column) => {
                if (column.field === 'Client') {
                    filteredEntry[column.field] = `${entry[column.field]} (${entry.TotalTimeSpentInMin})`;
                } else {
                    filteredEntry[column.field] = entry[column.field];
                }
            });
            return filteredEntry;
        });
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: FileType });
        FileSaver.saveAs(data, `ClientData{fileExtention}`);
    };

    // Export to Activity
    const exportToExcelActivity = async () => {
        const filteredData = activityDatagrid.map((entry) => {
            const filteredEntry = {};
            TABLE_HEAD2.forEach((column) => {
                if (column.field === 'Activity') {
                    filteredEntry[column.field] = `${entry[column.field]} (${entry.TotalTimeSpentInMin})`;
                } else {
                    filteredEntry[column.field] = entry[column.field];
                }
            });
            return filteredEntry;
        });


        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: FileType });
        FileSaver.saveAs(data, `ActivityData{fileExtention}`);
    };
    const exportToExcelService = async () => {
        const filteredData = sectionDDatagrid.map((entry) => {
            const filteredEntry = {};
            TABLE_HEAD1.forEach((column) => {
                // Exclude 'actions' field from the exported data
                if (column.field !== 'actions') {
                    filteredEntry[column.field] = entry[column.field];
                }
            });
            return filteredEntry;
        });

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: FileType });
        FileSaver.saveAs(data, `ServiceData{fileExtention}`);
    };
    const handleRemarkChange = (e) => {
        setServiceRemark(e.target.value)
    }

    const handleSelectedSiteName = (Id) => {
        setSite(Id);
        console.log("id", Id)
    };
    const handleSelectedActivity = (id, name) => {

        console.log("name", name);
        setActivity(id);
        setActivityName(name);
    };
    const handleSelectedProject = (Id) => {
        setProject(Id);
        console.log("id", Id)
    };

    const handleDate = (newValue) => {
        if (!dateError && newValue !== '01/Jan/1900') {
            setSecADate(newValue);
        }
        setDateError(false);
    };



    const validateDate = (dateString) => {
        const regex = /^\d{2}\/[A-Za-z]{3}\/\d{4}$/;
        return regex.test(dateString);
    };

    const handleInputChange = (event, newValue) => {
        const { value } = event.target;
        const isValidDate = validateDate(value);
        console.log("In Handle InputChange")
        if (!isValidDate) {
            setDateError(true);
        } else {
            setDateError(false);
            setSecADate(value); // Update with the input value, not the newValue parameter
        }
    };



    const handleServiceSubmit = (event) => {
        event.preventDefault();
        // Handle service form submission here
    };

    const [pageSize, setPageSize] = useState(25);
    const [pageSize1, setPageSize1] = useState(10);

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRow1, setSelectedRow1] = useState(null);
    const [sectionCGridData, setSectionCGridData] = useState([]);

    const CustomPagination = (props) => {
        const { pagination, onPageChange, serviceid } = props; // Destructure the necessary props

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span style={{ marginRight: '40px', textAlign: 'left' }}>Issue ID: {IssueId}</span>
                <GridPagination {...pagination} onPageChange={onPageChange}   />
            </div>
        );
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            const gridContainer = document.querySelector('.MuiDataGrid-root'); // get the DataGrid container element
            if (gridContainer && !gridContainer.contains(event.target)) {
                setSelectedRow(null); // deselect the row if the click target is outside the DataGrid
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [setSelectedRow]);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);

        //  Gets TeamNam Dropdown Data
        const payloadsClientId = { ClientId: "0" };
        URLS.getServiceEntrySiteName(payloadsClientId)
            .then((response) => {
                setSiteDropdown(response.data);
                setSite("");
            })
            .catch(error => {
                // On error
                console.log(" Error ");
                setSite("");
            });


        //  Gets ClientName Dropdown Data
        URLS.getServiceEntryClientName()
            .then((response) => {
                setClientDropdown(response.data);
                setClient("");
            })
            .catch(error => {
                // On error
                setClient("");
                console.log(" Error ");
            });

        //  Gets Project Dropdown Data
        URLS.getServiceEntryProject()
            .then((response) => {
                setProjectDropdown(response.data);
                setProject("");
            })
            .catch(error => {
                // On error
                setProject("");
                console.log(" Error ");
            });

        //  Gets Activity Dropdown Data
        URLS.getServiceActivityType()
            .then((response) => {
                setActivityDropdown(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

        //  Gets ClientName Dropdown Data
        URLS.getDefaultIssueId()
            .then((response) => {
                setIssueId(response.data);
            })
            .catch(error => {
                // On error
                setClient("");
                console.log(" Error ");
            });

    }, []);

    const handleSaveAndOtherFunction = () => {
        handleSave();
        handleFillService();
    }

    // Save button Api
    const handleSave = () => {
        const payload = {
            ClientId: client,
            SiteId: site,
            ProjectId: project,
            ActivityId: activity,
            Date: secAdate,
            StartTime: startTime,
            ServiceRemark: serviceRemark,
            ServiceInMinute: serviceInMin,
            UserID: userId
        };
        if (client.length === 0) {
            // Display an error message or perform any validation action
            enqueueSnackbar("Please select Client");

            // You can disable the generate button or show an error message to the user
        } else if (site.length === 0) {
            enqueueSnackbar("Please select Site");
        } else if (project.length === 0) {
            enqueueSnackbar("Please select Project");
        } else if (activity.length === 0) {
            enqueueSnackbar("Please select Activity");
        } else if (serviceInMin.length === 0) {
            enqueueSnackbar("Please select serviceInMin");
        } else {


            // Call API with payload
            URLS.postQuickServiceEntry(payload)
                .then((response) => {
                    enqueueSnackbar("Record saved successfully", { variant: 'success' });
                    const fillservicePayload = {
                        StartTime: secAdate,
                        UserID: userId
                    };
                    // call section C
                    URLS.getFilteredServicEntrySectionC(fillservicePayload)
                        .then((response) => {
                            setSectionCDatagrid(response.data);
                            handleFillService();

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });

                    URLS.getFilteredServicEntrySectionD(fillservicePayload)
                        .then((response) => {
                            setSectionDDatagrid(response.data);
                            handleFillService();

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });
                    URLS.getActivityDataGrid(fillservicePayload)
                        .then((response) => {
                            setActivityDatagrid(response.data);
                            handleFillService();

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });
                    URLS.getServiceEntryTotalServiceCount(fillservicePayload)
                        .then((response) => {
                            setTotalServiceCount(response.data);

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });
                    URLS.getServiceEntryTotalMinutes(fillservicePayload)
                        .then((response) => {
                            setTotalServiceMinutes(response.data);

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });
                    if (selected) {
                        setServiceRemark('');
                        setServiceInMin('');

                    } else {
                        setProject('');
                        setActivity('');
                        setClient('');
                        setSite('');
                        setServiceId('');
                        setServiceRemark('');
                        setServiceInMin('');

                    }
                    console.log(response.data);
                })
                .catch(error => {
                    // On error
                    handleError(error, enqueueSnackbar);
                });

        }
    };
    useEffect(() => {
        if (!selected) {
            setProject("");
            setActivity("");
            setClient("");
            setSite("");
            setServiceId("");
            setServiceRemark("");
            setServiceInMin('');
        }
    }, [selected]);


    // Update Button Api

    const handleUpdate = () => {
        const payload = {
            ServiceId: serviceid,
            ClientId: client,
            SiteId: site,
            ProjectId: project,
            Date: secAdate,
            ActivityId: activity,
            StartTime: startTime,
            ServiceRemark: serviceRemark,
            ServiceInMinute: serviceInMin,
            UserID: userId
        };
        if (client.length === 0) {
            // Display an error message or perform any validation action
            enqueueSnackbar("Please select Client");

            // You can disable the generate button or show an error message to the user
        } else if (site.length === 0) {
            enqueueSnackbar("Please select Site");
        } else if (project.length === 0) {
            enqueueSnackbar("Please select Project");
        } else if (activity.length === 0) {
            enqueueSnackbar("Please select Activity");
        } else if (serviceInMin.length === 0) {
            enqueueSnackbar("Please select serviceInMin");
        } else {
            // Call API with payload
            URLS.updateQuickServiceEntry(payload)
                .then((response) => {
                    enqueueSnackbar("Record saved successfully", { variant: 'success' });
                    const fillservicePayload = {
                        StartTime: secAdate,
                        UserID: userId
                    };

                    // call section C
                    URLS.getFilteredServicEntrySectionC(fillservicePayload)
                        .then((response) => {
                            console.log("jkskkfjjfofk", response);
                            setSectionCDatagrid(response.data);
                            handleFillService();

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });
                    URLS.getActivityDataGrid(fillservicePayload)
                        .then((response) => {
                            console.log("sddsdd", response);
                            setActivityDatagrid(response.data);
                            handleFillService();

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });
                    URLS.getFilteredServicEntrySectionD(fillservicePayload)
                        .then((response) => {
                            console.log("sddsdd", response);
                            setSectionDDatagrid(response.data);
                            handleFillService();

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });
                   
                    URLS.getServiceEntryTotalServiceCount(fillservicePayload)
                        .then((response) => {
                            console.log("wwwe", response);
                            setTotalServiceCount(response.data);

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });
                    URLS.getServiceEntryTotalMinutes(fillservicePayload)
                        .then((response) => {
                            console.log("wwwe", response);
                            setTotalServiceMinutes(response.data);

                        })
                        .catch(error => {
                            // On error
                            console.log("error")
                        });
                    console.log(response.data);
                })
                .catch(error => {
                    // On error
                    console.log("error")
                });

          
            setProject("");
            setActivity("");
            setClient("");
            setSite("");
            setServiceId("");
            // Call section D
            // 
        }
    };

    // FillService Button Api

    const handleFillService = () => {

        if (dateError) {
            setHideAll(false);
        } else {

            setHideAll(true);
            setIsDisable(true)

            const fillservicePayload = {
                StartTime: secAdate,
                UserID: userId
            };

            // call section C
            URLS.getFilteredServicEntrySectionC(fillservicePayload)
                .then((response) => {
                    setSectionCDatagrid(response.data);


                })
                .catch(error => {
                    // On error
                    console.log("error")
                });
             URLS.getActivityDataGrid(fillservicePayload)
                .then((response) => {
                    setActivityDatagrid(response.data);

                })
                .catch(error => {
                    // On error
                    console.log("error")
                });
            URLS.getFilteredServicEntrySectionD(fillservicePayload)
                .then((response) => {
                    setSectionDDatagrid(response.data);
                    const endTime = Moment(response.data[0].StartDate).add(response.data[0].TotalTimeSpentInMin, 'minutes').format('HH:mm');

                    setStartTime(endTime);

                })
                .catch(error => {
                    // On error
                    console.log("error")
                });

            URLS.getServiceEntryTotalServiceCount(fillservicePayload)
                .then((response) => {
                    setTotalServiceCount(response.data);

                })
                .catch(error => {
                    // On error
                    console.log("error")
                });
            URLS.getServiceEntryTotalMinutes(fillservicePayload)
                .then((response) => {
                    setTotalServiceMinutes(response.data);

                })
                .catch(error => {
                    // On error
                    console.log("error")
                });
            // setProject("");
            // setActivity("");
            // setClient("");
            // setSite("");
            // setStartTime("");
            // setServiceInMin("");
            // setServiceRemark("");
            // setServiceId("");
            // Call section D
        }
    };
    // Change Date popover
    const handleChangeDate = () => {
        if (isShow === false) {
            handleOpen();
        } else {
            setIsDisable(true);
        }
    }

    const handleSelectedClientName = (Id) => {
        setClient(Id);
        console.log("id", Id)
        //  Gets Site Dropdown Data
        const payloadsClientId = { ClientId: Id };
        URLS.getServiceEntrySiteName(payloadsClientId)
            .then((response) => {
                setSiteDropdown(response.data);
                setSite("");
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

    };

    const SelectClientName = () => {
        setClient("");
        const payloadsClientId = { ClientId: "0" };
        URLS.getServiceEntrySiteName(payloadsClientId)
            .then((response) => {
                setSiteDropdown(response.data);
                setSite("");
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

    };


    const SelectSiteName = () => {
        setSite("");


    };

    const SelectProjectName = () => {
        setProject("");


    };



    const handleRowClick = (params) => {
        // Check if the clicked row is already selected
        if (selectedRow && selectedRow.id === params.row.id) {
            // Deselect the row if it's already selected
            setSelectedRow(null);
        } else {
            // Otherwise, select the clicked row
            setSelectedRow(params.row);
        }
    };
    const handleRowClick1 = (params) => {
        // Check if the clicked row is already selected
        if (selectedRow1 && selectedRow1.id === params.row.id) {
            // Deselect the row if it's already selected
            setSelectedRow1(null);
        } else {
            // Otherwise, select the clicked row
            setSelectedRow1(params.row);
        }
    };

    // CSV DATA
    const getCsvData = () => {
        const header = TABLE_HEAD.map((column) => column.headerName);
        const rowsFormatted = sectionCDatagrid.map((row) =>
            TABLE_HEAD.map((column) => row[column.field])
        );
        return [header, ...rowsFormatted];
    };

    const getCsvData1 = () => {
        const header1 = TABLE_HEAD1.map((column) => column.headerName);
        const rowsFormatted1 = sectionDDatagrid.map((row) =>
            TABLE_HEAD1.map((column) => row[column.field])
        );
        return [header1, ...rowsFormatted1];
    };
    const [showRedBorder, setShowRedBorder] = useState(false);
    const startTimeRef = useRef(null);

    useEffect(() => {
        setHideAll(false)
    },[location.key])

    return (
        <Grid container spacing={2} >

            {/* Sectioin 1 --------------------------------------------- */}

            <Grid item xs={9} sm={6} md={2}>
                <FormControl size="small" fullWidth error={dateError}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            disabled={isDisable}
                            label="Date"
                            inputFormat="dd/MMM/yyyy"
                            value={secAdate}
                            onChange={(newValue) => handleDate(newValue)}
                            disableFuture
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    error={dateError}
                                    helperText={dateError ? 'Invalid date format' : ''}
                                    onChange={(event) => handleInputChange(event)}
                                    onBlur={(event) => handleInputChange(event)}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </FormControl>

               


            </Grid>
            <Grid item xs={3} sm={6} md={4}>

                {isDisable ? <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleChangeDate}
                    sx={{ fontSize: '12px' }}
                >
                    Change Date
                </Button> :
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleFillService}
                        sx={{ fontSize: '12px' }}
                    >
                        Fill Service
                    </Button>}
                <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...style }}>


                        <Typography id="modal-modal-title" variant="subtitle2" component="h2" textAlign="center">
                            Your entry not yet saved, Do you want to discard?
                        </Typography>
                        <Stack direction="row" mt={2} spacing={2}>
                            <Button
                                sx={{ color: 'white' }}
                                onClick={handelCancel1}
                                size="small"
                                variant="contained"
                                color="primary"
                            >
                                Yes
                            </Button>
                            <Button
                                sx={{ color: 'white' }}
                                onClick={handleClose1}
                                size="small"
                                variant="contained"
                                color="primary"
                            >
                                No
                            </Button>
                        </Stack>
                    </Box>

                </Modal>



            </Grid>
            {hideAll ?

                <>

                    <Grid item xs={5} md={2}>
                        <Typography variant="subtitle1">
                            Total Service Count: {totalserviceCount}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} md={3}>
                        <Typography variant="subtitle1">
                            {/* Total Service In Min: {totalServiceMinutes} */}
                            Total Service : {hours} Hr {minutes} Min
                        </Typography>

                    </Grid>

                    <Grid item xs={2} md={1}>
                        <Tooltip title="Export">
                            <IconButton id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick} sx={{ marginBottom: 1 }} >
                                <Icon height="15px" icon="ic:baseline-density-small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={handleClose}>  <Button sx={{ color: 'black' }} size="small" onClick={exportToExcel}>Export Client</Button></MenuItem>
                            <MenuItem onClick={handleClose}>  <Button sx={{ color: 'black' }} size="small" onClick={exportToExcelActivity}>Export Activity</Button></MenuItem>
                            <MenuItem onClick={handleClose}>  <Button sx={{ color: 'black' }} size="small" onClick={exportToExcelService}>Export Service</Button></MenuItem>

                        </Menu>
                    </Grid>

                    <Divider flexItem />

                    {/* Section 2 --------------------------------------------- */}

                    <Grid item xs={12} sm={7}>
                        <Paper style={{ height: '100%', width: '100%' }}>
                            <Grid  container spacing={2} style={{ height: '60%' }}>
                                {/*  <Grid item xs={12}> */}
                                {/*    <Typography variant="h5">Quick Service Entry</Typography>  */}
                                {/*  </Grid>  */}

                                <Grid item xs={12} sm={6}>
                                    <FormControl size="small" fullWidth  >
                                        <InputLabel sx={{ fontSize: '0.8rem' }}>Clients Name</InputLabel>
                                        <Select
                                           
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#fbe9e7', // Set the background color here
                                                        maxHeight: '200px',
                                                        width: '200px',// Set the max height here
                                                    }
                                                }
                                            }}
                                            label="Client Name"
                                            value={client}  >
                                            <MenuItem key={0} value={""} onClick={() => SelectClientName()} >Select ClientName</MenuItem>

                                            {clientDropdown.map((component) => (
                                                <MenuItem key={component.ClientId} value={component.ClientId} onClick={() => handleSelectedClientName(component.ClientId, component.Name)}> {component.Name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid  item xs={12} sm={6}>
                                    <FormControl size="small" fullWidth  >
                                        <InputLabel >Sites Name</InputLabel>
                                        <Select
                                            
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#fbe9e7', // Set the background color here
                                                        maxHeight: '200px',
                                                        width: '200px',// Set the max height here
                                                    }
                                                }
                                            }}
                                            label="Site Name"
                                            value={site}  >
                                            <MenuItem key={0} value={""} onClick={() => SelectSiteName()}>Select SiteName</MenuItem>
                                            {siteDropdown.map((component) => (
                                                <MenuItem key={component.SiteId} value={component.SiteId} onClick={() => handleSelectedSiteName(component.SiteId)}> {component.Name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <FormControl size="small" fullWidth  >
                                        <InputLabel> Project </InputLabel>
                                        <Select
                                           
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#fbe9e7', // Set the background color here
                                                        maxHeight: '200px',
                                                        width: '200px',// Set the max height here
                                                    }
                                                }
                                            }}
                                            label="Project"
                                            value={project}  >
                                            <MenuItem key={0} value={""} onClick={() => SelectProjectName()}>Select Project</MenuItem>

                                            {projectDropdown.map((component) => (
                                                <MenuItem key={component.ProjectVersionId} value={component.ProjectVersionId} onClick={() => handleSelectedProject(component.ProjectVersionId)}> {component.ProjectVersionName}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl   size="small" fullWidth  >
                                        <InputLabel >Activity Type</InputLabel>
                                        <Select
                                            
                                            
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#fbe9e7', // Set the background color here
                                                        maxHeight: '200px',
                                                        width: '200px',// Set the max height here
                                                    }
                                                }
                                            }}
                                            label="Activity Type"
                                            value={activity} onChange={(event) => setActivity(event.target.value)}  >
                                            <MenuItem key={0} value={""}>Select Activity</MenuItem>
                                            {activityDropdown.map((component) => (
                                                <MenuItem key={component.Id} value={component.Id} onClick={() => handleSelectedActivity(component.Id, component.Name)}> {component.Name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    
                                    <TextField
                                        size="small"
                                        label="Start Time"
                                        type="time"
                                        value={startTime}
                                        ref={startTimeRef}
                                        style={{
                                            border: showRedBorder ? '1px solid red' : '',
                                            borderRadius: showRedBorder ? '10px' : '' // Set to '20px' for red border, '0' for gray border
                                        }}
                                        onChange={(event) => {
                                            setStartTime(event.target.value);
                                        }}
                                        onBlur={(event) => {
                                            const selectedTime = event.target.value;
                                            console.log("selectedTime", selectedTime);
                                            //  const selectedDateTime = new Date(`${secAdate.toLocaleDateString()} ${selectedTime}`);


                                            const formattedDate = `${Moment(secAdate).format('DD-MMM-YYYY')} ${selectedTime}`;



                                            console.log("formattedDate", formattedDate);

                                            //   console.log("selectedDateTime", selectedDateTime);
                                            const currentDateTime = Moment();

                                            const formattedDate1 = currentDateTime.format('DD-MMM-YYYY');
                                            const formattedTime = currentDateTime.format('HH:mm:ss');

                                            const currentDateTimeFormatted = `${formattedDate1} ${formattedTime}`;
                                            console.log("currentDateTimeFormatted", currentDateTimeFormatted);

                                            const currentDate = Moment().format('DD-MMM-YYYY');
                                            console.log("currentDate", currentDate);
                                            //  const currentDate = new Date().toLocaleDateString();

                                            const selectedDate = Moment(secAdate).format('DD-MMM-YYYY');
                                            // const selectedDate = secAdate.toLocaleDateString();
                                            console.log("selectedDate", selectedDate);
                                            if (formattedDate1 > currentDateTimeFormatted) {
                                                setShowRedBorder(true);
                                                enqueueSnackbar("Start time must be less than current time.");
                                                if (startTimeRef.current) {
                                                    startTimeRef.current.querySelector('input').focus(); // Set focus on the text field
                                                }
                                            } else if (currentDate !== selectedDate) {
                                                setShowRedBorder(false);
                                                setStartTime(selectedTime);
                                            } else {
                                                setShowRedBorder(false);
                                                setStartTime(selectedTime);
                                                console.log("selectedTime after set", selectedTime);
                                            }
                                        }}
                                        inputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        fullWidth
                                    />


                                    
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        ref={serviceInMinRef}
                                        size="small"
                                        label="Service in Min"
                                        type="number"
                                        style={{
                                            border: showMinBorder ? '1px solid red' : '',
                                            borderRadius: showMinBorder ? '10px' : '' // Set to '20px' for red border, '0' for gray border
                                        }}
                                        value={serviceInMin}
                                        onChange={handleChange}
                                        onBlur={handleServiceInMinBlur}
                                        fullWidth

                                    />
                                </Grid>


                                <Grid item xs={12} sm={10}>
                                    <TextareaAutosize
                                        minRows={3}
                                        placeholder="Service Remark"
                                        value={serviceRemark}
                                        onChange={handleRemarkChange}
                                        style={{
                                            width: "100%",
                                            fontFamily: "Tahoma",
                                            fontSize: "12px",
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                    {serviceid ? <Button variant="contained" color="primary" onClick={handleUpdate}>Update</Button> :
                                        <Button variant="contained" color="primary" onClick={handleSave} >Save</Button>}
                               
                                        <div style={{ marginLeft: '6px', flexShrink: 0 }}>
                                <FormControlLabel
                                        control={
                                            <Tooltip title={selected ? "Clear" : "Partialy unclear"}>

                                        <Checkbox
                                                defaultChecked
                                                checked={selected}
                                                onChange={(event) => setSelected(event.target.checked)}
                                                />
                                            </Tooltip>
                                    }
                                                style={{ marginRight: '0' }}
                                            />
                                        </div>
                                    </div>
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Section C ------------------------------------------  */}
                    <Grid item xs={12} md={2.5}>
                        <DataGrid
                            rows={sectionCDatagrid}
                            columns={TABLE_HEAD}
                            onRowClick={handleRowClick}
                            style={{ height: 200 }}
                            pageSize={pageSize1}
                            onPageSizeChange={(newPageSize) => setPageSize1(newPageSize)}
                            getRowId={(row) => sectionCDatagrid.indexOf(row)}
                            density={'compact'}
                            disableColumnFilter
                            disableSelectionOnClick
                            pagination={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                        <DataGrid
                            rows={activityDatagrid}
                            columns={TABLE_HEAD2}
                            onRowClick={handleRowClick}
                            style={{ height: 200 }}
                            pageSize={pageSize1}
                            onPageSizeChange={(newPageSize) => setPageSize1(newPageSize)}
                            getRowId={(row) => activityDatagrid.indexOf(row)}
                            density={'compact'}
                            disableColumnFilter
                            disableSelectionOnClick
                            pagination={false}
                        />
                    </Grid>


                    {/* Section D ------------------------------------------- */}

                    <Grid item md={12} xs={12}>
                        <DataGrid
                            rows={sectionDDatagrid}
                            columns={TABLE_HEAD1}
                            onRowClick={handleRowClick1}
                            style={{ height: 240 }}
                            pageSize={pageSize}
                            sx={{ '.MuiDataGrid-columnHeaders': { height: '10px' } }}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            getRowId={(row) => sectionDDatagrid.indexOf(row)}
                            density={'compact'}
                            disableSelectionOnClick
                            components={{
                                Pagination: CustomPagination,
                            }}
                            pagination
                            paginationMode="client"
                            serviceid={IssueId} 
                        />
                    </Grid>

                </>

                : ''}

        </Grid>
    );
}
