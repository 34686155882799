import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    FormControlLabel,
    Checkbox,
    Grid,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import Page from '../../Page';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import { handleError } from '../../Common/Utils';


// ----------------------------------------------------------------------




function descendingComparator(a, b, orderBy) {


    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    // if (query) {
   
    //    return filter(array, (_project) => _project.ClientAlias.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    // }
    return stabilizedThis.map((el) => el[0]);
}

export default function ClientMaster({ clientMaster }) {

    const TABLE_HEAD = [
        { field: 'ContactId', headerName: 'ID', width: 150 },
        { field: 'ClientName', headerName: 'Client Name', width: 300 },
        { field: 'ClientAlias', headerName: 'Client Alias', width: 300 },
        { field: 'SaplMailerAPI', headerName: 'SAPL Mailer API', width: 150, hide: true },
        { field: 'FromEmailId', headerName: 'From Email Id', width: 150, hide: true},
        { field: 'CallListEmail', headerName: 'Call List Email', width: 150, hide: true },
        { field: 'ProjectStatus', headerName: 'Project Status', width: 150, hide: true},
        { field: 'IsActive', headerName: 'IsActive', width: 150, hide: true},
      
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues, teamPanelData) => {
               
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues, teamPanelData);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },

    ];
    const { enqueueSnackbar } = useSnackbar();
    // dropdown
   
    const [clientMasterDropDown, setclientMasterDropDown] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [teamPanelData, setTeamPanelData] = useState([]);

    const [data, setData] = useState([])
    const location = useLocation();

    const navigate = useNavigate();
    const handleClick = (event, cellValues) => {
        console.log("cellValues.row", cellValues.row);
        const payload = { ClientMasterID: cellValues.row.ContactId }

        URLS.getClientMasterEditData(payload)
            .then((response) => {
                const jsonDataString = JSON.stringify(response.data);
                const modifiedData = JSON.parse(jsonDataString);
                setData(modifiedData);
                navigate('/dashboard/clientmasterdetailspage', { state: { clientMaster: modifiedData } })
                console.log("clientMaster", clientMaster)
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            })

        // URLS.getTeamAndPrefferedClientsDropdown(payload)
        //    .then((response) => {
        //        const jsonDataString = JSON.stringify(response.data);
        //        const modifiedData = JSON.parse(jsonDataString);
        //        const replaceQuotesInArray = (arr) => {
        //            return JSON.parse(JSON.stringify(arr).replace(/"/g, "'"));
        //        };
        //        const modifiedDataWithSingleQuotes = [
        //            {
        //                teamMasterID: 80,
        //                teamIndex: 0,
        //                teamName: 'NayaTeam2',
        //                ProjectId: 38,
        //                selectedExecutives: [
        //                    {
        //                        Id: 1287,
        //                        Name: 'Amit Mule-SAPL117',
        //                        Designation: 'Executive',
        //                        Department: 'Operations Support',
        //                        Role: '301',
        //                        Costing: '9898',
        //                        ExecutiveValue: [6894, 1287],
        //                        CheckboxState: false,
        //                        radio: false,
        //                        errorcheckbox : true
        //                    },
        //                    {
        //                        Id: 6894,
        //                        Name: 'Amit Mule-SAPL117',
        //                        Designation: 'Executive',
        //                        Department: 'Operations Support',
        //                        Role: '300',
        //                        Costing: '8787',
        //                        ExecutiveValue: [6894, 1287],
        //                        CheckboxState: true,
        //                        radio: false,
        //                        errorcheckbox: true

        //                    }
        //                ]
        //            },
        //            {
        //                teamMasterID: 79,
        //                teamIndex: 1,
        //                teamName: 'NayaTeam1',
        //                ProjectId: 59,
        //                selectedExecutives: [
        //                    {
        //                        Id: 11434,
        //                        Name: 'Amit Mule-SAPL117',
        //                        Designation: 'Executive',
        //                        Department: 'Operations Support',
        //                        Role: '302',
        //                        Costing: '7665',
        //                        ExecutiveValue: [17841, 245, 11434],
        //                        CheckboxState: false,
        //                        radio: false,
        //                        errorcheckbox: true

        //                    },
        //                    {
        //                        Id: 11434,
        //                        Name: 'Amit Mule-SAPL117',
        //                        Designation: 'Executive',
        //                        Department: 'Operations Support',
        //                        Role: '302',
        //                        Costing: '7665',
        //                        ExecutiveValue: [17841, 245, 11434],
        //                        CheckboxState: false,
        //                        radio: true,
        //                        errorcheckbox: true

        //                    },
        //                    {
        //                        Id: 17841,
        //                        Name: 'Amit Mule-SAPL117',
        //                        Designation: 'Executive',
        //                        Department: 'Operations Support',
        //                        Role: '299',
        //                        Costing: '4354',
        //                        ExecutiveValue: [17841, 245, 11434],
        //                        CheckboxState: false,
        //                        radio: false,
        //                        errorcheckbox: true

        //                    },
        //                    {
        //                        Id: 17841,
        //                        Name: 'Amit Mule-SAPL117',
        //                        Designation: 'Executive',
        //                        Department: 'Operations Support',
        //                        Role: '299',
        //                        Costing: '4354',
        //                        ExecutiveValue: [17841, 245, 11434],
        //                        CheckboxState: false,
        //                        radio: true,
        //                        errorcheckbox: true

        //                    },
        //                    {
        //                        Id: 245,
        //                        Name: 'Amit Mule-SAPL117',
        //                        Designation: 'Executive',
        //                        Department: 'Operations Support',
        //                        Role: '300',
        //                        Costing: '6767',
        //                        ExecutiveValue: [17841, 245, 11434],
        //                        CheckboxState: true,
        //                        radio: false,
        //                        errorcheckbox: true

        //                    },
        //                    {
        //                        Id: 245,
        //                        Name: 'Amit Mule-SAPL117',
        //                        Designation: 'Executive',
        //                        Department: 'Operations Support',
        //                        Role: '300',
        //                        Costing: '6767',
        //                        ExecutiveValue: [17841, 245, 11434],
        //                        CheckboxState: 'true',
        //                        radio: true,
        //                        errorcheckbox: true

        //                    }
        //                ]
        //            }
        //        ];
        //        setTeamPanelData(modifiedData);

        //        navigate('/dashboard/clientmasterdetailspage', { state: { cliMaster: cellValues.row, teamPanelData: modifiedDataWithSingleQuotes, productTeam: modifiedDataWithSingleQuotes } })
        //    })
        //    .catch(error => {
        //        handleError(error, enqueueSnackbar);
        //    });
    }

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('ClientAlias');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);


    const [clientMasterData, setClientMasterData] = useState([]);
    const [clientAliasData, setClientAliasData] = useState([]);
   

    const [apiTxt, setApiTxt] = useState();

    const [isCheckedActive, setIsCheckedAcive] = useState(true);

    
    const [contactId, setContactId] = useState('');

    const handelSelectedClient = (id) => {
        setContactId(id);
    };

    const onFilterClick = () => {
      
            setIsLoading(true);

        const payload = { ClientMasterID: contactId };
            //   let payload = { clientId };

        URLS.getClientMasterListingData(payload)
                .then((response) => {
                    setClientMasterData(response.data);
                    setclientMasterDropDown(response.data);
                    setIsLoading(false);

                })
                .catch(error => {
                    handleError(error, enqueueSnackbar);
                });
        
    };

   
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    // recomment this code

     const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = clientMasterData.map((n) => n.margCode);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
     };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
   

    const handleIsActiveChange = (event) => {
        setIsCheckedAcive(event.target.checked);
    };


    const filteredProjects = applySortFilter(clientMasterData, getComparator(order, orderBy), filterName);

    const isProjectNotFound = filteredProjects.length === 0;

    useEffect(() => {
        // URLS.getClientNamesDD()
        //    .then((response) => {

        //        setClientAliasData(response.data);
        //        setIsLoading(false);


        //    })
        //    .catch(error => {
        //        handleError(error, enqueueSnackbar);
        //    });

        URLS.getClientMasterData()
            .then((response) => {

                setClientAliasData(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    }, []);

    const onResetClick1 = (event) => {
        setContactId('');
        setclientMasterDropDown([]);
        setApiTxt('');
        setIsCheckedAcive(false);
    };
    useEffect(() => {
        setContactId('');
        setclientMasterDropDown([]);
        setApiTxt('');
        setIsCheckedAcive(false);
    }, [location.key]); 
    
    const handleApiChange = (event) => {
        setApiTxt(event.target.value);
    }
    return (

        <Page title="Client Master" >


            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl size="small" fullWidth>
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Client Alias</InputLabel>
                                <Select
                                    label="ClientAlias"
                                    size="small"
                                    value={contactId}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7',
                                                maxHeight: '200px',
                                                width: '200px',
                                            }
                                        }
                                    }}
                                    onChange={(event) => handelSelectedClient(event.target.value)}
                                >
                                    <MenuItem key={0} value={""} onClick={() => handelSelectedClient()}>Select</MenuItem>
                                    {clientAliasData.map((component) => (
                                        <MenuItem key={component.ContactId} value={component.ContactId} onClick={() => handelSelectedClient(component.ContactId)}> {component.Name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                          
                        </Grid>
                    
                    </Grid>
                  {/*  <FormControlLabel control={<Checkbox checked={isCheckedActive} onChange={handleIsActiveChange} />} label="IsActive" style={{ width: '150px' }} /> */}
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>
                        <Tooltip title="Filter">
                            <Button variant="contained" size="small" onClick={onFilterClick}>
                                <Icon icon="ic:outline-filter-alt" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Add New">
                            <Button
                                component={RouterLink}
                                to='/dashboard/clientmasterdetailspage'
                                variant="contained"
                                size="small"
                                state={{ clientMaster: { ContactId: '', SubContactID: '', ClientName: '', ERPCode: '', Remarks: '', IsActive: '', SaplMailerAPI: '', fromEmailId: '', CallListEmail: '', ProjectStatus: '', ClientGrouping: '', ExecutiveID: '', TeamsDataMaster: [], ExecutivesDataMaster: [] } }}
                            >
                                <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Reset">
                            <Button variant="contained" size="small" onClick={onResetClick1}>
                                <Icon icon="ic:twotone-restart-alt" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={clientMasterDropDown} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => clientMasterDropDown.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}