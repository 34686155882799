import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import { Icon } from '@iconify/react';
import Moment from 'moment';
import Iconify from '../../Iconify';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import URLS from '../../../services/urls.service';
import Page from '../../Page';
import AppCache from '../../../services/AppCache';


// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {





    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}



export default function ClientInstallation() {

    const TABLE_HEAD = [
        { field: 'SiteId', headerName: 'Site Id', width: 80 },
        { field: 'Name', headerName: 'Name', width: 220 },
        { field: 'WebSite', headerName: 'Site Name', width: 150 },
        { field: 'ProjectVersionId', headerName: 'Project Version', width: 150},
        {
            field: 'StartDate', headerName: 'Start Date', width: 150, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy")
        },
        {
            field: 'EndDate', headerName: 'End Date', width: 150, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy")
        },
        { field: 'ContactMaster', headerName: 'Name', width: 110, hide: true},
        { field: 'Designation', headerName: 'Designation', width: 140, hide: true },
        { field: 'ContactNo', headerName: 'Contact Number', width: 110, hide: true },
        { field: 'Mobile', headerName: 'Mobile', width: 110, hide: true },
        { field: 'Email', headerName: 'Email', width: 180, hide: true },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            flex: 1,
            justifyContent:'end',
            renderCell: (cellValues) => {
               
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },

      

    ];

    // dropdown
    const navigate = useNavigate();
    const handleClick = (event, cellValues) => {
        navigate('/dashboard/clientinstallationdetailspage', { state: { cliInstallation: cellValues.row } })
       
    }
    const [clientData, setClientData] = useState({});
    const [isLoading, setIsLoading] = useState();

    const location = useLocation();

   

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('ClientAlias');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(10);


   



   

   
   
   
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

   

     // recomment this code
     const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = clientData.map((n) => n.margCode);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
     };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    const onResetClick = (event) => {
        window.location.reload();
    };



    // const filteredProjects = applySortFilter(clientData, getComparator(order, orderBy), filterName);

    // const isProjectNotFound = filteredProjects.length === 0;

    const [selectedSite, setSelectedSite] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState();
    useEffect(() => {
        const payload2 = {
            "SiteId": "",
            "ContactId": "",
            "Name": "",
            "ERPCode": "",
            "ContactTypeId": "",
            "Remarks": "",
            "IsSite": "",
            "ClientId": "",
            "ProjectVersionId": "",
            "StartDate": "",
            "EndDate": "",
            "AMCTypeId": "",
            "NoOfLicences": "",
            "WebSite": "",
            "Address": "",
            "Country": "",
            "State": "",
            "City": "",
            "District": "",
            "PinCode": "",
            "Region": "",
            "Classification": "",
            "FirstContactBy": "",

        };

        AppCache.SetClientInstallationLocations(payload2);
        
        URLS.getSiteAutocomplete()
            .then((response) => {
               
                setSelectedSite(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getProjectVersionAutocomplete()
            .then((response) => {
              
                setSelectedProject(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
     
        URLS.getClientMasterData()
            .then((response) => {

                setSelectedClient(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        // setIsLoading(true);
        // URLS.getClientInstallationContactMaster()
        //    .then((response) => {
        //        console.log("response.data", response.data);
        //        setClientData(response.data);
        //        setIsLoading(false);
        //    })
        //    .catch(error => {
        //        // On error
        //        console.log(" Error ");
        //    });

    }, []);

    // SiteAutocomplete code

    const [siteId, setSiteId] = useState('');
    const [siteName, setSiteName] = useState();
    const handelSelctedSite = (id, name) => {
       
        setSiteId(id);
       

    };

    // ProjectVersionId

    const [projectId, setProjectId] = useState('');
    const [projectName, setProjectName] = useState();
    const handelSelectedProject = (id, name) => {
     
        setProjectId(id);
        setProjectName(name);

    };

    // Client
    const [clientId, setClientId] = useState('');
    const [clientName, setClientName] = useState();
    const handelSelectedClient = (id, name) => {
     
        setClientId(id);
        setClientName(name);

    };

    const onFilterClick = () => {
        // if (clientId || siteId || projectId ) {
        //    setIsLoading(true);

        //    const payload = { ClientFilterID: clientId, SiteFilterID: siteId, ProjectFilterID: projectId };
        //    //   let payload = { clientId };

        //    URLS.getClientInstallationFilterDropDown(payload)
        //        .then((response) => {
        //            setClientData(response.data);
        //            setIsLoading(false);

        //        })
        //        .catch(error => {
        //            // On error
        //            console.log(" Error ");
        //        })
        // }
        // else {
        //    setIsLoading(true);
        //    URLS.getClientInstallationContactMaster()
        //        .then((response) => {
        //            console.log("response.data", response.data);
        //            setClientData(response.data);
        //            setIsLoading(false);
        //        })
        //        .catch(error => {
        //            // On error
        //            console.log(" Error ");
        //        });

        // }

        const payload = { ClientMasterID: clientId}
        URLS.getClientMasterListingData(payload)
            .then((response) => {
                setClientData(response.data);
            setIsLoading(false);

            })
            .catch(error => {
                console.log("error",error)
            });

    };
    useEffect(() => {
        setClientId('');
        setSiteId('');
        setClientData('')
        setProjectId('')
    }, [location.key]); 

    return (

        <Page title="Client Installation Locations" >


            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <FormControl
                        fullWidth
                        size="small"

                    >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Client</InputLabel>
                        <Select
                            label="Client"
                            size="small"
                            value={clientId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {selectedClient.map((client) => (
                                <MenuItem key={client.ContactId} value={client.ContactId} onClick={() => handelSelectedClient(client.ContactId, client.Name)}> {client.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>

                    <FormControl
                        fullWidth
                        size="small"

                    >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Project Version</InputLabel>
                        <Select
                            label="Project Version"
                            size="small"
                            value={projectId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {selectedProject.map((project) => (
                                <MenuItem key={project.ProjectVersionId} value={project.ProjectVersionId} onClick={() => handelSelectedProject(project.ProjectVersionId, project.VersionName)}> {project.VersionName}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                    <FormControl
                        fullWidth
                        size="small"

                    >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Sites</InputLabel>
                        <Select
                            label="Sites"
                            size="small"
                            value={siteId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handelSelctedSite() } >Select</MenuItem>

                            {selectedSite.map((site) => (
                                <MenuItem key={site.SiteId} value={site.SiteId} onClick={() => handelSelctedSite(site.SiteId, site.Name)}> {site.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                  

                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"

                                onClick={onFilterClick}
                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >


                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/clientinstallationdetailspage'
                                variant="contained"
                                size="small"
                                state={{ cliInstallation: { SiteId: '', ContactId: '', Name: '', ERPCode: '', ContactTypeId: '', Remarks: '', IsSite: '', ClientId: '', ProjectVersionId: '', StartDate: new Date(), EndDate: new Date(), AMCType: '', NoOfLicences: '', WebSite: '', Address: '', Country: '', State: '', City: '', District: '', PinCode: '', Region: '', Classification: '', FirstContactBy: '', ExecutiveID: '', contactmaster: { SiteId: '', ContactId: '', Location: '', ContactPersonName: '', ContactNo: '', Mobile: '', Email:''} } }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >


                    </Stack>

                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={clientData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => clientData.indexOf(row)} components={{
                        Toolbar: GridToolbar, 
                    }} /> 
                 </div> 

            </Card>

            {isLoading && (<LoadingProgressBar sx={{ py: 9 }} />)}
        </Page>


    );
}