import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Button, Box, Typography, Modal, IconButton, Badge, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import ModalPopUp from '../../Common/ModalPopUp';

const imageStyle = {
    aspectRatio: 16 / 9,
    height: 80

}

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};

export default function LoginImageUpload() {

    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [effectiveFromValue, setEffectiveFromValue] = useState(new Date());
    const [effectiveToValue, setEffectiveToValue] = useState(new Date());

    const [userId, setUserId] = useState([]);


    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };



    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedImageFiles, setSelectedImageFiles] = useState([]);

    const onSelectFile = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const imagesArray = selectedFiles.map((file) => URL.createObjectURL(file));

        setSelectedImages((prevImages) => [...prevImages, ...selectedFiles]);
        setSelectedImageFiles((prevFiles) => [...prevFiles, ...imagesArray]);

        event.target.value = ""; // Clear the file input value
        console.log("selectedFiles", selectedFiles);
    };
    const deleteHandler = (image) => {
        setSelectedImageFiles((prevFiles) => {
            const deletedIndex = prevFiles.findIndex((file) => file === image);
            const updatedFiles = [...prevFiles.slice(0, deletedIndex), ...prevFiles.slice(deletedIndex + 1)];

            setSelectedImages((prevImages) => {
                const updatedImages = [...prevImages];
                updatedImages.splice(deletedIndex, 1);
                return updatedImages;
            });

            console.log("Deleted file:", prevFiles[deletedIndex]);

            return updatedFiles;
        });

        URL.revokeObjectURL(image);
    };

    const { enqueueSnackbar } = useSnackbar();
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const navigate = useNavigate();
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {

        if (value) {
            navigate('/dashboard/app', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }


    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };



    const imageFolderPath = "";
    // Image upload start
    const [invalidImage, setInvalidImage] = useState("");

    const [imagePath, setImagePath] = useState("");

    const onResetClick = (event) => {
        setFieldValue("photo", "");
        setImagePath("");

        // window.location.reload();
    };
    // function handleUpload(event) {
    //    if (event.target.files.length !== 0) {

    //        if (!event.currentTarget.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //            setInvalidImage("Please select valid image");
    //            setImagePath("");

    //        }
    //        else {
    //            setFieldValue("photo", event.target.files[0]);
    //            setImagePath(URL.createObjectURL(event.target.files[0]))
    //            setInvalidImage("");
    //        }
    //    }
    //    else {
    //        setImagePath("");
    //        setInvalidImage("");
    //    }
    // }
    const Imageschema = Yup.object().shape({
        selectedImages: Yup.mixed().required('Image is required'),
    })
    const formik = useFormik({
        initialValues: {
            selectedImages: [],
            EffectiveFrom: effectiveFromValue,
            EffectiveTo: effectiveToValue,
            UploadedByID: userId,
        },
        validationSchema: Imageschema,
        onSubmit: (values, actions) => {
            if (selectedImages.length === 0) {
                enqueueSnackbar("Please Choose photo");
                actions.setSubmitting(false);
                return;
            }

            const formData = new FormData();
            for (let i = 0; i < selectedImages.length; i += 1) {
                formData.append("Photo", selectedImages[i]);
            }
            formData.append("EffectiveFrom", effectiveFromValue.toISOString());
            formData.append("EffectiveTo", effectiveToValue.toISOString());
            formData.append("UploadedByID", userId);

            // API call for update Photo
            URLS.loginImage(formData)
                .then((response) => {
                    // Successful response
                   
                        window.location.reload();
                        enqueueSnackbar("Records Saved Successfully", { variant: 'success' });
                   
                        
                })
                .catch(error => {
                    alert("Error");
                });

            actions.setSubmitting(false);
        },

    });
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} p={1}>

                <Stack p={2}>



                    <Stack direction="row" alignItems="center" spacing={2} pl={2}>

                        <Typography variant="subtitle2" sx={{ color: "#92979c" }}>
                            Image Upload  :
                        </Typography>
                        <label htmlFor="photoUpload">

                            <input
                                id="photoUpload"
                                name="selectedImages"
                                variant="contained"
                                type="file"
                                onChange={onSelectFile}
                                multiple
                                accept="image/png , image/jpeg, image/webp"
                                style={{ display: 'none' }} />

                            <Button variant="contained" component="span"
                                sx={{
                                    height: 25, width: 130, borderRadius: 1, fontWeight: 500, mr: 5,
                                    ":hover": { backgroundColor: "#9e9e9e" },
                                    backgroundColor: "#e0e0e0", color: "#212121", boxShadow: "none"
                                }}
                            >
                                Choose photo
                            </Button>

                        </label>

                    </Stack>

                    {selectedImageFiles.length > 0 &&
                        (selectedImageFiles.length > 10 ? (
                            <p className="error">
                                You can't upload more than 10 images! <br />
                                <span>
                                    please delete <b> {selectedImageFiles.length - 10} </b> of them{" "}
                                </span>
                            </p>
                        ) : (
                            ""
                        ))}

                    <div className="images">
                        <Stack direction="row" mt={2}>
                            {selectedImageFiles &&
                                selectedImageFiles.map((image, index) => {
                                    return (
                                        <div key={image} className="image" style={{ margin: '0 8px' }}>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                }}
                                            >
                                                <img src={image} style={imageStyle} alt="upload" />
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <IconButton size="small" onClick={() => deleteHandler(image)}>
                                                        <Icon icon="ic:baseline-cancel" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </Stack>

                    </div>

                    <Stack direction={{ xs: 'row', sm: 'column' }} width="300px" mt={3} spacing={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label="Effective From"
                                inputFormat="dd/MMM/yyyy hh:mm aa"
                                value={Moment(effectiveFromValue).format('DD-MMM-yyyy, hh:mm a')}
                                onChange={handleEffFrom}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                label="Effective To"
                                inputFormat="dd/MMM/yyyy hh:mm aa"
                                value={Moment(effectiveToValue).format('DD-MMM-yyyy, hh:mm a')}
                                onChange={handleEffTo}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                    {invalidImage && <Stack direction="row" alignItems="center" pl={8}><Typography>{invalidImage}</Typography></Stack>}

                    <Stack spacing={2} direction="row" mt={1} justifyContent="center">
                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Save
                        </LoadingButton>

                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>

                    </Stack>

                </Stack>
            </Form>
            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}
        </FormikProvider>
    );
}