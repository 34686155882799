import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Accordion, Radio, AccordionDetails, Tooltip, Stack, TextField, FormControl, InputLabel, Select, Grid, Modal, IconButton, MenuItem, Button, Box, Typography, Tabs, Tab, TextareaAutosize, Card, Autocomplete, Chip } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { styled } from '@mui/material/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';


import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';

import Iconify from '../../Iconify';



const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};

export default function TeamTabPanel({ formik, clientMaster, isCheckedCallList, isCheckedActive, handleCheckboxChange, handleIsActiveChange, projectStatus, clientGrouping, value, handleChangeValue, hide, handleHide, handleShow, projectStatusId, handelSelectedProject, subContactId, handleSelectedSubContact, userId, projectDatatDropDown, projectId, handleSelectedProject, executives, selectedExecutives, handleSelectedExecutive, teams, numberOfTeams, maxExecutivesPerTeam, initialExecutiveValue, executiveValue, addTeam, handleTeamNameChange, handleProjectChange, initialExecutiveStates, executiveStates, handleExecutivesChange, executiveRoles, executiveCostings, handleRoleChange, handleCostingChange, handleActiveCheckboxChange, selectedRadioStates, handleRadioChange, handleErrorCheckboxChange, exeData, handleSelectedExecutiveDD, SelectExecutive, handleSave, removeTeam, deleteTeam, handleDelete, executive, department, isHide, rolesData, teamPanelData }) {
    const accordionStyle = {
        backgroundColor: '#fbe9e7',
        minHeight: '10px',
        height: '40px',
        marginBottom:'5px'
        // Add other CSS properties as needed
    };
    console.log("teamPanelData", teamPanelData);
    // const executiveIdFromTeamPanelData = teamPanelData[0]?.ExecutiveId;
    const executiveIdFromTeamPanelData = teamPanelData && teamPanelData.length > 0 ? teamPanelData[0].ExecutiveId :'';
    const selectedExecutive = executives.find((executive) => executive.Id === executiveIdFromTeamPanelData);

    // Define the team object or use the existing one
    const team = {
        selectedExecutives: selectedExecutive ? [selectedExecutive] : [],
        // other team properties...
    };
    console.log("teamPanelData", teamPanelData);
    console.log("teams", teams, teams[0].selectedExecutives
);
    const filterDuplicates = (teams) => {
        const seen = new Set();
        return teams.filter(team => {
            const key = `${team.projectId}-${team.teamMasterID}-${team.teamName}`;
            if (seen.has(key)) {
                return false;
            }
            seen.add(key);
            return true;
        });
    };
    const filteredTeams = filterDuplicates(teams);
    const { handleSubmit, getFieldProps, errors, touched } = formik;
   
    return (
        <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={11} lg={11.5}  >
                {teams.length > 0 && teams.map((team1, index1) => (
                    <Stack key={index1}>
                        <Accordion
                            style={{
                                borderBottom: '1px solid #ddd',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                margin: '7px'
                            }}
                            defaultExpanded={index1 === 0} // Set defaultExpanded to true for the first accordion
                        >
                            <AccordionSummary style={accordionStyle} expandIcon={<ExpandMoreIcon />}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography style={{ lineHeight: 2.5 }}>
                                        <span style={{ fontWeight: 'bold' }}>Team Name :</span>
                                        {teamPanelData && teamPanelData.length > 0 ? teamPanelData[index1].teamName : team1.teamName}
                                    </Typography>
                                    {/* <Tooltip title={`Delete ${team1.teamName}`}> */}
                                    {/*    <IconButton */}
                                    {/*        onClick={() => deleteTeam(index1)} */}
                                    {/*        color="secondary" */}
                                    {/*        aria-label="delete" */}
                                    {/*    > */}
                                    {/*        <Iconify icon="ic:baseline-delete" width='20px' height='20px' color='primary.main' /> */}
                                    {/*    </IconButton> */}
                                    {/* </Tooltip> */}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            label="Team Name"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={team1.teamName}
                                            onChange={(e) => handleTeamNameChange(index1, e.target.value)}
                                            error={Boolean(touched.teamName && errors.teamName)}
                                            helperText={touched.teamName && errors.teamName}
                                        />
                                        <FormControl fullWidth size="small">
                                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                                Projects
                                            </InputLabel>
                                            <Select
                                                label="Projects"
                                                value={team1.projectId}
                                                onChange={(event) => handleProjectChange(index1, event.target.value)}
                                                MenuProps={{ style: { maxHeight: '200px' } }}
                                            >
                                                <MenuItem key={0} value="">
                                                    Select Project
                                                </MenuItem>
                                                {projectDatatDropDown.map((projectdata) => (
                                                    <MenuItem key={projectdata.ProjectId} value={projectdata.ProjectId} onClick={() => handleSelectedProject(projectdata.ProjectId)}>
                                                        {projectdata.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <Autocomplete
                                            multiple
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            id="checkboxes-tags-demo"
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.Name}
                                            value={team1.selectedExecutives}
                                            onChange={(event, newValue) => handleExecutivesChange(index1, newValue)}
                                            isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox checked={selected} />
                                                    {option.Name}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Executives"
                                                    placeholder="Select Executives"
                                                />
                                            )}
                                            renderTags={(value, getTagProps) => (
                                                <div>
                                                    {value.length > 0 && <p>{value.length} selected</p>}
                                                </div>
                                            )}
                                            options={executives.slice().sort((a, b) => {
                                                const isSelectedA = team1.selectedExecutives.some((selected) => selected.Id === a.Id);
                                                const isSelectedB = team1.selectedExecutives.some((selected) => selected.Id === b.Id);
                                                if (isSelectedA && !isSelectedB) {
                                                    return -1;
                                                } if (!isSelectedA && isSelectedB) {
                                                    return 1;
                                                } 
                                                    return 0;
                                                
                                            })}
                                            getOptionSelected={(option, value) => option.Id === value.Id}
                                        />
                                    </Stack>
                                    {team1.selectedExecutives.map((executive, execIndex) => (
                                        <Grid container spacing={1} key={execIndex}>
                                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                                <Stack style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="outlined" style={{ fontSize: 'smaller', marginRight: '5px', maxWidth: '300px' }}>
                                                        {executive.Name}
                                                    </Typography>
                                                    <Typography variant="body2" style={{ fontSize: 'smaller' }}>
                                                        ({executive.Designation}-{executive.Department})
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Roles</InputLabel>
                                                    <Select
                                                        label="Roles"
                                                        value={executive.Role || ''}
                                                        onChange={(e) => handleRoleChange(index1, execIndex, e.target.value)}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    backgroundColor: '#fbe9e7',
                                                                    maxHeight: '200px',
                                                                    width: '200px',
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem value="">Select Role</MenuItem>
                                                        {rolesData.map((role) => (
                                                            <MenuItem key={role.Id} value={role.Id}>
                                                                {role.Name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                                <TextField
                                                    label={`Costing`}
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    value={executive.Costing || ''} // Use the updated state
                                                    onChange={(e) => handleCostingChange(index1, execIndex, e.target.value)} // Handle change
                                                />


                                            </Grid>
                                            {/* <Grid item xs={12} sm={6} md={3} lg={2}> */}
                                            {/*    <FormControl fullWidth size="small"> */}
                                            {/*        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Alternative Executives</InputLabel> */}
                                            {/*        <Select */}
                                            {/*            label="Alternative Executives" */}
                                            {/*            value={executive.AltExecutiveId || ''} */}
                                            {/*            MenuProps={{ */}
                                            {/*                PaperProps: { */}
                                            {/*                    sx: { */}
                                            {/*                        backgroundColor: '#fbe9e7', */}
                                            {/*                        maxHeight: '200px', */}
                                            {/*                        width: '200px', */}
                                            {/*                    } */}
                                            {/*                } */}
                                            {/*            }} */}
                                            {/*            onChange={(e) => handleSelectedExecutiveDD(index1, execIndex, e.target.value)} */}
                                            {/*        > */}
                                            {/*            <MenuItem value={""} onClick={() => handleSelectedExecutiveDD(index1, execIndex, null)}> Select Alternative </MenuItem> */}
                                            {/*            {exeData.map((dept) => ( */}
                                            {/*                <MenuItem */}
                                            {/*                    key={dept.Id} */}
                                            {/*                    value={dept.Id} */}
                                            {/*                    onClick={() => handleSelectedExecutiveDD(index1, execIndex, dept.Id, dept.Name)} */}
                                            {/*                > */}
                                            {/*                    {dept.Name} */}
                                            {/*                </MenuItem> */}
                                            {/*            ))} */}
                                            {/*        </Select> */}
                                            {/*    </FormControl> */}

                                            {/* </Grid> */}
                                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={(clientMaster.ProjectTeam && clientMaster.ProjectTeam[0]?.ProjectId) ? executive.radio : (selectedRadioStates[index1] === execIndex)}
                                                            onChange={(e) => handleRadioChange(index1, execIndex, executive.Id)}
                                                            name={`execRadio_${index1}_${execIndex}`} // Include the team and executive index
                                                        />
                                                    }
                                                    label="Coordinator"
                                                />
                                            </Grid>
                                            {/* <Grid item xs={12} sm={6} md={3} lg={1} > */}
                                            {/*    <FormControlLabel */}
                                            {/*        control={ */}
                                            {/*            <Checkbox */}
                                            {/*              // checked={executiveStates[executive.Id].errorCheckbox} */}
                                            {/*                // checked = {(executiveStates[executive.Id] && executiveStates[executive.Id].errorCheckbox) || false} */}

                                            {/*                checked={executive.errorcheckbox} */}
                                            {/*                onChange={(e) => handleErrorCheckboxChange(index1, execIndex, executive.Id, e.target.checked)} */}
                                            {/*            /> */}
                                            {/*        } */}
                                            {/*        label="IsError" */}
                                            {/*    /> */}
                                            {/* </Grid> */}
                                        </Grid>
                                    ))}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                ))}


            </Grid>


            <Grid item xs={12} sm={12} md={1} lg={0.5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Tooltip title="Add New Team">
                    <Button onClick={addTeam} variant="contained" size="small"
                    // Adjust the width and height as needed
                    // style={{ width: '100px', height: '36px', marginBottom: '10px' }}

                    >
                        <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                    </Button>
                </Tooltip>

                {/* <Tooltip title="Delete New Team"> */}
                {/*   <Button onClick={removeTeam} variant="contained" size="small"> */}
                {/*       Delete Team */}
                {/*   </Button> */}
                {/* </Tooltip> */}
            </Grid>
        </Grid>


    );
}






