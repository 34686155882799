import * as React from 'react';
import PropTypes from 'prop-types';
// material
import {Typography,Box,Stack,Button,Modal} from '@mui/material';


ModalPopUp.propTypes = {
    msg: PropTypes.string,
    confirm: PropTypes.func
};
const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};

export default function ModalPopUp({ msg, confirm }) {

    const onClickForCancle = () => {
        confirm(false)
    };
    const onClickOk = () => {
        confirm(true)
    };
    return (
        <div>
            <Modal
                open
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }} gutterBottom> {msg} </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={ 3}>
                    <Button variant="contained" onClick={onClickOk} value='true'>Yes
                    </Button>
                        <Button variant="contained" onClick={onClickForCancle} >No</Button>
                        </Stack>
                </Box>
            </Modal>
            </div>
        )
}