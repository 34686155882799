import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../../components/Page';
import ProjectInformation from '../../../../components/SidebarMenuComponents/OtherComponent/ProjectInformationComponent/ProjectInformation';
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
}));
export default function ProjectInformationDetailsPage() {
    return (
        <Page title=" Project Information  | tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Project Information
            </Typography>

            <ContentStyle>
                <ProjectInformation />
            </ContentStyle>
        </Page>
    );
}

