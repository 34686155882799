import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, FormControl, InputLabel, Select, Grid, Modal, IconButton, Typography, MenuItem, Button, Box, CardMedia, Tabs, Tab, FormHelperText } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Moment from 'moment';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
import ProductTabPanel from './ProductTabPanel';

// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #4e342e',
    position: 'sticky',
    '& .MuiTabs-indicator': {

    },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontsize: '20px',
    color: '#b71c1c',
    Transition: '2s',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#4e342e',

        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4e342e',

        fontWeight: theme.typography.fontWeightMedium,

    },
    '&.Mui-focusVisible': {
        backgroundColor: '#fff',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function OAMasterDetails({ oaMaster, oaDetails, deleteTeam, formik, StatusData, value, handleChangeValue, hide, handleHide, handleShow, selectedClient, selectedClientId, handleClient, selectedExecutives, executivesId, handleExecutives, statusId, handleStatusChange, refQuotationDate, handleRefQuotationDateChange, oaDateValue, handleOADateChange, oaProductsData, oaProductId, handleProductsChange, fieldSets, handleAddButtonClick, handleFieldChange, handleProductIDChange, oaMultipleData, selectedPayment, paymentId, handlePayment, selectProductPayment,productPayemntId, handleProductPaymentIdChange  }) {
    const navigate = useNavigate();
   
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/master/clientmaster', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

    const validateInput = (input) => {
        return /^\d*$/.test(input); // Regular expression to allow only numeric input
    };

    const { handleSubmit, getFieldProps, errors, touched } = formik;
    console.log("paymentId>>>>>>>>>>>>.", paymentId, formik.values)

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <AntTabs value={value} onChange={handleChangeValue} variant="fullWidth" aria-label="basic tabs example">
                    <AntTab onClick={handleShow} label="OA" {...a11yProps(0)} />
                    <AntTab onClick={handleShow} label=" Product" {...a11yProps(1)} />
                </AntTabs >
            </Box>
            <TabPanel value={value} index={0}>
                <Stack spacing={3}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>

                        {/* <TextField */}
                        {/*    fullWidth */}
                        {/*    size="small" */}
                        {/*    label="OA Number " */}
                        {/*    {...getFieldProps('OANumber')} */}
                        {/*    error={Boolean(formik.touched.OANumber && formik.errors.OANumber)} */}
                        {/*    helperText={formik.touched.OANumber && formik.errors.OANumber} */}
                        {/* /> */}

                        
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker

                                label="OA Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(oaDateValue).format('DD-MMM-yyyy')}
                                onChange={handleOADateChange}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>

                        <FormControl
                            size="small"
                            fullWidth
                            error={Boolean(touched.ClientId && errors.ClientId)}
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Clients</InputLabel>
                            <Select
                                label="Clients"
                                size="small"
                                value={formik.values.ClientId} 
                                onChange={(event) => handleClient(event.target.value)} // Update selected client ID
                                required
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7',
                                            maxHeight: '200px',
                                            width: '200px',
                                        }
                                    }
                                }}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem key={0} value={""}>Select</MenuItem>
                                {selectedClient.map((product) => (
                                    <MenuItem key={product.ContactId} value={product.ContactId}>
                                        {product.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{touched.ClientId && errors.ClientId}</FormHelperText>
                        </FormControl>
                        {oaMaster[0]?.OAMasterId && ( // Conditionally render based on OAMasterId's existence

                            <TextField
                                fullWidth
                                label="OA Number"
                                size="small"
                                {...getFieldProps('OANumber')}
                                error={Boolean(formik.touched.OANumber && formik.errors.OANumber)}
                                helperText={formik.touched.OANumber && formik.errors.OANumber}
                                disabled
                            />

                        )}

                    </Stack>

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <TextField
                            fullWidth
                            label="Buyers Ref Order No"
                            size="small"
                            {...getFieldProps('BuyersRefOrderNo')}
                            error={Boolean(formik.touched.BuyersRefOrderNo && formik.errors.BuyersRefOrderNo)}
                            helperText={formik.touched.BuyersRefOrderNo && formik.errors.BuyersRefOrderNo}
                        />
                        <TextField
                            fullWidth
                            label="Ref Quotation No"
                            size="small"
                            {...getFieldProps('RefQuotationNo')}
                            error={Boolean(formik.touched.RefQuotationNo && formik.errors.RefQuotationNo)}
                            helperText={formik.touched.RefQuotationNo && formik.errors.RefQuotationNo}
                        />


                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker

                                label="Ref Quotation Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(refQuotationDate).format('DD-MMM-yyyy')}
                                onChange={handleRefQuotationDateChange}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>


                    </Stack>

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <FormControl
                            fullWidth
                            size="small">
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Responsible</InputLabel>
                            <Select
                                label="Responsible"
                                size="small"
                                value={executivesId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}>
                                <MenuItem key={0} value={""} onClick={() => handleExecutives()}  >Select</MenuItem>
                                {selectedExecutives.map((component) => (
                                    <MenuItem key={component.Id} value={component.Id} onClick={() => handleExecutives(component.Id)}> {component.Name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                         <FormControl 
                            fullWidth 
                            size="small"
                            error={Boolean(touched.PaymentTerm && errors.PaymentTerm)}
                        > 
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Payment Term</InputLabel> 
                            <Select 
                                label="Payment Term" 
                                size="small" 
                                value={formik.values.PaymentTerm}
                                onChange={(event) => handlePayment(event.target.value)}
                                onBlur={formik.handleBlur}
                                MenuProps={{ 
                                    PaperProps: { 
                                        sx: { 
                                            backgroundColor: '#fbe9e7', // Set the background color here 
                                            maxHeight: '200px', 
                                            width: '200px',// Set the max height here 
                                        } 
                                    } 
                                }}> 
                                <MenuItem key={0} value={""} onClick={() => handlePayment()}  >Select</MenuItem> 
                                {selectedPayment.map((component) => (
                                    <MenuItem key={component.Id} value={component.Id} onClick={() => handlePayment(component.Id)}> {component.Name}</MenuItem> 
                                ))} 
                            </Select> 
                            <FormHelperText>{touched.PaymentTerm && errors.PaymentTerm}</FormHelperText>
                         </FormControl> 

                        <FormControl fullWidth size="small">
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                Status
                            </InputLabel>
                            <Select
                                label="Status"
                                size="small"
                                value={statusId}
                                onChange={(event) => handleStatusChange(event.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px', // Set the max height here
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={0} value="">
                                    Select
                                </MenuItem>
                                {StatusData.map((component) => (
                                    <MenuItem key={component.Id} value={component.Id}>
                                        {component.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                    </Stack>


                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <TextField
                            fullWidth
                            label="Amount"
                            size="small"
                            {...getFieldProps('Amount')}
                            error={Boolean(formik.touched.Amount && formik.errors.Amount)}
                            helperText={formik.touched.Amount && formik.errors.Amount}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Tax Amount"
                            size="small"
                            {...getFieldProps('TaxAmount')}
                            error={Boolean(formik.touched.TaxAmount && formik.errors.TaxAmount)}
                            helperText={formik.touched.TaxAmount && formik.errors.TaxAmount}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Total Amount "
                            size="small"
                            {...getFieldProps('TotalAmount')}
                            error={Boolean(formik.touched.TotalAmount && formik.errors.TotalAmount)}
                            helperText={formik.touched.TotalAmount && formik.errors.TotalAmount}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                        />

                    </Stack>



                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <TextField
                            fullWidth
                            label="Remark"
                            size="small"
                            multiline
                            maxRows={4}
                            {...getFieldProps('Remark')}
                            error={Boolean(formik.touched.Remark && formik.errors.Remark)}
                            helperText={formik.touched.Remark && formik.errors.Remark}
                        />

                        </Stack>

                </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ProductTabPanel formik={formik} oaMaster={oaMaster} oaDetails={oaDetails} paymentId={paymentId} deleteTeam={deleteTeam} oaProductsData={oaProductsData} oaProductId={oaProductId} handleProductsChange={handleProductsChange} fieldSets={fieldSets} handleAddButtonClick={handleAddButtonClick} handleFieldChange={handleFieldChange} handleProductIDChange={handleProductIDChange} oaMultipleData={oaMultipleData} selectProductPayment={selectProductPayment} productPayemntId={productPayemntId} handleProductPaymentIdChange={handleProductPaymentIdChange} />
            </TabPanel>



            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}
        </>
    );
}