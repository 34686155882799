import { React, useEffect, useState } from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    Popover,
    Grid,
    Paper,
    TextareaAutosize,
    Box,
    Typography,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import {  useLocation } from 'react-router-dom';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
import AppCache from '../../../services/AppCache';


// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));
export default function CopyAccessRightsListing() {

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

   
    const [isLoading, setIsLoading] = useState();
    const [hide, setHide] = useState(false);
    const [data, setData] = useState('')

    const [departmentId, setDepartmentId] = useState('')
    const [departMentData, setDepartmentData] = useState([])

    const [accessRightsFrom, setAccessRightsFrom] = useState('')
    const [accessRightsFromData, setAccessRightsFromData] = useState([])

    const [accessRightsTo, setAccessRightsTo] = useState('')
    const [accessRightsToData, setAccessRightsToData] = useState([])

    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const [userId, setUserId] = useState([]);
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

 
  
    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

    const handleDepartmentChange = (id) => {
        setDepartmentId(id);
        const payload = { ExecutiveLoginId :id}
        URLS.getExecutiveNameData(payload)
            .then((response) => {
                setAccessRightsFromData(response.data);
                setAccessRightsToData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    };

    const handleAccessRightFrom = (id) => {
        setAccessRightsFrom(id)
    }

    const handleAccessRightTo = (id) => {
        setAccessRightsTo(id)
    }
    const message = {
        forCancel: 'Do you want to Cancel? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            setDepartmentId('')
            setHide(false)
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    useEffect(() => {
        URLS.getDepartmentDropDown()
            .then((response) => {
                setDepartmentData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
       

    }, [])

    const formik = useFormik({
        initialValues: {
           
        },
        onSubmit: (values, actions) => {

           
        },
    });

    const handleReset = () => {
        setDepartmentId()
    }

    useEffect(() => {
        setDepartmentId()
    }, [location.key])

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

   
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Page title="Incident Tracker" >

                    <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} sm={6} md={3} >
                                <FormControl
                                    fullWidth
                                    size="small"

                                >
                                    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Department</InputLabel>

                                    <Select
                                        name="ProjectStatus"
                                        value={departmentId}
                                        label="Project Status"

                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#fbe9e7',
                                                    maxHeight: '200px',
                                                    width: '200px',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem key={0} value="" onClick={() => handleDepartmentChange()}>
                                            Select
                                        </MenuItem>
                                        {departMentData.map((project) => (
                                            <MenuItem key={project.Id} value={project.Id} onClick={() => handleDepartmentChange(project.Id)}> {project.TeamName}</MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                            </Grid>
                                <Grid item xs={12} sm={6} md={3} display="flex" alignItems="center">
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleReset}
                                    ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                            </Grid>
                        </Grid>

               
                    </Stack>
                    {/* ----------------------------------------------------------------- */}
                    {departmentId && (
                        <>
                            <div style={{ marginTop: '20px', paddingLeft: '20px' }} />
                            <div style={{ height: '54vh', width: '100%' }}>
                                <Stack spacing={3}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={4}>
                                        <FormControl
                                            fullWidth
                                            size="small"

                                        >
                                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Copy Access From</InputLabel>

                                            <Select
                                                name="Copy Access From"
                                                value={accessRightsFrom}
                                                label="Project Status"

                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            backgroundColor: '#fbe9e7',
                                                            maxHeight: '200px',
                                                            width: '200px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem key={0} value="" onClick={() => handleAccessRightFrom()}>
                                                    Select
                                                </MenuItem>
                                                {accessRightsFromData.map((project) => (
                                                    <MenuItem key={project.Id} value={project.Id} onClick={() => handleAccessRightFrom(project.Id)}> {project.Name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                       
                                        <FormControl
                                            fullWidth
                                            size="small"

                                        >
                                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Copy Access To</InputLabel>

                                            <Select
                                                name="Copy Access To"
                                                value={accessRightsTo}
                                                label="Project Status"

                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            backgroundColor: '#fbe9e7',
                                                            maxHeight: '200px',
                                                            width: '200px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem key={0} value="" onClick={() => handleAccessRightTo()}>
                                                    Select
                                                </MenuItem>
                                                {accessRightsToData.map((project) => (
                                                    <MenuItem key={project.Id} value={project.Id} onClick={() => handleAccessRightTo(project.Id)}> {project.Name}</MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </Stack>
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2} m={2}>

                            <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                sx={{ color: 'white' }}
                            >
                                Save
                            </LoadingButton>

                            <Button
                                sx={{ color: 'white' }}
                                onClick={handelCancelClick}
                                size="medium"
                                variant="contained"
                                color="primary"
                            >
                                Cancel
                        </Button>
                        
                        </Stack>
                    
                            </div>
                        </>


                    )}
                   
                      
                    {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}
                    {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}


                </Page>
            </Form>
        </FormikProvider>

    );
}