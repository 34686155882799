import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Button, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';

import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import AppCache from '../../../services/AppCache';
import URLS from '../../../services/urls.service';
import ModalPopUp from '../../Common/ModalPopUp';
import { handleError } from '../../Common/Utils';
// -----------------------------------------------------------------------------------------------------

export default function ApiMasterDetailsComponent({apiMaster} ) {
    console.log("apiMaster>>>>>", apiMaster)
    const navigate = useNavigate();
 
    // const productId = proMaster.ID;
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    // executive ID
    const [userId, setUserId] = useState([]);
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const apiIdRef = useRef(null)
    const [isFocused, setIsFocused] = useState(false);
    const [listData, setListData] = useState([])
    const [isCheckingAPIIdExist, setIsCheckingAPIIdExist] = useState(false);
  const [lastCheckedAPIId, setLastCheckedAPIId] = useState('');

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    // Modal
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const handleCancel = () => {
            setOpenModalCancel(true);
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/master/apimaster', { replace: true });
            setOpenModalCancel(false)
        } else {
            setOpenModalCancel(false)
        }
    }
    const apiSchema = Yup.object().shape({
        APIId: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('API is required'),
        APIMethodName: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('API method name is required')
    })

    const apiMasterId = apiMaster[0]?.Id ? apiMaster[0]?.Id : 0;
    console.log("apiMasterId", apiMasterId)

    const createdByVal = apiMasterId === 0 ? userId : null;
    const editedByVal = apiMasterId !== 0 ? userId : null;

    const formik = useFormik({
        initialValues: {
            Id: apiMasterId,
            APIId: (apiMaster && apiMaster.length > 0) ? apiMaster[0].APIId : '' ,
            APIMethodName: (apiMaster && apiMaster.length > 0) ? apiMaster[0].APIMethodName : '',
            APIAuthKey: (apiMaster && apiMaster.length > 0) ? apiMaster[0].APIAuthKey : '',
            APITableName: (apiMaster && apiMaster.length > 0) ? apiMaster[0].APITableName : '',
            APIPostProcessName: (apiMaster && apiMaster.length > 0) ? apiMaster[0].APIPostProcessName : '',
            CreatedBy: userId,
            EditedBy: userId,
            DeletedBy: null
        },
        validationSchema: apiSchema,
        onSubmit: (values, action) => {
            const checkPayload = {
                "APIMasterId": apiMasterId,
                "APIId": values.APIId
            }
            const payload = {
                Id: apiMasterId,
                APIId: values.APIId,
                APIMethodName: values.APIMethodName,
                APIAuthKey: values.APIAuthKey,
                APITableName: values.APITableName,
                APIPostProcessName: values.APIPostProcessName,
                CreatedBy: createdByVal,
                EditedBy: editedByVal,
                DeletedBy: null
            }
            console.log("save", payload)
                        URLS.saveAPIMaster(payload)
                            .then((response) => {
                                if (response.status === 200) {
                                    navigate('/dashboard/master/apimaster', { replace: true });
                                }
                                enqueueSnackbar("Data Saved successfully", { variant: 'success' });
                            })
        }
    })
    const handleCheckAPIIdExist = async () => {
        if (isCheckingAPIIdExist || openModalForCancel) {
            return;
        }
        const checkPayload = {
            "APIMasterId": apiMasterId,
            "APIId": formik.values.APIId
        };
        try {
            const response = await URLS.checkAPIIdExist(checkPayload);
            if (response.data.status === "false") {
                setFieldValue("APIId", formik.values.APIId.toUpperCase());
            } else {
                enqueueSnackbar("API Name Already exists");
                // Focus on API ID field
                if (apiIdRef.current) {
                    apiIdRef.current.focus();
                }
            }
        } catch (error) {
            console.error("Error while checking API Id existence:", error);
        }
        finally {
            setIsCheckingAPIIdExist(false)
        }
    };
    const fetchAPIMasterListingData = async () => {
        const payload = { APIMasterId: '' };
        try {
            const response = await URLS.getAPIMasterListingData(payload);
            setListData(response.data);
        } catch (error) {
            handleError(error, enqueueSnackbar);
        }
    };

    useEffect(() => {
        fetchAPIMasterListingData()
    }, [])

    const handleAPIIdFieldEdit = () => {
        if (formik.values.APIId !== lastCheckedAPIId) {
            handleCheckAPIIdExist();
            setLastCheckedAPIId(formik.values.APIId);
        }
    };
    useEffect(() => {
        if (apiMasterId !== 0) {
            const preselectedAPIId = apiMaster[0]?.APIId || ''; // Get the preselected APIId
            const isNewAPIIdAlreadyExist = listData.some(api => api.APIId === preselectedAPIId); // Check if the preselected APIId already exists in the fetched data
            if (isNewAPIIdAlreadyExist && preselectedAPIId !== formik.values.APIId) {
                // Call handleCheckAPIIdExist if the preselected APIId is different from the current APIId and already exists in the fetched data
                handleCheckAPIIdExist();
            }
        }
    }, [apiMasterId, listData, formik.values.APIId]);

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;


    return (
        <FormikProvider value={formik} onSubmit={handleSubmit}>
            <Form autoComplete="off" noValidate  >
                <Stack spacing={3}>
                    <Stack style={{ paddingRight:'52%' }}>
                        <TextField
                            fullWidth
                            size="small"
                            label="API Id"
                            {...getFieldProps('APIId')}
                            onChange={(e) => {
                                const uppercaseValue = e.target.value.toUpperCase();
                                formik.setFieldValue('APIId', uppercaseValue);
                            }}
                            error={Boolean(touched.APIId && errors.APIId)}
                            helperText={touched.APIId && errors.APIId}
                            onBlur={handleCheckAPIIdExist}
                            inputRef={apiIdRef}
                        />
                    </Stack>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>

                        <TextField
                            fullWidth
                            size="small"
                            label="API Method Name "
                            multiline
                            maxRows={2}
                            {...getFieldProps('APIMethodName')}
                            error={Boolean(touched.APIMethodName && errors.APIMethodName)}
                            helperText={ touched.APIMethodName && errors.APIMethodName}
                        />

                        <TextField
                            fullWidth
                            label="API Authentication Key"
                            size="small"
                            multiline
                            maxRows={2}
                            {...getFieldProps('APIAuthKey')}

                        />


                    </Stack>

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <TextField
                            fullWidth
                            label="API Table Name"
                            size="small"
                            multiline
                            maxRows={2}
                            {...getFieldProps('APITableName')}

                        />
                        <TextField
                            fullWidth
                            label="API Post Process Name"
                            size="small"
                            multiline
                            maxRows={2}
                            {...getFieldProps('APIPostProcessName')}

                        />


                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Save
                        </LoadingButton>
                      
                        
                        <Button
                            sx={{ color: 'white' }}
                            size="medium"
                            variant="contained"
                            color="primary"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </Form>
            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}
        </FormikProvider>
    );
}