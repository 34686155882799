/// <reference path="../components/common/utils.js" />
import { Link as RouterLink } from 'react-router-dom';
import { React, useEffect, useState } from 'react';

// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Typography, Grid, Stack, Card, Container, Box, Paper, MobileStepper, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import * as LocalStorage from 'local-storage';
import URLS from '../services/urls.service';
import AppCache from '../services/AppCache';
// hooks
// components
import Logo from '../components/Logo';
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';
import Utils from '../components/Common/Utils';



// ----------------------------------------------------------------------

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);



const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        backgroundColor: '#bcaaa4',
        height: '100%',
    }
}));


const ContentStyle = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 460,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 1),
    [theme.breakpoints.up('sm')]: {
        width: 430,
    },
}));


// ----------------------------------------------------------------------

export default function Login() {

    const imageFolderPath = Utils.commonImagePath;
    const [imagePath, setImagePath] = useState([{ "Id": 1, "LoginImageNamePath": "/static/illustrations/SmileServiceLoginImage.png" }]);
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = imagePath.length;
    const [hide, setHide] = useState(false);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    // Clear previous local storage data. 
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        // Clear previous local storage data. 
        AppCache.ClearStorage();
        LocalStorage.clear();

        // APIUrl calls
        URLS.configFileURL().then((response) => {
            if (response.status === 200) {
                const config = JSON.parse(JSON.stringify(response.data));
                // AppCache.SetCurrentAPIURL(config.SmileServiceApiBaseUrl);
                AppCache.SetCurrentAPIURL(config);
                setLoading(false);
            }
            else {
                // Failure response
                alert("Api URL  Failed");
            }
        })
        URLS.changeImageURL().then((response) => {
            if (response.status === 200) {
                setLoading(false);
                setImagePath(response.data);
                console.log("response.data Data", response.data)
            }
            else {
                // Failure response
                alert("Change Image Api URL  Failed");
            }
        })

    }, []);


    if (loading) { return <CircularProgress /> }


    return (
       
        <Page sx={{ backgroundColor: "#f5f5f5", py: 4, px: 4, height: '100%' }} title=" Login | tiketic" >
            <Card sx={{ maxWidth: '1000px', margin: 'auto', justifyContent: 'center' }} >

                <Stack direction={{ xs: 'column', sm: 'row' }}>
                    <Stack>
                   
                        <ContentStyle>
                            <Stack>
                                <Box marginLeft="20px">
                                    <Logo />
                                </Box>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={ 3}>
                                    { /* if APIbaseURL not found spinner will spin */}
                                    {loading ? (
                                        <CircularProgress />
                                    ) : (
                                        <LoginForm />
                                    )}
                                </Stack>
                               
                            </Stack>
                        </ContentStyle>
                        <Stack direction="row"  alignItems="center">
                            <Box component="img" src="/static/illustrations/SAPLLogo.jpg" sx={{
                                width: 50, height: 30, margin: 1, marginLeft: 3
                            }} />
                            <Typography sx={{ fontSize: '10px' }}>&copy;2023-Tiketic(2.0) Smile Automation Pvt.Ltd, Pune, India</Typography>
                            </Stack>
                    </Stack>
                  
                        <Stack>
                            <Box sx={{ maxWidth: 1000, flexGrow: 1 }}>
                                <Paper
                                    square
                                    elevation={0}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                       
                                        pl: 2,
                                        bgcolor: 'background.default',
                                    }}
                                />

                               
                            <AutoPlaySwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                                style={{ maxWidth: '100%', overflowX: 'hidden' }} // Ensure images don't cause overflow
                            >
                                {imagePath.map((step, index) => (
                                    <div key={step.Id}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: '80vh',
                                                    display: 'block',
                                                    maxWidth: '100%', // Allow images to scale down if needed
                                                    width: 'auto',    // Let images adjust width proportionally
                                                    padding: '10px',  // Add padding here
                                                }}
                                                src={step.LoginImageNamePath}
                                                alt={step.Id}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>


                                <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    activeStep={activeStep}
                                    nextButton={
                                        <Button
                                            size="small"
                                            onClick={handleNext}
                                            disabled={activeStep === maxSteps - 1}
                                        >
                                            Next
                                            {theme.direction === 'rtl' ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            )}
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                            {theme.direction === 'rtl' ? (
                                                <KeyboardArrowRight />
                                            ) : (
                                                <KeyboardArrowLeft />
                                            )}
                                            Back
                                        </Button>
                                    }
                                />
                            </Box>

                        </Stack>
                    </Stack>
                </Card>
           
        </Page>
    );
}