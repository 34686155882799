import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, Typography, Modal, FormControl, InputLabel, Select, MenuItem, Button, Grid } from '@mui/material';
import { startOfMonth, endOfMonth } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
// -----------------------------------------------------------------------------------------------------


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#fbe9e7', // Set the background color to #fbe9e7
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function FillInstallationReportDetails({ fillInstallation }) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // const productId = proMaster.ID;



    // executive ID
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);


    const InstallationStatusData = [
        { Id: 1, value: 'Completed' },
        { Id: 2, value: 'Cancelled' }
    ];
    const [insStatusId, setInsStatusId] = useState('');
    const handleInsStatus = (value) => {
        setInsStatusId(value);

    };
    // Modal
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {

        navigate('/dashboard/oa/fillintallationreport', { replace: true });
        setOpenModalCancel(false);


    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

    // dropdown
    const [clients, setClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('');
    const handleClient = (id) => {
        setSelectedClientId(id);

    };

    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));

    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };


    const [activityData, setActivityData] = useState([]);
    const [activityDataValue, setActivityDataValue] = useState('');
    const handleActivity = (id) => {
        setActivityDataValue(id);

    };
    useEffect(() => {
        URLS.getClientLandingPageGenerateReportUI()
            .then((response) => {

                setClients(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getActivityDD()
            .then((response) => {

                setActivityData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });



    }, []);

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const [selectedTime, setSelectedTime] = useState(new Date());

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
    };

    const formik = useFormik({
        initialValues: {

            ClientId: selectedClientId,
            ProductId: fillInstallation.ProductId,
            SiteId: fillInstallation.Issueid,
            InstallationStatus: insStatusId,
            InstallationStatusDate: effectiveFromValue,
            InstallationRemark: fillInstallation.InstallationRemark,
        },

        onSubmit: (values, actions) => {



            // const payload = {
            //    "ProductId": selectedProductsId,
            //    "NonCommercialId": nonCommercialProductIds,
            //    "Remark": values.Remark,
            //    "ExecutiveID": userId,
            // };
            // URLS.getProductNameValidation(productNamePayload)
            //    .then((response) => {
            //        if (response.data.status === "false") {
            //            URLS.postProductMaster(payload)
            //                .then((response) => {
            //                    if (response.status === 200) {
            //                        navigate('/dashboard/master/productmaster', { replace: true });

            //                    }
            //                    enqueueSnackbar("Record saved successfully", { variant: 'success' });
            //                })
            //                .catch(error => {
            //                    handleError(error, enqueueSnackbar);
            //                });
            //        } else {
            //            enqueueSnackbar("Product Name Already exists");
            //        }
            //    })
            //    .catch(error => {
            //        handleError(error, enqueueSnackbar);
            //    });
            console.log("payload");
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;



    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={4}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography>Client Name:</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>Product Name:</Typography>
                                </Grid>
                                <Grid item xs={2.3}>
                                    <Typography>Site Name:</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction="row" spacing={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Installation Status</InputLabel>
                                    <Select
                                        label="Installation Status"
                                        size="small"
                                        value={insStatusId}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#fbe9e7',
                                                    maxHeight: '200px',
                                                    width: '200px',
                                                },
                                            },
                                        }}>
                                        <MenuItem key={0} value={""} onClick={() => handleInsStatus()}>Select</MenuItem>
                                        {InstallationStatusData.map((product) => (
                                            <MenuItem key={product.Id} value={product.Id} onClick={() => handleInsStatus(product.Id)}> {product.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Installation Date"
                                        inputFormat="dd/MMM/yyyy"
                                        value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                                        onChange={handleEffFrom}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </LocalizationProvider>
                                <TextField fullWidth label="Installation Remark" size="small" />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>



                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography>Service Details</Typography>
                                </Grid>

                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={4}>
                                <Stack direction="row" spacing={2}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Activity</InputLabel>
                                        <Select
                                            label="Activity"
                                            size="small"
                                            value={activityDataValue}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#fbe9e7',
                                                        maxHeight: '200px',
                                                        width: '200px',
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem key={0} value={""} onClick={() => handleClient()}>Select</MenuItem>
                                            {/* Assuming activityData is an array */}
                                            {activityData.map((product) => (
                                                <MenuItem key={product.Id} value={product.Id} onClick={() => handleClient(product.Id)}>
                                                    {product.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <TimePicker
                                            label="Installation Time"
                                            value={selectedTime}
                                            onChange={(newTime) => handleTimeChange(newTime)}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <TextField fullWidth label="Service In Min" size="small" />
                                    <TextField fullWidth label="Remark" size="small" />
                                </Stack>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>



                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Save
                        </LoadingButton>

                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Back
                        </Button>
                    </Stack>
                </Stack>


            </Form>
            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider >
    );
}