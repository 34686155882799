// routes
import { SnackbarProvider, useSnackbar } from 'notistack'
import Router from './routes';

// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

// ----------------------------------------------------------------------

export default function App() {
    return (
        <SnackbarProvider>
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
            </ThemeProvider>
            </SnackbarProvider>
  );
}
