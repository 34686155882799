import axios from 'axios';
import { dashBoardMenuUrl } from './config';

// // const API_URL = './api/';
// const API_URL = commonurl.API_URL;
const headers = { 'content-type': 'application/json' };

class DashBoardMenuService {
    constructor() {
        this.dashBoardMenu = (payload) => (axios.post(`${dashBoardMenuUrl}`, payload, headers));
    }
}
export default new DashBoardMenuService();
