import * as React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Button, Box, Typography, Tabs, Tab, TextField, Checkbox, FormControl, MenuItem, TextareaAutosize,InputLabel, Select, Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Moment from 'moment';
import Scrollbar from '../../../Scrollbar';
import ProjectServerDrives from '../../../../pages/SidebarMenus/OtherMenu/ProjectInformation/ProjectServerDrives';


const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #4e342e',
   
   
    position: 'sticky',


    '& .MuiTabs-indicator': {
       
    },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),

    color: '#b71c1c',
    Transition: '2s',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#4e342e',
        
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4e342e',
        
        fontWeight: theme.typography.fontWeightMedium,
    
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#fff',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function ProjectInformationDetailsComponent({ serverProData, clientAliasdropDownData, formik, handleDomain, DomainExpiry, SSLExpiry, AgreementExpiry, PurchaseOrderExpiry, handleSSLExpiry, handleAgreement, handlePurchase, clientId, clientName, clientAlias, handelSelectedClient, disable, serviceProvider, isCheckedActive, handleIsActiveChange}) {


    console.log("clientName", clientName);
    console.log("clientAlias", clientAlias);

  
   
    // const [clientNameData, setClientNameData] = useState("");

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    };

    

    // const handleDataChange = (ev) => {

    //    const val = ev.target.value;
    //    setclientAliasdropDownData(val);

    //    const component = clientAliasdropDownData[val];
    //    if (component) {
    //        setClientNameData(component.Name );
    //    }

    // }


    const { handleSubmit, getFieldProps, errors, touched } = formik;

    return (


        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <AntTabs  value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example">
                    <AntTab label="Project Information" {...a11yProps(0)} />
                    <AntTab label="Project Servers" {...a11yProps(1)} />
                    <AntTab label="Project Server Drives" {...a11yProps(2)} />
                </AntTabs >
            </Box>
            <Scrollbar>
                
                    <TabPanel value={value} index={0}>
                        <Grid container spacing={2} >

                            <Grid item xs={6} sm={6} md={5}>
                                {disable ? <Box marginBottom={2}> <Typography sx={{ justifyContent: "right", ml: 2 }} value={clientAlias} variant="subtitle1">ERP Code: {clientAlias}</Typography> </Box> :
                                <FormControl
                                        fullWidth
                                    size="small"
                                >
                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Client Alias</InputLabel>
                                    <Select sx={{ marginBottom: 2 }}

                                        value={clientAlias}
                                        label="ClientAlias"
                                        size="small"

                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#fbe9e7', // Set the background color here
                                                    maxHeight: '200px',
                                                    width: '200px',// Set the max height here
                                                }
                                            }
                                        }}
                                    >
                                        <MenuItem key={0} value={""}>Select</MenuItem>
                                        {clientAliasdropDownData.map((clientAliasRecord) => (
                                            <MenuItem key={clientAliasRecord.ERPCode} value={clientAliasRecord.ERPCode} onClick={() => handelSelectedClient(clientAliasRecord.Id, clientAliasRecord.ERPCode, clientAliasRecord.Name)}> {clientAliasRecord.ERPCode}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>}
                        </Grid>
                        {/* <label onChange={(ev) => setClientNameData(ev.target.value)} value={clientNameData}></label> */}
                        <Grid item xs={6} sm={6} md={3}>
                            <Box>
                                    <Typography sx={{ justifyContent: "right" }} variant="subtitle1" value={clientName}> Name: {clientName}</Typography></Box>
                        </Grid>
                            </Grid>
                        <Stack justifyContent="center" spacing={2}>

                            <Stack direction={{xs:'column', md:'row'}} spacing={2}>
                                <TextField size="small" id="outlined-basic" label="Project URL" variant="outlined" fullWidth  {...getFieldProps('ProjectURL')}
                                    helperText={touched.ProjectURL && errors.ProjectURL}
                                    error={Boolean(touched.ProjectURL && errors.ProjectURL)} />
                                <TextField size="small" id="outlined-basic" label="Project Version"  variant="outlined" fullWidth {...getFieldProps('ProjectVersion')}
                                    helperText={touched.ProjectVersion && errors.ProjectVersion}
                                    error={Boolean(touched.ProjectVersion && errors.ProjectVersion)} />
                                <TextField size="small" id="outlined-basic" label="Project Domain" fullWidth variant="outlined" {...getFieldProps('ProjectDomain')}
                                    helperText={touched.ProjectDomain && errors.ProjectDomain}
                                    error={Boolean(touched.ProjectDomain && errors.ProjectDomain)} />
                            </Stack>

                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                <TextField size="small" id="outlined-basic" label="SSL Client" variant="outlined" fullWidth {...getFieldProps('SSLClient')}
                                    helperText={touched.SSLClient && errors.SSLClient}
                                    error={Boolean(touched.SSLClient && errors.SSLClient)} />
                               
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="SSL Expiry"
                                        inputFormat="dd/MMM/yyyy"
                                        value={Moment(SSLExpiry).format('DD-MMM-yyyy')}
                                        onChange={handleSSLExpiry}
                                        renderInput={(params) => <TextField fullWidth size="small"  {...params} />}
                                    />
                                </LocalizationProvider>
                                <TextField size="small" id="outlined-basic" label="Https Upload URL" variant="outlined" fullWidth {...getFieldProps('HttpsUploadURL')}
                                    helperText={touched.HttpsUploadURL && errors.HttpsUploadURL}
                                    error={Boolean(touched.HttpsUploadURL && errors.HttpsUploadURL)} />
                            </Stack>
                           
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                <TextField size="small" id="outlined-basic" label="Web API URL" variant="outlined" fullWidth {...getFieldProps('WebAPIURL')}
                                    helperText={touched.WebAPIURL && errors.WebAPIURL}
                                    error={Boolean(touched.WebAPIURL && errors.WebAPIURL)} />
                               
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Domain Expiry"
                                        inputFormat="dd/MMM/yyyy"
                                        value={Moment(DomainExpiry).format('DD-MMM-yyyy')}
                                        onChange={handleDomain}

                                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                              
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Agreement Expiry"
                                        inputFormat="dd/MMM/yyyy"
                                        value={Moment(AgreementExpiry).format('DD-MMM-yyyy')}
                                        onChange={handleAgreement}
                                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                               

                                <FormControl
                                    fullWidth
                                    size="small"
                                >
                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Server Provider</InputLabel>
                                    <Select
                                        label="Server Provider"
                                        size="small"
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7', // Set the background color here
                                                maxHeight: '200px',
                                                width: '200px',// Set the max height here
                                            }
                                        }
                                    }}
                                        {...getFieldProps('ServerProvider')}
                                    >
                                        <MenuItem key={0} value={""}>Select</MenuItem>

                                        {serverProData.map((server) => (

                                            <MenuItem key={server.Id} value={server.Name} > {server.Name}</MenuItem>
                                        ))}
                                    </Select>


                                </FormControl>

                                <FormControl fullWidth size="small">
                                    <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                        Service Provider
                                    </InputLabel>
                                    <Select
                                        label="Service Provider"
                                        size="small"
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7', // Set the background color here
                                                maxHeight: '200px',
                                                width: '200px',// Set the max height here
                                            }
                                        }
                                    }}
                                        {...getFieldProps('ServiceProvider')}
                                    >
                                        <MenuItem key={0} value={""}>
                                            Select
                                        </MenuItem>
                                        {serviceProvider.map((server) => (
                                            <MenuItem key={server.Id} value={server.Name}>
                                                {server.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                              
                            
                                <TextField size="small" id="outlined-basic" label="Server Ownership" variant="outlined" fullWidth  {...getFieldProps('ServerOwnership')}
                                    helperText={touched.ServerOwnership && errors.ServerOwnership}
                                    error={Boolean(touched.ServerOwnership && errors.ServerOwnership)} />
                               

                            </Stack>
                         
                            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                <TextField type="number" size="small" id="outlined-basic" label="Purchase Order Number" variant="outlined" fullWidth  {...getFieldProps('PurchaseOrderNo')}
                                    helperText={touched.PurchaseOrderNo && errors.PurchaseOrderNo}
                                    error={Boolean(touched.PurchaseOrderNo && errors.PurchaseOrderNo)} />
                               
                               
                                <TextField size="small" id="outlined-basic" label="Purchase Order Period" fullWidth variant="outlined"  {...getFieldProps('PurchaseOrderPeriod')}
                                    helperText={touched.PurchaseOrderPeriod && errors.PurchaseOrderPeriod}
                                    error={Boolean(touched.PurchaseOrderPeriod && errors.PurchaseOrderPeriod)} />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Purchase Order Expiry"
                                        inputFormat="dd/MMM/yyyy"
                                        value={Moment(PurchaseOrderExpiry).format('DD-MMM-yyyy')}
                                        onChange={handlePurchase}
                                        renderInput={(params) => <TextField fullWidth size="small"  {...params} />}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            <Grid container spacing={4}>
                                <Grid item xs={6} sm={4} md={3.7} style={{ paddingLeft: 0, paddingTop: 0 }}>
                                    <TextField size="small" id="outlined-basic" label="Zylem DB Name" variant="outlined" fullWidth {...getFieldProps('ZylemDBName')}
                                        helperText={touched.ZylemDBName && errors.ZylemDBName}
                                        error={Boolean(touched.ZylemDBName && errors.ZylemDBName)} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={4} style={{ paddingTop: 0 }} >
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isCheckedActive} onChange={handleIsActiveChange} />} label="IsActive" />
                                    </FormGroup>
                                </Grid>
                            </Grid>


                        </Stack>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <Stack justifyContent="center" spacing={2}>
                            <Stack direction="row" spacing={4}>
                                <TextField size="small" id="outlined-basic" label="Server Name" variant="outlined" fullWidth {...getFieldProps('ServerName')}
                                    helperText={touched.ServerName && errors.ServerName}
                                    error={Boolean(touched.ServerName && errors.ServerName)} />
                                <TextField size="small" id="outlined-basic" label="Server IP"  variant="outlined" fullWidth  {...getFieldProps('ServerIP')}
                                    helperText={touched.ServerIP && errors.ServerIP}
                                    error={Boolean(touched.ServerIP && errors.ServerIP)} />
                            </Stack>
                            <Stack direction="row" spacing={4}>
                                <TextField size="small" id="outlined-basic" label="Public IP"  variant="outlined" fullWidth {...getFieldProps('PublicIP')}
                                    helperText={touched.PublicIP && errors.PublicIP}
                                    error={Boolean(touched.PublicIP && errors.PublicIP)} />
                                

                                <TextareaAutosize
                                    minRows={3}
                                    placeholder="Configuration Details"
                                    fullWidth variant="outlined" {...getFieldProps('ConfigurationDetails')}
                                    helperText={touched.ConfigurationDetails && errors.ConfigurationDetails}
                                    error={Boolean(touched.ConfigurationDetails && errors.ConfigurationDetails)}
                                    style={{
                                        width: "100%",
                                        fontFamily: "Tahoma",
                                        fontSize: "12px",
                                    }}
                                />

                            </Stack>
                        </Stack>
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <ProjectServerDrives />
                    </TabPanel>
                
            </Scrollbar>
        </Box>




    );
}