/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { differenceInDays } from 'date-fns';
import { Tooltip, IconButton, Stack, TextField, Typography, Modal, FormControl, InputLabel, Select, MenuItem, Button, Box } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
import typography from '../../../theme/typography';
// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function RequestMasterDetailsComponent({ request, isDisable, isActions, requestedId, statusId, fromDate, toDate, isEdit }) {
    console.log("request", request);
    const TABLE_HEAD = [
        { field: 'LevelNo', headerName: 'Level Number', width: 150 },
        { field: 'ApproverName', headerName: 'Approved By', width: 150 },
        {
            field: 'ApproverDate',
            headerName: 'Approved DateTime',
            width: 200,
            valueFormatter: params => {
                const dateValue = params?.value;
                return dateValue ? Moment(dateValue).format("DD-MMM-yyyy, HH:mm") : '';
            }
        },
        { field: 'ApproverRemark', headerName: 'Approver Remark', width: 150 },
        { field: 'RequestStatus', headerName: 'Status', width: 150 },
    ];

    const TABLE_HEAD1 = [
        { field: 'Name', headerName: 'Next Approver', width: 150 },
        { field: 'Status', headerName: 'Status', width: 150 },
       
        



    ];


    const { enqueueSnackbar } = useSnackbar();
    const [requestMasterData, setRequestMasterData] = useState([]);
    const [requestStatusData, setRequestStatusData] = useState([]);
    const [requestApprovedData, setRequestApprovedData] = useState([]);
    const [getNextApproverData, setGetNextApproverData] = useState([]);
    const [checkApproved, setCheckApproved] = useState('');
    const navigate = useNavigate();
    const [requestCategory, setRequestCategory] = useState([]);
    const [requestId, setRequestId] = useState(request.RequestCategoryId);
    const [startTime, setStartTime] = useState('');
    const [isExpiry, setIsExpiry] = useState(false);
    const [approverRemark, setapproverRemark] = useState('')
    const [isDisableDate, setIsDisableDate] = useState(false);
    const handelChangeRequestStatusSelect = () => {
        setRequestId('');
    }

    const handleEditDate = () => {
        setIsDisableDate(false);
    }


    const handleRemarkChange = (event) => {
        setapproverRemark(event.target.value);
    }

    const handleSelectedRequest = (categoryId, expiry) => {
        setIsExpiry(true);
        setIsFromDateToDate(true);
        setRequestId(categoryId);
       
    };
    // Dates
    const handleStartTime = (event) => {
        const inputValue = event.target.value;

        // Regular expression to allow only digits
        const regex = /^[0-9]*$/;

        // Check if the input value matches the regex
        if (regex.test(inputValue)) {
            // Update the state with the valid input value
            setStartTime(inputValue);
        }
        // If the input value doesn't match the regex, do nothing (don't update state)
    };

    const reqStatusId = statusId;
    const [effectiveFromValue, setEffectiveFromValue] = useState(request.AccessFromDate);
    const [effectiveToValue, setEffectiveToValue] = useState(request.AccessToDate);
    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };

    // const currentFromDateTime = Moment(effectiveFromValue).format('DD-MMM-yyyy hh:mm A');
    // const currentToDateTime = Moment(effectiveToValue).format('DD-MMM-yyyy hh:mm A');

    // Date minutes
    const [fromDateValue, setFromDateValue] = useState(request.AccessFromDate);
    const handleFromChange = (newValue) => {
        setFromDateValue(newValue)
    };


    // Calculations
    const calculatedDateTime = Moment(fromDateValue).add(startTime, 'minutes');



    // checkboxes
    const [isFromDateToDate, setIsFromDateToDate] = useState(false || request.IsFromDate);
    const [isInMinutes, setIsInMinutes] = useState(false || request.IsInMin);
    const handleisFromDateToDateChange = (event) => {
        setIsFromDateToDate(event.target.checked);
        setIsInMinutes(false);

    };


    const handleIsInMinutesChange = (event) => {
        setIsInMinutes(event.target.checked);
        setIsFromDateToDate(false);
    };



    const [dateRange, setDateRange] = useState([]);

    // executive ID
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        if (request.Id === '') {
            setIsExpiry(false);
        } else {
            const fromDate = new Date(request.AccessFromDate);
            const toDate = new Date(request.AccessToDate);
            const timeDifferenceHours = Math.abs(toDate - fromDate) / 36e5; // 1 hour = 3600000 milliseconds

            // Convert time difference to minutes
            const timeDifferenceMinutes = timeDifferenceHours * 60;
            // Store the calculated minutes in the startTime state
            setStartTime(timeDifferenceMinutes);
            setIsExpiry(true);
        }

        setUserId(CurrentLoggedInUser.ExecutiveId);
        const LoginId = CurrentLoggedInUser.ExecutiveId;
        const payload = { ExecutiveID: LoginId }
        URLS.getRequestMasterDropDownLandingPage(payload)
            .then((response) => {

                setRequestMasterData(response.data);

            })
            .catch(error => {
                handleError(error);
            });

    }, []);


    const requestMastereditId = requestedId;
    const dateFromDate = fromDate;
    const dateToDate = toDate;
    // Modal
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value, requestedId, statusId) => {
        if (value) {
            navigate('/dashboard/requestandapproval/requestmaster', { state: { requestedId: requestMastereditId, statusId: reqStatusId, fromDate: dateFromDate, toDate: dateToDate } });
            setOpenModalCancel(false);
        } else {
            setOpenModalCancel(false);
        }
    };


    const handelCancel = (value) => {
        setOpenModalCancel(true);
        if (value) {

            navigate('/dashboard/requestandapproval/requestapproval', { replace: true });
        }

    };
    const [differenceInDays, setDifferenceInDays] = useState(0);
    const [differenceInMinutes, setDifferenceInMinutes] = useState(0);
    useEffect(() => {
        const dateDifference = Moment(effectiveToValue).diff(effectiveFromValue, 'days');
        const minDifference = Moment(effectiveToValue).diff(effectiveFromValue, 'minutes');

        setDifferenceInDays(dateDifference);
        setDifferenceInMinutes(minDifference);

    }, [effectiveFromValue, effectiveToValue]);



    useEffect(() => {
        setIsDisableDate(true);
        if (requestId !== null && !isEdit && !isDisable && !isActions) {
            const payloadDate = { RequestID: requestId };

            URLS.getRequestDateRange(payloadDate)
                .then((response) => {
                    setDateRange(response.data);

                    // Check the ExpiryAlert value and update checkboxes and effectiveToValue accordingly
                    if (response.data.length > 0) {
                        const expiryAlert = response.data[0].ExpiryAlert;
                        const parts = expiryAlert.split(":");
                        if (parts.length === 2) {
                            const alertType = parts[0];
                            const alertValue = parseInt(parts[1], 10);
                            setStartTime(alertValue.toString());
                            // Set checkboxes and effectiveToValue based on alertType
                            if (alertType === "D") {
                                setIsFromDateToDate(true);
                                setIsInMinutes(false);
                            } else if (alertType === "M") {
                                setIsFromDateToDate(false);
                                setIsInMinutes(true);
                            }

                            // Calculate newEffectiveToValue based on alertType
                            const newEffectiveToValue = alertType === "D"
                                ? Moment(effectiveFromValue).add(alertValue, 'days').toDate()
                                : Moment(effectiveFromValue).add(alertValue, 'minutes').toDate();

                            // Update state variables and differences
                            setEffectiveToValue(newEffectiveToValue);

                            const daysDifference = Moment(newEffectiveToValue).diff(effectiveFromValue, 'days');
                            const minutesDifference = Moment(newEffectiveToValue).diff(effectiveFromValue, 'minutes');

                            setDifferenceInDays(daysDifference);
                            setDifferenceInMinutes(minutesDifference);

                        }
                    }

                })
                .catch(error => {
                    handleError(error);
                });
        }
    }, [requestId, effectiveFromValue]);





    useEffect(() => {

        setUserId(CurrentLoggedInUser.ExecutiveId);
        const LoginId = CurrentLoggedInUser.ExecutiveId;
        const payload = { ExecutiveID: LoginId }
        URLS.getRequestCategoryMasterDropDown(payload)
            .then((response) => {

                setRequestCategory(response.data);

            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });

        const LoginId1 = CurrentLoggedInUser.ExecutiveId;
        const payload1 = { ExecutiveID: LoginId1, RequestCategoryID: request.Id }
        const payload3 = { ExecutiveID: LoginId1, RequestCategoryID: request.Id, RequestedByID: request.RequestedById }
        URLS.getRequestApprovalStatusData(payload3)
            .then((response) => {

                setRequestStatusData(response.data);

            })
            .catch(error => {
                handleError(error);
            });
        URLS.checkApprovedStatus(payload1)
            .then((response) => {
                if (response.status === true) {
                    setCheckApproved(response.data);
                }

                setCheckApproved(response.data);

            })
            .catch(error => {
                handleError(error);
            });
        const payload2 = { RequestCategoryID: request.Id }
        URLS.getApprovedData(payload2)
            .then((response) => {

                setRequestApprovedData(response.data);

            })
            .catch(error => {
                handleError(error);
            });
        
        URLS.getNextApproveStatus(payload3)
            .then((response) => {

                setGetNextApproverData(response.data);

            })
            .catch(error => {
                handleError(error);
            });
    }, []);

    const RequestMasterSchema = Yup.object().shape({
        Remarks: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Remark is required'),

    });
    const { AccessFromDate, AccessToDate } = request;

    // Convert the string dates to JavaScript Date objects
    const fromDate1 = new Date(AccessFromDate);
    const toDate1 = new Date(AccessToDate);

    // Calculate the difference in days
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const differenceInDays1 = Math.round(Math.abs((toDate1 - fromDate1) / oneDay));
    const oneMinute = 60 * 1000; // 60 seconds * 1000 milliseconds
    const differenceInMinutes1 = Math.round(Math.abs((toDate1 - fromDate1) / oneMinute));
    const formik = useFormik({
        initialValues: {
            Id: request.Id,
            RequestCategoryId: requestId,
            Remarks: request.Remarks,
            AccessFromDate: isFromDateToDate ? effectiveFromValue : fromDateValue,
            AccessToDate: isFromDateToDate ? effectiveToValue : calculatedDateTime,
            RequestedById: userId,
            IsFromDate: isFromDateToDate,
            IsInMin: isInMinutes,
            RequestStatusId: 2
        },
        validationSchema: RequestMasterSchema,
        onSubmit: (values, actions) => {

            const payload = {
                "Id": request.Id,
                "RequestCategoryId": requestId,
                "Remarks": values.Remarks,
                "AccessFromDate": isFromDateToDate ? effectiveFromValue : fromDateValue,
                "AccessToDate": isFromDateToDate ? effectiveToValue : calculatedDateTime,
                "RequestedById": userId,
                "IsFromDate": isFromDateToDate,
                "IsInMin": isInMinutes,
                "RequestStatusId": 2
            };
            if (requestId.length === 0) {
                enqueueSnackbar("Please select Request Category");
            } else {
                URLS.postSaveRequestSlipMaster(payload)
                    .then((response) => {
                        if (response.status === 200) {
                            navigate('/dashboard/requestandapproval/requestmaster', { replace: true });

                        }
                        enqueueSnackbar("Request Raised successfully", { variant: 'success' });
                    })
                    .catch(error => {
                        handleError(error, enqueueSnackbar);
                    });
            }
            


        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;


    const handleApprove = () => {
        const payload = {
            Id: request.Id,
            RequestCategoryId: requestId,
            LevelNo: request.LevelNo,
            ApproverId: userId,
            Remarks: request.Remarks,
            AccessFromDate: isFromDateToDate ? effectiveFromValue : fromDateValue,
            AccessToDate: isFromDateToDate ? effectiveToValue : calculatedDateTime,
            Status: 1,
            ApproverRemark: approverRemark,
            RequestedById: request.RequestedById,
            RequestedDate: request.CreatedDate,
            RequestStatusId: 2,
        }
      
        URLS.postRequestSlipDetails(payload)
            .then((response) => {

                navigate('/dashboard/requestandapproval/requestapproval', { replace: true });
                enqueueSnackbar("Request Approved successfully", { variant: 'success' });
            })
            .catch(error => {
                handleError(error);
            });
    }

    const handelCancelClick = () => {
        setOpenModalCancel(true);
            navigate('/dashboard/requestandapproval/requestmaster', { state: { requestedId: requestMastereditId, statusId: reqStatusId, fromDate: dateFromDate, toDate: dateToDate } });

    };
    const handleReject = () => {
        const payload = {
            Id: request.Id,
            RequestCategoryId: requestId,
            LevelNo: request.LevelNo,
            ApproverId: userId,
            Remarks: request.Remarks,
            AccessFromDate: isFromDateToDate ? effectiveFromValue : fromDateValue,
            AccessToDate: isFromDateToDate ? effectiveToValue : calculatedDateTime,
            Status: 2,
            ApproverRemark: approverRemark,
            RequestedById: request.RequestedById,
            RequestedDate: request.CreatedDate,
            RequestStatusId: 4
        }
        URLS.postRejectRequestSlipDetails(payload)
            .then((response) => {

                navigate('/dashboard/requestandapproval/requestapproval', { replace: true });
                enqueueSnackbar("Request Rejected", { variant: 'success' });
            })
            .catch(error => {
                handleError(error);
            });
    }
    let approveButton;
    let rejectButton;
    let cancelButton;

    if (isActions) {
        if (checkApproved && request.NextApproverId === userId) {
            approveButton = (
                <LoadingButton
                    size="medium"
                    variant="contained"
                    sx={{ color: 'white' }}
                    onClick={handleApprove}
                >
                    Approve
                </LoadingButton>
            );
            rejectButton = (
                <Button
                    onClick={handleReject}
                    sx={{ color: 'white' }}
                    size="medium"
                    variant="contained"
                    color="primary"
                >
                    Reject
                </Button>
            );
            cancelButton = (
                <Button
                    sx={{ color: 'white' }}
                    onClick={handelCancelClick}
                    size="medium"
                    variant="contained"
                    color="primary"
                >
                    Cancel
                </Button>
            );
        } else {
            approveButton = (
                <LoadingButton
                    disabled // or set it to false if you want to enable the button
                    size="medium"
                    variant="contained"
                    sx={{ color: 'white' }}
                >
                    Approve
                </LoadingButton>
            );
            rejectButton = (
                <Button
                    disabled // or set it to false if you want to enable the button
                    onClick={handleReject}
                    sx={{ color: 'white' }}
                    size="medium"
                    variant="contained"
                    color="primary"
                >
                    Reject
                </Button>
            );
            cancelButton = (
                <Button
                    sx={{ color: 'white' }}
                    onClick={handelCancelClick}
                    size="medium"
                    variant="contained"
                    color="primary"
                >
                    Cancel
                </Button>
            );
        }
    } else {
        approveButton = (
            <LoadingButton
                disabled={isDisable}
                size="medium"
                type="submit"
                variant="contained"
                sx={{ color: 'white' }}
            >
                Save
            </LoadingButton>
        );
        rejectButton = (
            <Button
                sx={{ color: 'white' }}
                onClick={handelCancelClick}
                size="medium"
                variant="contained"
                color="primary"
            >
                Cancel
            </Button>
        );
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={1}>
                    <Stack direction="row" spacing={2}>

                        {isActions ? (
                            <Box sx={{ width: '230px' }}>
                                {/* <Typography  variant="subtitle1"> */}
                                {/*    Request:{request.Name} */}
                                {/* </Typography> */}
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Request Category :</span> {request.Name}
                                </Typography>
                            </Box>
                        ) : (
                            <FormControl disabled={isDisable} fullWidth size="small">
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                    Request Category
                                </InputLabel>
                                    <Select
                                        disabled={isEdit}
                                    label="Request Category"
                                    size="small"
                                    value={requestId}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7', // Set the background color here
                                                maxHeight: '200px',
                                            },
                                        },
                                    }}>
                                        <MenuItem key={0} value={""} onClick={() => handelChangeRequestStatusSelect()}>
                                        Select
                                    </MenuItem>
                                    {requestCategory.map((req) => (
                                        <MenuItem
                                            key={req.CategoryId}
                                            value={req.CategoryId}
                                            onClick={() => handleSelectedRequest(req.CategoryId, req.IsExpiryApplicable)}
                                        >
                                            {req.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {isActions ? (
                            <Box sx={{ width: '350px' }}>
                                <Typography variant="subtitle1">
                                    Remark:{request.Remarks}
                                </Typography>
                            </Box>
                        ) : (
                            <TextField
                                disabled={isDisable}
                                fullWidth
                                label="Remark"
                                size="small"
                                {...getFieldProps('Remarks')}
                                error={Boolean(touched.Remarks && errors.Remarks)}
                                helperText={touched.Remarks && errors.Remarks}
                            />
                        )}
                    </Stack>
                    {isExpiry ?
                        <>
                            <Stack spacing={12} direction="row">
                               
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={isDisable || isActions}
                                                    defaultChecked
                                                    checked={isFromDateToDate}
                                                    onChange={handleisFromDateToDateChange}
                                                />
                                            }
                                            label="FromDate-ToDate"
                                        />
                                    </FormGroup>
                               


                                
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={isDisable || isActions}
                                                    checked={isInMinutes}
                                                    onChange={handleIsInMinutesChange}
                                                />
                                            }
                                            label="In Minutes"
                                        />
                                    </FormGroup>
                                

                                {isEdit || isDisable || isActions ?
                                    (isFromDateToDate ?
                                        <Tooltip title={`This Request Category has been applicable for ${differenceInDays1} days`}>
                                            <Icon icon="ic:baseline-info" width="20px" height="20px" style={{ marginTop: '11px' }} />
                                        </Tooltip> :
                                        <Tooltip title={`This Request Category has been applicable for ${differenceInMinutes1} Minutes`}>
                                            <Icon icon="ic:baseline-info" width="20px" height="20px" style={{ marginTop: '11px' }} />
                                        </Tooltip>
                                    ) :
                                    (
                                        isFromDateToDate ?
                                            <Tooltip title={`This Request Category has been applicable for ${differenceInDays} days`}>
                                                <Icon icon="ic:baseline-info" width="20px" height="20px" style={{ marginTop: '11px' }} />
                                            </Tooltip> :
                                            <Tooltip title={`This Request Category has been applicable for ${differenceInMinutes} Minutes`}>
                                                <Icon icon="ic:baseline-info" width="20px" height="20px" style={{ marginTop: '11px' }} />
                                            </Tooltip>
                                    )
                                }


                            </Stack>




                            {isFromDateToDate ?
                                <Stack direction="row" spacing={2}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            disabled={isDisable || isDisableDate}
                                            label="Access From"
                                            inputFormat="dd/MMM/yyyy hh:mm aa"
                                            value={Moment(effectiveFromValue).format('DD-MMM-yyyy, hh:mm a')}
                                            onChange={handleEffFrom}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                        />
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            disabled={isDisable || isDisableDate}
                                            label="Access To"
                                            inputFormat="dd/MMM/yyyy hh:mm aa"
                                            value={Moment(effectiveToValue).format('DD-MMM-yyyy, hh:mm a')}
                                            onChange={handleEffTo}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                        />
                                    </LocalizationProvider>
                                    {isDisable || !checkApproved ? '' :
                                        <IconButton
                                            onClick={checkApproved ? handleEditDate : null}
                                            disabled={!checkApproved}
                                        >
                                            <Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' />
                                        </IconButton>}


                                </Stack>
                                : ''}

                            {isInMinutes ?

                                <Stack spacing={2} direction="row">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            disabled={isDisable}
                                            label="From Date"
                                            inputFormat="dd/MMM/yyyy hh:mm aa"
                                            value={Moment(fromDateValue).format('DD-MMM-yyyy, hh:mm a')}
                                            onChange={handleFromChange}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </LocalizationProvider>


                                    <TextField
                                        disabled={isDisable}
                                        fullWidth
                                        label="In Minutes"
                                        size="small"
                                        type="number"
                                        value={startTime}
                                        onChange={handleStartTime}
                                    />


                                </Stack>
                                : ''}

                        </>
                        : ''}
                    {isActions ?
                        <TextField
                            value={approverRemark}
                            onChange={handleRemarkChange}
                            fullWidth
                            label="Approver Remark"
                            size="small"
                        /> :
                        ''}

                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>



                        {approveButton}
                        {rejectButton}
                        {cancelButton }
                        {/* <Button */}
                        {/*    sx={{ color: 'white' }} */}
                        {/*    onClick={handelCancel} */}
                        {/*    size="medium" */}
                        {/*    variant="contained" */}
                        {/*    color="primary" */}
                        {/* > */}
                        {/*    Cancel */}
                        {/* </Button> */}

                        {/* {isActions || request.RequestStatusId === 3 ? ( */}
                        {/*    <> */}
                        {/*        <LoadingButton */}
                        {/*            size="medium" */}
                        {/*            variant="contained" */}
                        {/*            sx={{ color: 'white' }} */}
                        {/*            onClick={handleApprove} */}
                        {/*            disabled={isDisable} // Disable if either isActions is true or RequestStatusId is 3 */}
                        {/*        > */}
                        {/*            Approve */}
                        {/*        </LoadingButton> */}

                        {/*        <Button */}
                        {/*            onClick={handleReject} */}
                        {/*            sx={{ color: 'white' }} */}
                        {/*            size="medium" */}
                        {/*            variant="contained" */}
                        {/*            color="primary" */}
                        {/*            disabled={isDisable} // Disable if either isActions is true or RequestStatusId is 3 */}
                        {/*        > */}
                        {/*            Reject */}
                        {/*        </Button> */}
                        {/*    </> */}
                        {/* ) : ( */}
                        {/*    <> */}
                        {/*        <LoadingButton */}
                        {/*            disabled={isDisable} */}
                        {/*            size="medium" */}
                        {/*            type="submit" */}
                        {/*            variant="contained" */}
                        {/*            sx={{ color: 'white' }} */}
                        {/*        > */}
                        {/*            Save */}
                        {/*        </LoadingButton> */}

                        {/*        <Button */}
                        {/*            sx={{ color: 'white' }} */}
                        {/*            onClick={handelCancelClick} */}
                        {/*            size="medium" */}
                        {/*            variant="contained" */}
                        {/*            color="primary" */}
                        {/*        > */}
                        {/*            Cancel */}
                        {/*        </Button> */}
                        {/*    </> */}
                        {/* )} */}

                    </Stack>
                    <Stack direction="row">
                        {isDisable || isActions?



                            <Typography variant="subtitle1">
                                Current Status:  {request.Status}
                            </Typography> :
                            ''
                        }
                    </Stack>

                    {isDisable && requestStatusData.length !==0 ?
                        <div style={{ height: '34vh', width: '100%' }}>
                            <DataGrid disableSelectionOnClick rows={requestStatusData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => requestStatusData.indexOf(row)} components={{
                                Toolbar: GridToolbar,
                            }} />
                        </div>
                        : ''}
                    {isDisable && requestStatusData.length === 0 ?
                    <div style={{ height: '34vh', width: '100%' }}>
                        <DataGrid disableSelectionOnClick rows={getNextApproverData} density={'compact'} columns={TABLE_HEAD1} getRowId={(row) => getNextApproverData.indexOf(row)} components={{
                            Toolbar: GridToolbar,
                            }} />
                            
                        </div>
                        : ''}
                    {isActions && requestStatusData.length !== 0 ?
                        <div style={{ height: '34vh', width: '100%' }}>
                            <DataGrid disableSelectionOnClick rows={requestStatusData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => requestStatusData.indexOf(row)} components={{
                                Toolbar: GridToolbar,
                            }} />
                        </div>
                        : ''}
                   


                </Stack>


            </Form>

            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider>
    );
}