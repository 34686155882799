import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Button, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import Moment from 'moment';
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import AppCache from '../../services/AppCache';
import URLS from '../../services/urls.service';


// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//  {
//    label: 'Mark my attendance',
//    icon: 'eva:home-fill',
//    linkTo: 'app',
//    submit: () => {
//            console.log("hi");
//        },
//  },
//  {
//    label: 'Upload photo',
//    icon: 'eva:person-fill',
//    linkTo: '#',
//  },
//  {
//    label: 'Settings',
//    icon: 'eva:settings-2-fill',
//    linkTo: '#',
//  },
    
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {

    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    const [test, setTest] = useState([]);
    const [mark, setMark] = useState(false);
    const [username, setDashboardusername] = useState([]);
    const [imagePath, setImagePath] = useState([{ "Id": 1, "LoginImageNamePath": "/static/illustrations/SmileServiceLoginImage.png" }]);
    const [disable, setDisable] = useState(false)

    const imageUrl = imagePath[0].LoginImageNamePath;
    useEffect(() => {
        setDashboardusername(CurrentLoggedInUser.ExecutiveName);
        setUserId(CurrentLoggedInUser.ExecutiveId);
        const ContactId = CurrentLoggedInUser.ExecutiveId;
   
       
        URLS.getUserPhoto(ContactId)
            .then(response => {
            if (response.status === 200) {
              
                setImagePath(response.data);

                // console.log("imagePathVVVVVVV", response.data)
            } else {
                alert('Failed to retrieve image');
            }
        });
      
    }, []);

    const onPopClick = () => {
        const payload = { ExecutiveId: userId };
        URLS.getTestingData(payload).then((response) => {
            if (response.status === 200) {
                setDisable(true);
                navigate('profiledetails', {
                    replace: true,
                    state: {
                        prfile: {
                            FirstName: response.data[0].FirstName, LastName: response.data[0].LastName, ExecutiveEmailId: response.data[0].ExecutiveEmailId, PhoneNo: response.data[0].PhoneNo, ReportingTo: response.data[0].ReportingTo, OfficeLocationId: response.data[0].OfficeLocationId, ERPCode: response.data[0].ERPCode, Department: response.data[0].Department, DateOfBirth: Moment(response.data[0].DateOfBirth).format("DD-MMM-yyyy"), DateOfJoining: Moment(response.data[0].DateOfJoining).format("DD-MMM-yyyy"), DateOfConfirmation: Moment(response.data[0].DateOfConfirmation).format("DD-MMM-yyyy"), Designation: response.data[0].Designation, CurrentClientId: response.data[0].CurrentClientId, IsAtClient: response.data[0].IsAtClient, OfficeDeskNo: '', LoginId: response.data[0].LoginId, Address: response.data[0].Address, EmailId: response.data[0].EmailId, EmergencyContactNo: '', Aadhar: response.data[0].Aadhar, UAN: response.data[0].UAN, PAN: response.data[0].PAN, BankName: response.data[0].BankName, Branch: response.data[0].Branch, AccountNo: response.data[0].AccountNo, IFSC: response.data[0].IFSC, BankAccountName: response.data[0].BankAccountName, ESIC: response.data[0].ESIC, OfficialDOB: Moment(response.data[0].OfficialDOB).format("DD-MMM-yyyy"), BloodGroup: response.data[0].BloodGroup, SexId: response.data[0].SexId, MaritalStatusId: response.data[0].MaritalStatusId, DateOfWedding: Moment(response.data[0].DateOfWedding).format("DD-MMM-yyyy"), PermAddress: response.data[0].PermAddress, NewPassword: '', ConfirmPassword: '', UploadPhoto: '', disable: true
                }}
                });
            }
            else {
                // Failure response
                // alert("Error");
                console.log("error");

            }
        });
      

    };



  const anchorRef = useRef(null);
    const navigate = useNavigate();

   const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
    const handleClose = () => {
        setOpen(null);
    };
    const handleImageClose = () => {
        setOpen(null);
        navigate('/loginimageupload', { relpace: true });

    };
    const handleLogOut = () => {
      setOpen(null);
      navigate('/login', { relpace: true });

    };

    

  return (
    <>
      <IconButton
        ref={anchorRef}
       
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
          >
              <Avatar src={`data:image/png;base64,${imagePath}`}  onClick={onPopClick} />

      </IconButton>


      
       

            

      
       
    </>
  );
}
