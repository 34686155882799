import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function IRQuestionnairesListing() {
    const { enqueueSnackbar } = useSnackbar();



    const TABLE_HEAD = [
        { field: 'Name', headerName: 'Product Name', width: 150 },
        { field: 'Status', headerName: 'Status', width: 300 },

        // {
        //    field: 'actions',
        //    headerName: '',
        //    type: 'actions',
        //    width: 20,
        //    renderCell: (cellValues) => {
        //        return (<div>

        //            <IconButton
        //                onClick={(event) => {
        //                    handleClick(event, cellValues);
        //                }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>

        //        </div>
        //        );
        //    }
        // },

    ];

    // dropdown
    const [products, setProducts] = useState([]);
    const [selectedProductsId, setSelectedProductsId] = useState('');
    const handleProducts = (id) => {
        setSelectedProductsId(id);

    };

    useEffect(() => {
        URLS.getOAProductsDropDown()
            .then((response) => {

                setProducts(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });


    }, []);


    // const navigate = useNavigate();
    // const handleClick = (event, cellValues) => {
    //    navigate('/dashboard/productmasterdetailspage', { state: { proMaster: cellValues.row } })
    // }


    const [irListingData, setIRListingData] = useState([]);

    const [isLoading, setIsLoading] = useState();




    const onFilterClick = () => {
        setIsLoading(true);

        URLS.getIRQustionnariesData()
            .then((response) => {
                setIRListingData(response.data);
                setIsLoading(false);

            })
            .catch(error => {
                // On error
                handleError(error, enqueueSnackbar);
            });
    };



    const handleReset = () => {
        setIRListingData([]);
    }



    return (

        <Page title="IR Questionnaires Listing" >

            <Card >


                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <FormControl
                        size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}>
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Products</InputLabel>
                        <Select
                            label="Prducts"
                            size="small"
                            value={selectedProductsId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} >Select</MenuItem>

                            {products.map((product) => (
                                <MenuItem key={product.Id} value={product.Id} onClick={() => handleProducts(product.Id)}> {product.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>



                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}

                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >

                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/irquestionnairesdetailscomponent'
                                variant="contained"
                                size="small"
                                state={{ irQuestionnaries: { Id: '', ProductId: '', FromDate: '', ToDate: '',IsActive:true } }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleReset}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                        </Tooltip >

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={irListingData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => irListingData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}