import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components

import GenerateInstallationReport from "../../../components/SidebarMenuComponents/OAComponent/GenerateInstallationReport";
import Page from "../../../components/Page";
import IncidentTrackerListing from "../../../components/SidebarMenuComponents/OtherComponent/IncidentTrackerListing";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function IncidentTracker() {
    const location = useLocation();
    const reports = location.state;
    console.log("landing page", reports)
    return (
        <Page title="Incident Tracker| tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Incident Tracker 
            </Typography>

            <ContentStyle>
                <IncidentTrackerListing />
            </ContentStyle>
        </Page>
    );
}

