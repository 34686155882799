import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    Popover,
    Grid,
    Paper,
    TextareaAutosize,
    Box,
    Typography,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
import AppCache from '../../../services/AppCache';


// ----------------------------------------------------------------------



// ----------------------------------------------------------------------


export default function IncidentTrackerListing() {

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

   
    const [isLoading, setIsLoading] = useState();
    const [hide, setHide] = useState(false);
    const [data, setData] = useState('')
    const [issueId, setIssueId] = useState('')
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const [userId, setUserId] = useState([]);
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

 
    const handleChangeRequest = () => {
        setHide(true)
        const payload = { IssueId: issueId }
        URLS.getIssueDetails(payload)
            .then((response) => {
                console.log("res>>>>>>",response)
                setData(response.data);
                setIsLoading(false);
                if (response.data.length === 0) {
                    setHide(false);
                    enqueueSnackbar('Issue ID is not correct', { variant: 'error' });

                } else {
                    setShowSaveButton(response.data[0]?.IncidentIssueId === null);
                }
               
            })
            .catch(error => {
                // On error
                handleError(error, enqueueSnackbar);
            });

    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };
    const message = {
        forCancel: 'Do you want to clear? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            setIssueId('')
            setHide(false)
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

   
    const handleSave = () => {
       
        const payload = {
            "IssueId": issueId,
            "Remark": "",
            "IsIncident": showSaveButton,
            "CreatedById": userId,
            "EditedById": userId


        };
        console.log("payload>>>>>>", payload)

        URLS.saveIssueIncident(payload)
            .then((response) => {
                enqueueSnackbar("Data Saved successfully", { variant: 'success' });
                setIssueId('')
                setHide(false)
            })
            .catch(error => {
                enqueueSnackbar("Please select Issue ID", { variant: 'error' });
                /* handleError(error, enqueueSnackbar); */
            });
        setOpenModalCancel(false)
    }

    useEffect(() => {
        setIssueId('');
        setShowSaveButton(false)
        setHide(false)
    }, [location.key])

    return (

        <Page title="Incident Tracker" >

                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                size="small"
                                label="Issue Id"
                                fullWidth
                                type="number"
                                value={issueId}
                                onChange={(e) => setIssueId(e.target.value)}
                            />
                        </Grid>
                        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2} margin={2}>

                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleChangeRequest }
                            >
                             Go
                            </Button>
                        </Stack>
                    </Grid>

                </Stack>
            
            {/* ----------------------------------------------------------------- */}
            {hide && data && issueId && (
                <Grid item m={2}>
                    <Paper elevation={3} >
                        <Typography variant="body1">
                            Issue Reported By <strong>{data[0]?.IssueReportedBy}</strong> On Date <strong>{data[0]?.Date}</strong> Of <strong>{data[0]?.Client} ({data[0]?.SiteName})</strong> For <strong>{data[0]?.IssueCategory}</strong> and Owned by <strong>{data[0]?.IssueOwnedBy}</strong>
                        </Typography>
                        <Typography>
                            <em>'{data[0]?.Remarks}'</em>

                        </Typography>
                    </Paper>

                   
                </Grid>
            )}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2} margin={2}>
                {hide && showSaveButton && (
                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        sx={{ color: 'white' }}
                        onClick={handleSave }
                    >
                        Add Incident
                    </LoadingButton>
                )}

                {hide && !showSaveButton && data && (
                    <Button
                        sx={{ color: 'white' }}
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={handleSave}

                    >
                        Remove Incident
                    </Button>
                )}

                {hide && (
                    <Button
                        sx={{ color: 'white' }}
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={handelCancelClick}
                    >
                        Clear
                    </Button>
                )}
               
            </Stack>

            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}
            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}


        </Page>


    );
}