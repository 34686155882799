import AppCache from '../../services/AppCache';


 const CurrentAPIURL = AppCache.GetCurrentAPIURL();

const Utils = {

    commonImagePath: "/Images/",
};
// commonImagePath: "https://localhost:44369/Images/",

export default Utils;
export const handleError = (error, enqueueSnackbar ) => {

    const resMessage = (error.response && error.response.data && error.response.data) || error.message || error.toString();
   
    enqueueSnackbar(resMessage.Message);
    
   
};

