import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import ClientMaster from '../../../components/SidebarMenuComponents/MasterComponent/ClientMaster'
import ProductMaster from "../../../components/SidebarMenuComponents/MasterComponent/ProductMaster";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function ProductMasterPage() {
    const location = useLocation();
    const proMaster = location.state;
    console.log("proMaster", proMaster);

    return (
        <Page title="  Product Master  | tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Product Master
            </Typography>

            <ContentStyle>
                <ProductMaster proMaster={proMaster} />
            </ContentStyle>
        </Page>
    );
}

