import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, Typography, Modal, FormControl, InputLabel, Select, MenuItem, Button, Box } from '@mui/material';
import { startOfMonth, endOfMonth } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};
const Status = [
    { id: 0, Name: 'Pending' },
    { id: 1, Name: 'Completed' },
    { id: 2, Name: 'Cancelled' },
];

export default function GenerateInstallationReportDetails({ reports }) {
    console.log("repo", reports)
   
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // const productId = proMaster.ID;
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    // executive ID
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    // Modal
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/oa/generateintallationreport', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

    // dropdown
    const [clients, setClients] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState(reports[0]?.ClientId || "");
    const [selectedOADetails, setSelectedOADetails] = useState([]);
    const [selectedOADetailsID, setSelectedOADetailsID] = useState(reports[0]?.OADetailsId || '');
    const [statusId, setStatusId] = useState('');
    const [effectiveFromValue, setEffectiveFromValue] = useState(reports[0]?.InstallationStatusUpdateDateTime);

    const [clientDropDownDisable, setClientDropDownDisable] = useState(false);
    const [OAdisable, setOAdisable] = useState(true);
    const [productId, setProductId] = useState(reports[0]?.ProductId || '');

    const [isIssueIdValid, setIsIssueIdValid] = useState(true);
    const [issueIdValue, setIssueIdValue] = useState(reports[0]?.IssueId ||'')

    const handleEffFrom = (newValue) => {
        const formattedDate = Moment(newValue).format('DD/MMM/yyyy HH:mm');
        setEffectiveFromValue(formattedDate);
        console.log("formattedDate", formattedDate)
    };

    const handleClient = (id) => {
        if (!id) {
            setSelectedClientId("");
            setSelectedOADetailsID(""); // Set selectedOADetailsID to empty when selectedClientId is empty

        } else {
            setSelectedClientId(id);
        }
    };


    const handleOADetails = (id) => {
        setSelectedOADetailsID(id); // Update the selected OADetails ID
        // Find the selected OADetails object from the list
        const selectedOADetail = selectedOADetails.find(detail => detail.Id === id);
        if (selectedOADetail) {
            // Extract the productId from the selected OADetails and set it in the state
            setProductId(selectedOADetail.ProductId);
            console.log("selectedOADetail***********************", selectedOADetail)
        }
        console.log("setSelectedOADetailsID****************", setSelectedOADetailsID)
    };


    console.log("selctedClientId>>>>>>>>>>>>>>>>>>>>>>>>>>", selectedClientId, "productId>>>>>>>>>>>>>>>>>>.", productId)
    const handleStatusChange = (event) => {
        const newSelectedCategory = event.target.value;
        setStatusId(newSelectedCategory);
    };

    const handleIssueIdChange = (event) => {
        setIssueIdValue(event.target.value);
    };

    useEffect(() => {
        URLS.getOAClient()
            .then((response) => {

                setClients(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
       
            const payload = { OADetailsId: reports.OADetailsId};

            // URLS.getOAProductWiseData(payload)
            //    .then((response) => {
            //        setSelectedOADetails(response.data);
            //         setSelectedOADetailsID(`${response.data[0].OANumber}-${response.data[0].ProductId}`);
            //        console.log("data>>>>>>>", `${response.data[0].OANumber}-${response.data[0].ProductId}`);
            //    })
            //    .catch((error) => {
            //        handleError(error, enqueueSnackbar);
            //    });
        
    }, []);
  
    useEffect(() => {
        if (selectedClientId) {
            const payload = { ClientID: selectedClientId };
            URLS.getOAProductWiseData(payload)
                .then((response) => {
                    setOAdisable(false);
                    setSelectedOADetails(response.data);
                    console.log("res", response.data)
                    
                })
                .catch(error => {
                    // Handle error
                    console.error("Error fetching product data:", error);
                });
        }
    }, [selectedClientId])

   

    console.log("selectedOADetails", selectedOADetails, selectedOADetailsID, selectedClientId)
   

    // Event handler to update selected product ID from OADetails dropdown
   
    // useEffect hook to update selected product ID when either selectedClientId or selectedOADetails changes
    useEffect(() => {
        setSelectedOADetailsID(reports[0]?.OADetailsId || ''); // Initialize selectedOADetailsID
    }, [reports]);

    const genertaeValidationSchema = Yup.object().shape({
        IssueId: Yup.string().required('IssueId is required'),
       
    });

    const formik = useFormik({
        initialValues: {
            InstallationReportMasterId: 0,
            OADetailsId: reports[0]?.OADetailsId,
            ProductId: reports[0]?.ProductId,
            Remark: reports[0]?.Remark,
            IssueId: reports[0]?.IssueId,
            InstallationStatusUpdateDateTime: reports[0]?.InstallationStatusUpdateDateTime,
            CreatedBy: userId,
            InstallationReportDetailsModel:[
            {
                InstallationReportDetailsId: reports.InstallationReportDetailsId,
                InstallationReportMasterId: reports.InstallationReportMasterId,
                InstallationReportQuestionnairesMasterId: 0,
                QuestionId: "string",
                Answers: "string"
        }
            ]
        },
        onSubmit: (values, actions) => {
            if (!selectedClientId) {
                enqueueSnackbar("Please select client ", { variant: 'error' });
                return;
            }
            if (!selectedOADetailsID) {
                enqueueSnackbar("Please select OADetails", { variant: 'error' });
                return;
            }
            if (issueIdValue.length === 0) {
                enqueueSnackbar("Please select IssueId", { variant: 'error' });
                return;
            }
            const selectedProduct = selectedOADetails.find(product => product.Id === values.OADetailsId);
           

            const selectedOADetail = selectedOADetails.find(detail => detail.Id === values.OADetailsId); // Find the selected OADetails
            const oadetailsId = selectedOADetail ? selectedOADetail.Id : ""; 
            console.log("productId>>>>>>>>>>>>>>", productId, reports[0]?.ProductId)
            const payload = {
                "InstallationReportMasterId": (reports && reports.length > 0) ? reports[0].Id : 0,
                "OADetailsId": selectedOADetailsID,
                "ProductId": productId, // Extracting the second part after splitting by '-'
                "Remark": values.Remark || "",
                "InstallationStatus": 0,
                "IssueId": issueIdValue,
                "InstallationStatusUpdateDateTime": effectiveFromValue,
                "CreatedBy": userId,
                "EditedBy": userId,
                "CancelledBy": null,
                "InstallationReportDetailsModel": [
                    {
                    "InstallationReportDetailsId": 0,
                    "InstallationReportMasterId": 0,
                        "InstallationReportQuestionnairesMasterId": 1,
                        "QuestionId": "2",
                        "Answers": "asd"
                    }
                ]
            };
            console.log("payload>>>>>>>>>", payload)
            const validatePayload = { IssueId: issueIdValue, OADetailsId: selectedOADetailsID };
            URLS.validateIssueIdAgainstOA(validatePayload)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "true") {

                        URLS.saveInstallationReportMaster(payload)
                            .then((response) => {
                                if (response.status === 200) {
                                    // After successfully saving, call generateInstallationReportExcelData API
                                    // Inside the onSubmit function
                                    URLS.getGenerateInstallationReportExcelData(payload)
                                        .then((response) => {
                                            console.log("response", response)
                                            if (response.status === 200 && response.data && response.data.filepath) {
                                                // Create a link element
                                                const link = document.createElement('a');
                                                link.href = response.data.filepath;
                                                link.setAttribute('download', '');
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link); // Clean up after download

                                                enqueueSnackbar("Excel data saved successfully", { variant: 'success' });

                                            }
                                            else {
                                                enqueueSnackbar("Filepath not found in response", { variant: 'error' });
                                            }
                                        })
                                    navigate('/dashboard/oa/generateintallationreport', { replace: true });

                                }
                                enqueueSnackbar("Data saved successfully", { variant: 'success' });
                            })
                            .catch(error => {
                                enqueueSnackbar("Please select a OADetails", { variant: 'error' });

                                // handleError(error, enqueueSnackbar);
                            });
                    }
                    else {
                        enqueueSnackbar(response.data.message, { variant: 'error' });

                    }
                })
                .catch(error => {
                    enqueueSnackbar('Invalid IssueId', { variant: 'error' });
                    setIsIssueIdValid(false); // Set validation status to false
                });
          
        },
    });

   const [oaFilter, setOAfilter] = useState([])

    const handleIssueIdBlur = (event) => {
        const issueId = event.target.value;
        if (issueId) {
            const validatePayload = { IssueId: issueId, OADetailsId: selectedOADetailsID };
            URLS.validateIssueIdAgainstOA(validatePayload)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.status === "true") {
                            enqueueSnackbar(response.data.message, { variant: 'success' });
                        } else {
                            enqueueSnackbar(response.data.message, { variant: 'error' });

                        }
                    }
                })
                .catch(error => {
                    enqueueSnackbar('Invalid IssueId', { variant: 'error' });
                    setIsIssueIdValid(false); // Set validation status to false
                });
        }
    };


    console.log("eff", effectiveFromValue);
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  

    console.log("master reports", reports);
    console.log("selectedOADetailsID>>>>>>", selectedOADetailsID,)

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={4}>
                    <Stack direction="row" spacing={4}>
                        <FormControl size="small" fullWidth>
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Clients</InputLabel>
                            <Select
                                label="Clients"
                                size="small"
                                value={selectedClientId}
                               
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7',
                                            maxHeight: '200px',
                                            width: '200px',
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} onClick={() => handleClient()} >Select</MenuItem>
                                {clients.map((product) => (
                                    <MenuItem key={product.ContactId} value={product.ContactId} onClick={() => handleClient(product.ContactId)}> {product.Name}</MenuItem>
                                ))}
                            </Select>

                        </FormControl>

                        {/* Conditionally render the second dropdown */}
                      
                            <FormControl size="small" fullWidth>
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>OADetails</InputLabel>
                                <Select
                                label="OADetails"
                                size="small"
                                value={selectedOADetailsID}
                                onChange={(event) => handleOADetails(event.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7',
                                            maxHeight: '200px',
                                            width: '200px',
                                        }
                                    }
                                }}
                                disabled={!selectedClientId }
                                >
                                
                                {selectedOADetails.map((product, index) => (
                                    <MenuItem key={index} value={product.Id}>
                                        {`OANumber: ${product.OANumber} - Product: ${product.ProductName}`}
                                    </MenuItem>
                                ))}

                                </Select>
                            </FormControl>
                    
                        </Stack>

                    <Stack direction="row" spacing={4}>
                        <TextField
                            fullWidth
                            label="IssueId"
                            size="small"
                            value={issueIdValue}
                            onChange={handleIssueIdChange}
                           
                        />

                        <TextField
                            fullWidth
                            label="Remark"
                            size="small"
                            {...getFieldProps('Remark')}
                            multiline
                            maxRows={4}
                         
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="From Date and Time"
                                inputFormat="dd/MMM/yyyy HH:mm:ss" // Adjust the format as needed
                                value={Moment(effectiveFromValue).format('DD-MMM-yyyy HH:mm:ss')} // Include time in the format
                                onChange={handleEffFrom}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>


                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Generate
                        </LoadingButton>

                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>
                    </Stack>

                </Stack>


            </Form>

            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider>
    );
}