import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, Typography, Modal,FormControl, InputLabel, Select, MenuItem, Button, Box } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function ProductMasterDetailsComponent({ proMaster }) {
    console.log("proMaster>>>>>>>>>>>>>>", proMaster)
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const productId = proMaster[0]?.ID;



    // executive ID
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    
   // CheckBox
    const [isRecurring, setIsRecurring] = useState(proMaster[0]?.IsRecurring || false);
    const [isCheckedActive, setIsCheckedAcive] = useState(proMaster[0]?.IsActive || true);
    const [isCommercial, setIsCommercial] = useState(proMaster[0]?.IsCommercialProduct || false);

    const handleIsRecurringChange = (event) => {
        setIsRecurring(event.target.checked);
    };

    const handleIsActiveChange = (event) => {
        setIsCheckedAcive(event.target.checked);
    };

    const handleIsCommercial = (event) => {
        setIsCommercial(event.target.checked);
    };

    // Modal
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/master/productmaster', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

    const [projectStatus, setProjectStatus] = useState([]);

   
    const [projectStatusId, setProjectStatusId] = useState(proMaster[0]?.ProjectStatusId || "");

    const [productFamilyId, setProductFamilyId] = useState(proMaster[0]?.ProductFamily || "");
    const [prodcutFamilyData, setProductFamilyData] = useState([]);

    const [productGroupId, setProductGroupId] = useState(proMaster[0]?.ProductGroup || "");
    const [productGroupData, setProductGroupData] = useState([]);

    const [productCategoryId, setProductCategoryId] = useState(proMaster[0]?.ProductCategory || "");
    const [productCategoryData, setProductCategoryData] = useState([]);


    const handleProjectStatusChange = (id) => {
        setProjectStatusId(id);

    };

    const handleProductFamilyChange = (id) => {
        setProductFamilyId(id);
    };

    const handleProductGroup = (id) => {
        setProductGroupId(id);
    };

    const handleProductCategory = (id) => {
        setProductCategoryId(id);
    };

    const ProductMasterSchema = Yup.object().shape({
        Name: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Name is required'),
       
    });

    const formik = useFormik({
        initialValues: {
            ID: productId,
            Name: proMaster[0]?.Name,
            GST: proMaster[0]?.GST,
            Remark: proMaster[0]?.Remark,
            /* IsActive: isCheckedActive, */
            IsActive: proMaster[0]?.IsActive,
            IsCommercialProduct: isCommercial,
            ProjectStatusId: proMaster[0]?.ProjectStatusId,
            ExecutiveID: userId,
        },
        validationSchema: ProductMasterSchema,
        onSubmit: (values, actions) => {


            const productNamePayload = {
                "Name": values.Name,
                "ID": productId
            };
            const payload = {
                "ID": (proMaster && proMaster.length > 0) ? proMaster[0].ID : 0,
                "Name": values.Name,
                "GST": values.GST,
                "Remark": values.Remark,
                "IsActive": isCheckedActive,
                "IsCommercialProduct": isCommercial,
                "IsRecurring": isRecurring,
                "ProjectStatusId": projectStatusId,
                "ExecutiveID": userId,
                "ProductFamily": productFamilyId,
                "ProductGroup": productGroupId,
                "ProductCategory": productCategoryId
            };
            console.log("payload>>>>>",payload)
            URLS.getProductNameValidation(productNamePayload)
                .then((response) => {
                    console.log("res>>>>>>",response)
                    if (response.data.status === "false") {
                        URLS.saveProductMaster(payload)
                            .then((response) => {
                                console.log("")
                                if (response.status === 200) {
                                    navigate('/dashboard/master/productmaster', { replace: true });

                                }
                                enqueueSnackbar("Record saved successfully", { variant: 'success' });
                            })
                            .catch(error => {
                                handleError(error, enqueueSnackbar);
                            });
                    } else {
                        enqueueSnackbar("Product Name Already exists");
                    }
                })
                .catch(error => {
                    handleError(error, enqueueSnackbar);
                });

        },
    });
    const fetchGroupData = (kayId, setData) => {
        const payload = { KeyId: kayId };
        URLS.getGroupsData(payload)
            .then((response) => {
                setData(response.data);
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    };


    useEffect(() => {
        URLS.getProjectStatusDropDown()
            .then((response) => {

                setProjectStatus(response.data);

            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
   
        fetchGroupData(30, setProductFamilyData);
        fetchGroupData(31, setProductGroupData);
        fetchGroupData(32, setProductCategoryData);

    }, []);

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;



    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={3}>
                    <Stack direction="row" spacing={4}>

                        <TextField
                            fullWidth
                            autoComplete="Name"
                            size="small"
                            label="Product Name "
                            {...getFieldProps('Name')}
                            error={Boolean(touched.Name && errors.Name)}
                            helperText={touched.Name && errors.Name}
                        />





                        <TextField
                            fullWidth
                            label="GST%"
                            size="small"
                            {...getFieldProps('GST')}
                            error={Boolean(touched.GST && errors.GST)}
                            helperText={touched.GST && errors.GST}
                        />


                        <FormControl
                            size="small"
                            fullWidth
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Project Status</InputLabel>
                            <Select
                                label="Project Status"
                                size="small"
                                value={projectStatusId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} onClick={() => handleProjectStatusChange()} >Select</MenuItem>

                                {projectStatus.map((component) => (
                                    <MenuItem key={component.Id} value={component.Id} onClick={() => handleProjectStatusChange(component.Id)}> {component.Name}</MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={4}>
                        <FormControl
                            size="small"
                            fullWidth
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Product Family</InputLabel>
                            <Select
                                label="Product Family"
                                size="small"
                                value={productFamilyId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} onClick={() => handleProductFamilyChange()} >Select</MenuItem>

                                {prodcutFamilyData.map((component) => (
                                    <MenuItem key={component.Id} value={component.Id} onClick={() => handleProductFamilyChange(component.Id)}> {component.Name}</MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                        <FormControl
                            size="small"
                            fullWidth
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Product Group</InputLabel>
                            <Select
                                label="Product Group"
                                size="small"
                                value={productGroupId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} onClick={() => handleProductGroup()} >Select</MenuItem>

                                {productGroupData.map((component) => (
                                    <MenuItem key={component.Id} value={component.Id} onClick={() => handleProductGroup(component.Id)}> {component.Name}</MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                        <FormControl
                            size="small"
                            fullWidth
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Product Category</InputLabel>
                            <Select
                                label="Product Category"
                                size="small"
                                value={productCategoryId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}
                            >
                                <MenuItem key={0} value={""} onClick={() => handleProductCategory()} >Select</MenuItem>

                                {productCategoryData.map((component) => (
                                    <MenuItem key={component.Id} value={component.Id} onClick={() => handleProductCategory(component.Id)}> {component.Name}</MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={4}>
                        <TextField
                            fullWidth
                            label="Remark"
                            size="small"
                            {...getFieldProps('Remark')}
                            error={Boolean(touched.Remark && errors.Remark)}
                            helperText={touched.Remark && errors.Remark}
                            style={{ width: '590px' }}
                        />

                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={isRecurring} onChange={handleIsRecurringChange} />} label="IsRecurring" style={{ width: '150px' }} />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={isCheckedActive} onChange={handleIsActiveChange} />} label="IsActive" style={{ width: '150px' }}/>
                        </FormGroup>

                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={isCommercial} onChange={handleIsCommercial} />} label="Commercial Product" style={{ width: '150px' }}
                            />
                        </FormGroup>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Save
                        </LoadingButton>

                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>
                    </Stack>
                   
                </Stack>


            </Form>

            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider>
    );
}