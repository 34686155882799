import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import ClientInstallation from '../../../components/SidebarMenuComponents/MasterComponent/ClientInstallation'
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
   
    margin: 'auto',
    display: 'flex',
   
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));

export default function ClientInstallationPage() {

    return (
        <Page title="  Client Installation Locations  | tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Client Installation Locations
            </Typography>
            <ContentStyle>
                <ClientInstallation />
            </ContentStyle>
        </Page>
    );
}

