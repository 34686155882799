import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography, Divider } from '@mui/material';
// components
import Page from '../../../components/Page';
import Scrollbar from "../../../components/Scrollbar";
import RequestMasterDetailsComponent from "../../../components/SidebarMenuComponents/ApprovalComponent/RequestMasterDetailsComponent";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
}));
export default function RequestMasterDetailsPage() {
    const location = useLocation()
    const { request } = location.state;
    const { isDisable } = location.state;
    const { isActions } = location.state;
    const { isHidden } = location.state;
    const { requestedId } = location.state;
    const { statusId } = location.state;
    const { fromDate } = location.state;
    const { toDate } = location.state;
    const { isEdit } = location.state;
    console.log("requestIdpage", requestedId);

    return (
        <Page title="  Request Master Details |tiketic" >
            {isActions ?
                <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Request Approval Details
                </Typography> :
                <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                    Request Master Details
                </Typography>
                }
           
            <Divider />
            <Card sx={{ height: '470px', padding: 2 }}>
                <Scrollbar>
                    <ContentStyle>
                        <RequestMasterDetailsComponent request={request} isDisable={isDisable} isActions={isActions} requestedId={requestedId} statusId={statusId} fromDate={fromDate} toDate={toDate} isEdit={isEdit}/>
                    </ContentStyle>
                </Scrollbar>
            </Card>
        </Page>
    );
}

