import PropTypes from 'prop-types';
// material
import { Popover } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const ArrowStyle = styled('span')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        top: -7,
        zIndex: 1,
        width: 12,
        right: 100,
        height: 12,
        content: "''",
        position: 'absolute',
        borderRadius: '0 0 4px 0',
        transform: 'rotate(-135deg)',
        background: theme.palette.background.paper,
        borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
        borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    },
}));

// ----------------------------------------------------------------------

MenuPopover.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object,

};

export default function MenuPopover({ children, sx, ...other }) {

    return (
        <Popover
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 300,
                    ...sx,
                    transition: 'opacity 1s linear, transform 1s ease-in-out', // Adding transition properties
                    opacity: 1, // Set initial opacity to 0 if you want to fade in
                    transform: 'scale(1)', // Set initial transform value if you want to add a scaling effect
                },
            }}
            {...other}
            height='auto'
        >
            {/* <ArrowStyle className="arrow" /> */}
            {children}
        </Popover>

    );
}
