import { useLocation, Navigate } from "react-router-dom";
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import GenerateInstallationReport from "../../../components/SidebarMenuComponents/OAComponent/GenerateInstallationReport";
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function GenerateInstallationReportPage() {
    const location = useLocation();
    const reports = location.state;
    console.log("landing page",reports)
    return (
        <Page title="Generate Installation Report| tiketic" >
            <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                Generate Installation Report
            </Typography>

            <ContentStyle>
                <GenerateInstallationReport  />
            </ContentStyle>
        </Page>
    );
}

