/* eslint-disable no-debugger */
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, Typography, InputAdornment, FormControl, InputLabel, Select, MenuItem, Button, Grid, Box, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import { Icon } from '@iconify/react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
import Scrollbar from '../../Scrollbar';
// -----------------------------------------------------------------------------------------------------


const categoruDetails = [
    {
        Id: 1,
        Name: "Days",

    },
    {
        Id: 2,
        Name: "Minutes",

    },
]


export default function RequestDetailsComponent({ requestMaster, isDisabled, selectedlevels }) {
    console.log("requestMaster", requestMaster)
    console.log("selectedlevels", selectedlevels)

    const accordionStyle = {
        backgroundColor: '#fbe9e7',
        minHeight: '10px',
        height: '30px',
        marginTop: '10px',
        marginBottom: '10px'
        // Add other CSS properties as needed
    };


    const { enqueueSnackbar } = useSnackbar();



    const [levelNo, setLevelNo] = useState(1)


    // const [isDisable, setIsDisable] = useState(false);
    const [requestCategoryApproval, setRequestCategoryApproval] = useState([]);
    const [requestApprovalId, setRequestApprovalId] = useState('');
    const handleSelectedRequestApproval = (id) => {
        setRequestApprovalId(id);
    };

    const [reqname, setReqName] = useState("Request Category : ");

    const navigate = useNavigate();
    const [isDisable, setIsDisable] = useState(false);
    const [isPlus, setIsPlus] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [requestCategory, setRequestCategory] = useState([]);
    const [requestId, setRequestId] = useState(requestMaster.CategoryId);
    const handelChangeRequest = () => {
        setRequestId('');
    }
    const handleSelectedRequest = (id) => {
        setRequestId(id);
    };
    useEffect(() => {
        if (requestMaster.Id) {
            setIsDisable(true);
            setIsClicked(true);
        } else {
            setIsDisable(false);
        }

    }, []);

    const handlePlus = () => {
        setRequestId('')
        setIsDisable(true);
        setIsPlus(true);
        setIsClicked(true);
        setReqName(true);
    }


    // executive ID
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);


    // CheckBox

    const [daysValue, setDaysValue] = useState('');
    const [minutesValue, setMinutesValue] = useState('');
    const [isCheckedActive, setIsCheckedAcive] = useState(requestMaster.isExpiryApplicable);

    const handleIsActiveChange = (event) => {
        const isChecked = event.target.checked;
        setIsCheckedAcive(isChecked);

        // If checkbox is unchecked, set selectedCategory and daysValue to empty strings
        if (!isChecked) {
            setSelectedCategory('');
            setDaysValue('');
            setMinutesValue('');
        }
    };

    const [isReturn, setIsReturn] = useState(requestMaster.isReturnApplicable);

    const handleIsReturnChange = (event) => {
        setIsReturn(event.target.checked);
    };

    // Modal
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    // let cancelClickCount = 0;
    const forCancelConfirm = (value) => {
        if (value) {

            navigate('/dashboard/requestandapproval/requestcategory', { replace: true });
        }

        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };


    // Request Category Details DropDown
    const [executive, setExecutive] = useState([]);
    const [executiveValue, setExecutiveValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(requestMaster.ExpiryAlert || 'D');




    useEffect(() => {
        const parts = requestMaster.ExpiryAlert.split(':');
        if (parts.length === 2) {
            setSelectedCategory(parts[0].trim()); // Trim any spaces
            setDaysValue(parts[1].trim()); // Trim any spaces
            setMinutesValue(parts[1].trim()); // Trim any spaces
        }
    }, [requestMaster.ExpiryAlert]);


    const categoryDetails = [
        { id: 'D', name: 'Days' },
        { id: 'M', name: 'Minutes' },
    ];

    const handleCategoryChange = (event) => {
        const newSelectedCategory = event.target.value;
        setSelectedCategory(newSelectedCategory);
         if (newSelectedCategory === 'days') {
            setMinutesValue('');
        } else if (newSelectedCategory === 'minutes') {
            setDaysValue('');
        }
    };



    const categoryDetailsApproval = [
        { id: 'ReportingTo', name: 'Reporting To' },
        { id: 'ID', name: 'Id' },
        { id: 'ReportingToOfPreviosLevel', name: 'Reporting To Previos Level' },
    ];



    const [selectedLevelNos, setSelectedLevelNos] = useState([]);
    const [selectedCategoryApproval, setSelectedCategoryApproval] = useState('ReportingTo');
    const [formDataList, setFormDataList] = useState([]);

     // const handleApprovalChange = (event, index) => {
     //   const { value } = event.target;
     //   const updatedFormDataList = [...formDataList];
     //   updatedFormDataList[index] = {
     //       ...updatedFormDataList[index],
     //       selectedCategoryApproval: value,
     //   };
     //   setFormDataList(updatedFormDataList);
     // };
    const handleApprovalChange = (event, index) => {
        const newFormDataList = [...formDataList];
        const newValue = event.target.value;

        // Update the approver key and reset the executive value
        newFormDataList[index] = {
            ...newFormDataList[index],
            selectedCategoryApproval: newValue,
            executiveValue: newValue === 'ID' ? newFormDataList[index].executiveValue : '' // Reset executive value if not 'ID'
        };

        // Update state with new form data
        setFormDataList(newFormDataList);
    };


    const handleSelectedExecutive = (value, index) => {
        console.log("Selected Executive Id:", value);
        const selectedExecutive = executive.find(exec => exec.Id === value);
        const selectedName = selectedExecutive ? selectedExecutive.Name : '';
        console.log("Selected Executive Name:", selectedName);

        // Convert selected executive value to string for comparison
        const selectedValueString = value.toString();

        // Check if the selected executive Id already exists in the form data list
        const isDuplicateId = formDataList.some((formData, idx) => {
            return idx !== index && formData.executiveValue === selectedValueString;
        });
        console.log("Is Duplicate in formDataList:", isDuplicateId);

        // Check if the selected executive's Id exists in the selectedLevels array
        const isDuplicateExecutives = selectedlevels && selectedlevels.some(level => {
            return level.Executives === selectedValueString && level.Executives === value;
        });
        console.log("Is Duplicate in selectedLevels:", isDuplicateExecutives);

        if (isDuplicateId || isDuplicateExecutives) {
            enqueueSnackbar(`You have already selected ${selectedName}`);
        } else {
            const updatedFormDataList = [...formDataList];
            updatedFormDataList[index] = {
                ...updatedFormDataList[index],
                executiveValue: selectedValueString,
            };
            console.log("Updated Form Data List:", updatedFormDataList);
            setFormDataList(updatedFormDataList);
        }
    };

    // const handleSelectedExecutive = (value, index) => {
    //    // Only update if the Approver Key is 'ID'
    //    if (formDataList[index].selectedCategoryApproval === 'ID') {
    //        const newFormDataList = [...formDataList];
    //        newFormDataList[index] = {
    //            ...newFormDataList[index],
    //            executiveValue: value
    //        };
    //        setFormDataList(newFormDataList);
    //    }
    // };




    const SelectExecutive = () => {
        setExecutiveValue("");


    };


    useEffect(() => {
        // Initialize form data when selectedlevels changes
        if (selectedlevels && selectedlevels.length > 0 && selectedlevels.every(level => level.LevelNo !== 0)) {

            const initialFormDataList = selectedlevels.map((level, index) => ({
                Id: level.Id,
                selectedCategoryApproval: level.ApproverKey,
                executiveValue: level.Executives,
                levelNo: index + 1,
            }));
            setFormDataList(initialFormDataList);
        } else if (selectedlevels && selectedlevels.length > 0 && selectedlevels.every(level => level.LevelNo === 0)) {

            setFormDataList([]);
        } else {

            setFormDataList([
                {
                    selectedCategoryApproval: 'ReportingTo',
                    executiveValue: '',
                    levelNo: 1,
                    Id: null
                },
            ]);
        }



    }, [selectedlevels]);

    const [deletedRecords, setDeletedRecords] = useState([]);

    // const handleDeleteButtonClick = (indexToDelete) => {
    //    const deletedRecord = formDataList[indexToDelete];
    //    const updatedFormDataList = formDataList
    //        .filter((_, index) => index !== indexToDelete)
    //        .map((formData, index) => {
    //            return { ...formData, levelNo: index + 1 };
    //        });

    //    setDeletedRecords([...deletedRecords, deletedRecord]); // Save deleted record
    //    setFormDataList(updatedFormDataList); // Update the form data list
    // };
    const handleDeleteButtonClick = (indexToDelete) => {
        const recordToDelete = formDataList[indexToDelete];

        // Check if the record's levelNo is 1
        if (recordToDelete.levelNo === 1) {
            // Handle error or show notification
            enqueueSnackbar("Cannot delete a record with levelNo equal to 1");
            return; // Exit the function early
        }

        const updatedFormDataList = formDataList
            .filter((_, index) => index !== indexToDelete)
            .map((formData, index) => {
                return { ...formData, levelNo: index + 1 };
            });

        setDeletedRecords([...deletedRecords, recordToDelete]); // Save deleted record
        setFormDataList(updatedFormDataList); // Update the form data list
    };




    // const handleDeleteButtonClick = (indexToDelete) => {
    //    // Create a copy of the formDataList without the record to be deleted
    //    const updatedFormDataList = formDataList.filter((_, index) => index !== indexToDelete);
    //    setFormDataList(updatedFormDataList);
    // };



    const [isFirstClick, setIsFirstClick] = useState(true);

    const handleAddButtonClick = () => {

        setFormDataList((prevList) => [
            ...prevList,
            {
                selectedCategoryApproval: '',
                executiveValue: '',
                levelNo: prevList.length + 1,
            },
        ]);
    };
    // const handleDeleteButtonClick = (indexToDelete) => {
    //    // Use filter to create a new array excluding the item to delete
    //    setFormDataList([]);
    // };





    let approverKey = "";

    // Iterate through formDataList to construct ApproverKey
    formDataList.forEach((formData, index) => {
        const { selectedCategoryApproval, executiveValue } = formData;
        if (selectedCategoryApproval === 'ID') {
            // If selectedCategoryApproval is 'ID', include executiveValue
            approverKey += `ID:${executiveValue}`;
        } else {
            // Otherwise, use selectedCategoryApproval as is
            approverKey += selectedCategoryApproval;
        }

        // Add a separator between entries, e.g., a comma
        if (index < formDataList.length - 1) {
            approverKey += ',';
        }
    });

    let levelNoString = "";

    // Iterate through formDataList to construct LevelNo
    formDataList.forEach((formData, index) => {
        const { levelNo, selectedCategoryApproval, executiveValue } = formData;

        // Format the level number and selected category based on your desired logic
        let levelNoEntry = `${levelNo},`;

        if (selectedCategoryApproval === 'ID') {
            // If selectedCategoryApproval is 'ID', include executiveValue
            levelNoEntry += `ID:${executiveValue}`;
        } else {
            // Otherwise, use selectedCategoryApproval as is
            levelNoEntry += selectedCategoryApproval;
        }

        // Add the levelNoEntry to the levelNoString
        levelNoString += `"${levelNoEntry}"`;

        // Add a separator between entries, e.g., a comma and a space
        if (index < formDataList.length - 1) {
            levelNoString += ',';
        }
    });

    // Your formatted LevelNo string





    // const generatePayload = () => {
    //    // Create an array to store the data from formDataList
    //    const payloadData = formDataList.map((formData) => {
    //        if (formData.selectedCategoryApproval === 'ID') {
    //            // Concatenate selectedCategoryApproval and executiveValue
    //            return {
    //                customField: `${formData.selectedCategoryApproval}:${formData.executiveValue}`,
    //            };
    //        } else {
    //            // Use selectedCategoryApproval as is
    //            return {
    //                customField: formData.selectedCategoryApproval,
    //            };
    //        }
    //    });

    //    // You can use payloadData as needed, e.g., sending it to an API or processing it further
    //    console.log('Generated Payload:', payloadData);
    // };
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        const LoginId = CurrentLoggedInUser.ExecutiveId;
        const payload = { ExecutiveID: LoginId }

        URLS.getRequestCategoryDropDown(payload)
            .then((response) => {

                setRequestCategory(response.data);
                console.log("res>>>",response.data)

            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
        URLS.getRequestCategoryApprovalHierarchy()
            .then((response) => {

                setRequestCategoryApproval(response.data);

            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });

        URLS.getExecutivesList()
            .then((response) => {

                setExecutive(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    }, []);



    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const handleChange = (event) => {
        const inputValue = event.target.value;
        // Check if input value is empty, then set daysValue to null
        if (inputValue === '') {
            setDaysValue(1);
        } else if (/^\d*$/.test(inputValue)) {
            // Validate input to allow only numeric characters
            setDaysValue(inputValue);
        }
    };
    const [groupName, setName] = useState('');
    const inputRef = useRef();

    const handleNamecheck = () => {
        const payload = { RequestName: groupName }
        URLS.checkRequestNameData(payload)
            .then((response) => {
                if (response.data.status === true) {
                    enqueueSnackbar("Category Name Already Exists");
                    inputRef.current.focus(); // Focus the input field again
                }

            })
            .catch(error => {
                handleError(error);
            });
    }
    console.log("ischeck", isCheckedActive);
    const requestMasterSchema = Yup.object().shape({
        CategoryDetails: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Category Details is required'),
        // Name: Yup.string()
        //    .min(2, 'Too Short!')
        //    .max(100, 'Too Long!')
        //    .required('Category Name is required'),
        // Description: Yup.string()
        //    .min(2, 'Too Short!')
        //    .max(100, 'Too Long!')
        //    .required('Category Description is required'),

    });
    const formik = useFormik({
        initialValues: {
            Id: requestMaster.Id,
            ApprovalHierarchyId: requestMaster.ApprovalHierarchyId,
            CategoryId: requestId,
            Name: requestMaster.Name,
            Description: requestMaster.Description,
            CategoryDetails: requestMaster.CategoryDetails,
            IsExpiryApplicable: isCheckedActive,
            IsReturnApplicable: isReturn,
            ExpiryAlert: `${selectedCategory}:${selectedCategory === 'D' ? daysValue : minutesValue}`,
            LevelNo: requestMaster.LavelNo,
            // ApproverKey: selectedCategoryApproval === 'ReportingTo' ? selectedCategoryApproval : `${selectedCategoryApproval}:${selectedCategoryApproval === 'ID' ? executiveValue : 'ReportingTo'}`,
            ApproverKey: approverKey,
            ExecutiveId: userId,
            DeletedFlags: deletedRecords
        },

       /* validationSchema: requestMasterSchema, */
        onSubmit: (values, actions) => {
            let saveData = true; // Flag to track whether to save data or not

            if (isCheckedActive === true ? requestMaster.ExpiryAlert : "") {
                if (selectedCategory === 'D' && !daysValue) {
                    enqueueSnackbar("Please select Days or Minutes");
                    saveData = false;
                    return;
                }

                if (selectedCategory === 'M' && !minutesValue) {
                    enqueueSnackbar("Please select Days or Minutes");
                    saveData = false;
                    return;
                }
            }
            if (isPlus && values.Name.length === 0) {
                enqueueSnackbar("Please select Category Name");
                saveData = false;

            }
            else if (isPlus && values.Description.length === 0) {
                enqueueSnackbar("Please select Category Description");
                saveData = false;
            }

            else if (values.CategoryDetails.length === 0) {
                enqueueSnackbar("Please select Request Category Details");
                saveData = false;

            }

            const payload = {
                "Id": values.Id,
                "ApprovalHierarchyId": requestMaster.ApprovalHierarchyId,
                "CategoryId": requestId || requestMaster.RequestCategoryId,
                "Name": values.Name || requestMaster.Name,
                "Description": values.Description,
                "CategoryDetails": values.CategoryDetails,
                "IsExpiryApplicable": isCheckedActive,
                "IsReturnApplicable": isReturn,
                "ExpiryAlert": `${selectedCategory}:${selectedCategory === 'D' ? daysValue : minutesValue}`,
                "LevelNo": levelNoString,
                // "ApproverKey": `${selectedCategoryApproval}:${selectedCategoryApproval === 'ID' ? executiveValue : 'ReportingTo'}`,
                // "ApproverKey": selectedCategoryApproval === 'ReportingTo' ? selectedCategoryApproval : `${selectedCategoryApproval}:${selectedCategoryApproval === 'ID' ? executiveValue : 'ReportingTo'}`,
                "ApproverKey": approverKey,
                "ExecutiveId": userId,
                "DeletedFlags": deletedRecords
            };

            const errorMessagesShown = new Set(); // Set to store error messages already shown

            formDataList.forEach((formData, index) => {
                if (formData.selectedCategoryApproval === 'ID' && !formData.executiveValue) {
                    if (!errorMessagesShown.has("Please select Executives")) {
                        enqueueSnackbar("Please select Executives");
                        errorMessagesShown.add("Please select Executives");
                    }
                    saveData = false; // Prevent saving
                }
            });
            // Iterate over formDataList
          
                // if (inputRef.current === document.activeElement) {
                //    if (!errorMessagesShown.has("Category Name Already Exists")) {
                //        enqueueSnackbar("Category Name Already Exists");
                //        errorMessagesShown.add("Category Name Already Exists");
                //    }
                //    saveData = false;
                // }
                 if (!isPlus && !isDisabled && requestId.length === 0) {
                    if (!errorMessagesShown.has("Please Select Request Category")) {
                        enqueueSnackbar("Please Select Request Category");
                        errorMessagesShown.add("Please Select Request Category");
                    }
                    saveData = false;
                 }
                 // else if (isPlus && groupName.length === 0) {
                 //   if (!errorMessagesShown.has("Please select name")) {
                 //       enqueueSnackbar("Please select name");
                 //       errorMessagesShown.add("Please select name");
                 //   }
                 //   saveData = false;
                 // }
                 else if (levelNoString.length !== 0 && approverKey.length === 0) {
                    if (!errorMessagesShown.has("Please select ApproverKey")) {
                        enqueueSnackbar("Please select ApproverKey");
                        errorMessagesShown.add("Please select ApproverKey");
                    }
                    saveData = false;
                } else if (formDataList.some(formData => formData.selectedCategoryApproval.length === 0)) {
                    if (!errorMessagesShown.has("Please select Approver Key")) {
                        enqueueSnackbar("Please select Approver Key");
                        errorMessagesShown.add("Please select Approver Key");
                    }
                    saveData = false;
                } else if (isCheckedActive && (daysValue.length === 0 && minutesValue.length === 0)) {
                    if (!errorMessagesShown.has("Please select Days or Minutes")) {
                        enqueueSnackbar("Please select Days or Minutes");
                        errorMessagesShown.add("Please select Days or Minutes");
                    }
                    saveData = false;
                }
            


            // If the flag is still true, save data
            const payloadName = { RequestName: values.Name }
           
            if (requestMaster.Name) {
                console.log("name>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", requestMaster.Name)
                if (saveData) {
                    console.log("save>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
                    setShowErrorMessage(false);

                    URLS.postRequestCategoryMaster(payload)
                        .then((response) => {
                            if (response.status === 200) {
                                navigate('/dashboard/requestandapproval/requestcategory', { replace: true });
                            }
                            enqueueSnackbar("Record saved successfully", { variant: 'success' });
                        })
                        .catch(error => {
                            handleError(error, enqueueSnackbar);
                            enqueueSnackbar("Record does not saved", { variant: 'error' });

                        });
                    console.log("payload>>>>>>>>>>>>>>>>>>>>>>>>>>", values.Name.length, payload.Name, payload)

                }
            } else {
                URLS.checkRequestNameData(payloadName)
                    .then((response) => {
                        if (response.data.status === true) {
                            enqueueSnackbar("Category Name Already exists");

                        }
                        else {
                            console.log("out>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")

                            if (saveData) {
                                console.log("save>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
                                setShowErrorMessage(false);

                                URLS.postRequestCategoryMaster(payload)
                                    .then((response) => {
                                        if (response.status === 200) {
                                            navigate('/dashboard/requestandapproval/requestcategory', { replace: true });
                                        }
                                        enqueueSnackbar("Record saved successfully", { variant: 'success' });
                                    })
                                    .catch(error => {
                                        handleError(error, enqueueSnackbar);
                                        enqueueSnackbar("Record does not saved", { variant: 'error' });

                                    });
                            }
                        }

                    })
                console.log("payload>>>>>>>>>>>>>>>>>>>>>>>>>>",  payload)

                   
            }
        },
    });

    console.log("nameOutside>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", requestId)

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;



    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={2} >
                    <Stack direction="row">

                        {isDisable ?

                            <Typography>
                                <span style={{ fontWeight: 'bold' }}>{reqname}</span> {requestMaster.Name}
                            </Typography> :
                            <FormControl
                                fullWidth
                                size="small" >
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Request Category</InputLabel>
                                <Select

                                    label="Request Category"
                                    size="small"
                                    value={requestId}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7', // Set the background color here
                                                maxHeight: '200px',
                                            }
                                        }
                                    }}>
                                    <MenuItem key={0} value={""} onClick={() => handelChangeRequest()}>Select</MenuItem>
                                    {requestCategory.map((req) => (
                                        <MenuItem key={req.Id} value={req.Id} onClick={() => handleSelectedRequest(req.Id)}> {req.Name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        }

                        {isClicked ? (
                            null
                        ) : (
                            <IconButton
                                onClick={handlePlus}

                            >
                                <Icon icon="ic:baseline-plus" />
                            </IconButton>
                        )}


                        {isPlus ? (
                            <>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Category Name"
                                    {...getFieldProps('Name')}
                                    error={Boolean(touched.Name && errors.Name)}
                                    helperText={touched.Name && errors.Name}
                                  /*  onBlur={handleNamecheck} */
                                    // value={groupName}
                                    // onChange={(e) => setName(e.target.value)}
                                    // inputRef={inputRef} // Assign the ref to the input field
                                />

                                {isDisable ?


                                    <TextField
                                        sx={{ marginLeft: '20px' }}
                                        fullWidth
                                        size="small"
                                        label="Category Description"
                                        {...getFieldProps('Description')}
                                        error={Boolean(touched.Description && errors.Description)}
                                        helperText={touched.Description && errors.Description}
                                    /> : ''}
                            </>
                        ) : (
                            <></>
                        )}
                        {isDisabled ?
                            <TextField
                                sx={{ marginLeft: '130px', width: '240px', }}

                                size="small"
                                label="Request Category Details"
                                {...getFieldProps('CategoryDetails')}
                                error={Boolean(touched.CategoryDetails && errors.CategoryDetails)}
                                helperText={touched.CategoryDetails && errors.CategoryDetails}
                            /> :
                            <TextField
                                sx={{ marginLeft: '10px' }}
                                fullWidth
                                size="small"
                                label="Request Category Details"
                                {...getFieldProps('CategoryDetails')}
                                error={Boolean(touched.CategoryDetails && errors.CategoryDetails)}
                                helperText={touched.CategoryDetails && errors.CategoryDetails}
                            />}
                    </Stack>

                    <Stack spacing={20} direction="row">
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={isCheckedActive} onChange={handleIsActiveChange} />} label="IsExpiryApplicable" />
                        </FormGroup>

                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={isReturn} onChange={handleIsReturnChange} />} label="IsReturnApplicable" />
                        </FormGroup>
                    </Stack>


                    {isCheckedActive ?

                        <Stack spacing={8} direction="row">
                            <FormControl sx={{ width: '240px' }} size="small">
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                    Expiry Alert
                                </InputLabel>
                                <Select
                                    label="Expiry Alert"
                                    size="small"
                                    value={selectedCategory}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7',
                                                maxHeight: '200px',
                                            },
                                        },
                                    }}
                                    onChange={handleCategoryChange}
                                >

                                    {categoryDetails.map((category) => (
                                        <MenuItem
                                            key={category.id}
                                            value={category.id}
                                            onClick={() => setSelectedCategory(category.id)}
                                        >
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {selectedCategory === 'D' && (
                                <TextField
                                    sx={{ width: '240px' }}
                                    size="small"
                                    type="number"
                                    label="Enter days"
                                    value={daysValue}
                                    style={{ border: showErrorMessage ? '1px solid red' : '1px solid #ccc' }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">D :</InputAdornment>,
                                    }}
                                    onChange={handleChange}
                                />

                            )}

                            {selectedCategory === 'M' && (
                                <TextField
                                    sx={{ width: '240px' }}
                                    size="small"
                                    type="number"
                                    label="Enter minutes"
                                    value={minutesValue}
                                    style={{ border: showErrorMessage && minutesValue.length === 0 ? '1px solid red' : '1px solid #ccc' }}

                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">M :</InputAdornment>,
                                    }}
                                    // onChange={(event) => setMinutesValue(event.target.value)}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        // Validate input to allow only numeric characters
                                        if (/^\d*$/.test(inputValue)) {
                                            setMinutesValue(inputValue);
                                        }
                                    }}
                                />
                            )}
                        </Stack>
                        : ''}
                </Stack>

                <Accordion defaultExpanded mt={1} sx={{ marginBottom: '10px' }} >
                    <AccordionSummary
                        style={accordionStyle}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="subtitle1">Approval Hierarchy</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end', // Align content to the right
                            }}
                            item xs={6} sm={6}>
                            {/* <IconButton */}
                            {/*    onClick={handleAddButtonClick} */}
                            {/*    sx={{ */}
                            {/*        ':hover': { */}
                            {/*            cursor: 'pointer', */}
                            {/*        }, */}
                            {/*    }} */}
                            {/* > */}
                            {/*    <Icon icon="ic:baseline-plus" /> */}
                            {/* </IconButton> */}

                            <Button

                                onClick={handleAddButtonClick}
                                variant="contained"
                                size="small"

                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <div style={{ maxWidth: '800px' }}>
                                <Stack spacing={1} justifyContent="flex-start">
                                    <Stack spacing={1} direction="row">
                                        <Grid container paddingTop={0} spacing={1}>
                                            {formDataList.map((formData, index) => (
                                                <Grid item xs={12} sm={12} key={index} sx={{ paddingTop: 0 }}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: '8px',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <Typography padding={1} >
                                                            <span style={{ fontWeight: 'bold' }}>LevelNo:</span> {formData.levelNo}
                                                        </Typography>


                                                        <FormControl sx={{ width: '250px' }} size="small"> {/* Set width here */}
                                                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                                                Approver Key
                                                            </InputLabel>
                                                            <Select
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        sx: {
                                                                            backgroundColor: '#fbe9e7',
                                                                            maxHeight: '200px',
                                                                        },
                                                                    },
                                                                }}
                                                                label="Approver Key"
                                                                size="small"
                                                                value={formData.selectedCategoryApproval}
                                                                onChange={(event) => handleApprovalChange(event, index)}
                                                            >
                                                                {categoryDetailsApproval.map((category) => (
                                                                    <MenuItem
                                                                        key={category.id}
                                                                        value={category.id}
                                                                        onClick={() => setSelectedCategoryApproval(category.id)}
                                                                    >
                                                                        {category.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        {formData.selectedCategoryApproval === 'ID' && (
                                                            <FormControl sx={{ width: '250px' }} size="small">
                                                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                                                    Executives
                                                                </InputLabel>
                                                                <Select
                                                                    MenuProps={{
                                                                        PaperProps: {
                                                                            sx: {
                                                                                backgroundColor: '#fbe9e7',
                                                                                maxHeight: '200px',
                                                                            },
                                                                        },
                                                                    }}
                                                                    label="Executives"
                                                                    value={formDataList[index].executiveValue} // Ensure this value is correctly mapped
                                                                    onChange={(event) => handleSelectedExecutive(event.target.value, index)}
                                                                >
                                                                    {/* Your options for Executives */}
                                                                    <MenuItem key={0} value={''}>
                                                                        Select
                                                                    </MenuItem>
                                                                    {executive.map((exec) => (
                                                                        <MenuItem
                                                                            key={exec.Id}
                                                                            value={exec.Id}
                                                                        >
                                                                            {exec.Name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        )}

                                                        <IconButton
                                                            onClick={() => handleDeleteButtonClick(index)}
                                                        >
                                                            <Icon icon="ic:round-delete" />
                                                        </IconButton>

                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>


                                    </Stack>
                                </Stack>
                            </div>
                        </Grid>

                        {/* Plus Button to add more items */}
                        {/* <Grid item xs={6} sm={6}> */}
                        {/*    <IconButton */}
                        {/*        onClick={handleAddButtonClick} */}
                        {/*        sx={{ */}
                        {/*            ':hover': { */}
                        {/*                cursor: 'pointer', */}
                        {/*            }, */}
                        {/*        }} */}
                        {/*    > */}
                        {/*        <Icon icon="ic:baseline-plus" /> */}
                        {/*    </IconButton> */}

                        {/* </Grid> */}







                    </AccordionDetails>
                </Accordion>



                <Stack direction="row" justifyContent="center" spacing={2}>

                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        sx={{ color: 'white' }}
                    >
                        Save
                    </LoadingButton>

                    <Button
                        sx={{ color: 'white' }}
                        onClick={handelCancelClick}
                        size="medium"
                        variant="contained"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </Stack>
            </Form>

            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider>
    );
}