import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { CircularProgress, Box, Typography  } from '@mui/material';
// -------------------------------------------------------------------------------------------------

LoadingProgressBar.propTypes = {
    sx: PropTypes.object
};

const style = {
    position: "fixed",
    left: "50%",
    top: "50%",
    color:"#b71c1c"
};

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        position: "fixed",
                        left: "51%",
                        top: "52%",
                        color: "#b71c1c",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    CircularProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate variant.
         * Value between 0 and 100.
         * @default 0
         */
        value: PropTypes.number.isRequired,
    };

export default function LoadingProgressBar() {
        const [progress, setProgress] = useState(10);

        useEffect(() => {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
            }, 800);
            return () => {
                clearInterval(timer);
            };
        }, []);

    return <CircularProgressWithLabel value={progress} sx={ style} />;
    }